// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/iam/v1/mfa.proto (package api.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ActivateTotpRequest,
  ActivateTotpResponse,
  GenerateTotpRequest,
  GenerateTotpResponse,
  RecoverTotpRequest,
  RecoverTotpResponse,
  ValidateTotpRequest,
  ValidateTotpResponse,
} from '../../../iam/auth/v1/mfa_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.iam.v1.MFAAPI
 */
export const MFAAPI = {
  typeName: 'api.iam.v1.MFAAPI',
  methods: {
    /**
     * @generated from rpc api.iam.v1.MFAAPI.GenerateTotp
     */
    generateTotp: {
      name: 'GenerateTotp',
      I: GenerateTotpRequest,
      O: GenerateTotpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.MFAAPI.ValidateTotp
     */
    validateTotp: {
      name: 'ValidateTotp',
      I: ValidateTotpRequest,
      O: ValidateTotpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.MFAAPI.RecoverTotp
     */
    recoverTotp: {
      name: 'RecoverTotp',
      I: RecoverTotpRequest,
      O: RecoverTotpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.iam.v1.MFAAPI.ActivateTotp
     */
    activateTotp: {
      name: 'ActivateTotp',
      I: ActivateTotpRequest,
      O: ActivateTotpResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
