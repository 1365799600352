export const replaceDashesWithSpaces = (input: string): string => {
  return input.replace(/-/g, ' ');
};

export const replaceSpacesWithDashes = (input: string): string => {
  if (!input) {
    return '';
  }
  return input.trimEnd().replace(/ /g, '-').toLowerCase();
};
