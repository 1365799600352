import { getConnectClient } from '@services/api/helpers';
import { useMutation } from '@tanstack/react-query';
import { Device, PreSignRequest, PreSignResponse, SignInRequest } from '@proto/iam/auth/v1/auth_pb';
import qs from 'qs';
import { AuthAPI } from '@proto/api/iam/v1/auth_connect';
import { useEffect } from 'react';
import { SignInResponse, SignOutRequest } from '@proto/api/iam/v1/auth_pb';
import { JWT } from '@services/jwt';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../zustand/auth';
import { useJWTStore } from '../../zustand/jwt';

const authClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AuthAPI
);

export const useOauthPreSignIn = () => {
  const oAuthAppTypeQueryParam = 'oauth_app';

  const getRedirectUri = (): string => {
    const redirectUriParams = qs.stringify({ [oAuthAppTypeQueryParam]: 'google' });
    return `${window.location.href}?${redirectUriParams}`;
  };

  const getOAuthCodeRequestUrl = (state: string): string => {
    const redirectUri = getRedirectUri();
    const params = qs.stringify({
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      response_type: 'code',
      redirect_uri: redirectUri,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      state,
    });
    return `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
  };

  const preSignIn = async (): Promise<PreSignResponse> => {
    return authClient.preSign(new PreSignRequest());
  };

  return useMutation({
    mutationFn: preSignIn,
    // onSuccess: (response) => {
    //   window.open(getOAuthCodeRequestUrl(response.state), '_self');
    // },
  });
};

export const useEmailPreSignIn = () => {
  const { getDevice, device } = useAuthState();
  useEffect(() => {
    getDevice();
  }, [getDevice]);

  const preSignIn = async (payload: string): Promise<PreSignResponse> => {
    return authClient.preSign(
      new PreSignRequest({
        method: {
          case: 'email',
          value: payload,
        },
        device: device || undefined,
      })
    );
  };

  return useMutation({
    mutationFn: preSignIn,
    onSuccess: (response) => {
      toast.success('Email sent');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useSignIn = () => {
  const { setSessionId } = useAuthState();
  const { getDevice, device } = useAuthState();
  const { setTokens } = useJWTStore();
  const navigate = useNavigate();
  useEffect(() => {
    getDevice();
  }, [getDevice]);

  const signIn = async (payload: SignInRequest): Promise<SignInResponse> => {
    return authClient.signIn(new SignInRequest({ ...payload, device: device || undefined }), {
      onHeader: (headers) => {
        const sessionId = headers.get('x-session-id');
        if (sessionId) {
          setSessionId(sessionId);
          navigate('/welcome');
        }
      },
    });
  };

  return useMutation({
    mutationFn: signIn,
    onSuccess: (response) => {
      setTimeout(() => {
        if (response?.tokens) {
          setTokens({
            accessToken: response?.tokens?.access,
            refreshToken: response?.tokens?.refresh,
          });
        }
      }, 500);
    },
  });
};

export const useSignOut = () => {
  const navigate = useNavigate();
  const { device, getDevice } = useAuthState();

  const signOut = async () => {
    return authClient.signOut(
      new SignOutRequest({
        devices: [
          new Device({
            fingerprint: device?.fingerprint,
            os: device?.os,
          }),
        ],
      })
    );
  };

  useEffect(() => {
    getDevice();
  }, [getDevice]);

  return useMutation({
    mutationFn: signOut,
    onSuccess: () => {
      JWT.removeJWTTokens();
      navigate('/login');
    },
  });
};
