// ProfilePopover.tsx
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import CustomAvatar from '@components/Avatar/Avatar';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import Popover from '@components/Popover/Popover';
import { useSignOut } from '@services/queries/Auth';

const ProfilePopover: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: signOut } = useSignOut();
  const { data: userProfile } = useGetMyProfile();

  const handleAvatarClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box position="relative">
      <Box
        data-popover-trigger
        onClick={handleAvatarClick}
        sx={{ cursor: 'pointer', display: 'inline-block' }}
      >
        <CustomAvatar
          alt={userProfile?.profile?.name?.nickName || 'User'}
          avatarId={userProfile?.profile?.avatar}
          profileId={userProfile?.profile?.profileId?.value}
        />
      </Box>

      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        style={{
          position: 'absolute',
          top: '120%',
          right: 0,
          width: '300px',
          zIndex: 10,
          padding: '16px',
        }}
        className="flex flex-col gap-2 shadow-lg justify-center items-center rounded-md bg-diamond"
      >
        <Box className="flex gap-1 items-center px-4 py-2">
          <Link
            onClick={() => setIsOpen(false)}
            to={ROUTES.PROFILE_SETTING}
            className="NavLink HoverUnderlineAnimation FlexLink"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Typography variant="subtitle2" mr={1}>
              Account Settings
            </Typography>
            <SettingsIcon />
          </Link>
        </Box>
        <Box
          onClick={() => signOut()}
          className="HoverUnderlineAnimation flex gap-1 items-center px-2 cursor-pointer"
        >
          <Typography>Sign Out</Typography>
          <LogoutIcon />
        </Box>
      </Popover>
    </Box>
  );
};

export default ProfilePopover;
