import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import { combineReducers } from '@reduxjs/toolkit';

import {
  ChangeProfileStatusServiceRequest,
  ChangeProfileStatusServiceResponse,
  GetMyProfileServiceRequest,
  GetMyProfileServiceResponse,
  GetProfileServiceRequest,
  GetProfileServiceResponse,
  ListToolTipsServiceResponse,
  PinItemServiceRequest,
  PinItemServiceResponse,
  profileService,
  UnPinItemServiceRequest,
  UnPinItemServiceResponse,
  UpdateProfilePersonalInfoServiceRequest,
  UpdateProfilePersonalInfoServiceResponse,
} from '@services/api/profiler/profile';

import { bizService, ListUserInvitesServiceResponse } from '@services/api/profiler/biz';

import { useCallback } from 'react';

import {
  GetProfileRequest,
  UpdateProfilePersonalInfoRequest,
} from '@proto/profiler/user/v1/profile_pb';

import { JWT } from '@services/jwt';

import { transformInvitesArrayToMap, TUserInvite } from '@store/profiler/helpers';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { transformPinItems } from '@utils/transformPinItemsToMap';
import { StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';
import { showSnackbar } from '@store/snackbars';
import { PinItemRequest, UnpinItemRequest } from '@proto/profiler/user/v1/pin_pb';
import { authSlice } from '@store/auth/slices';
import { userSlice } from './slices';
import { Profile, TSearchProfiles } from './profiler';

const {
  actions: {
    setProfile,
    setProfileName,
    setProfileStatus,
    setSelectedProfile,
    setUserIvitesList,
    setTooltipsList,
    setPinnedItems,
  },
} = userSlice;
const { setDevice, setShowOtp, setSessionId, setOtp } = authSlice.actions;

export const profilerReducer = combineReducers({
  user: userSlice.reducer,
});

// PROFILE
export const getMyProfile = createServiceCall<GetMyProfileServiceResponse, never>(
  userSlice.name,
  profileService.getMyProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setProfile({ profile }));
    dispatch(setPinnedItems({ pinnedItems: transformPinItems(profile?.pinnedItems || []) }));
  },
  ({ dispatch }) => {
    // JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);
const getWelcomeProfile = createServiceCall<
  GetMyProfileServiceResponse,
  GetMyProfileServiceRequest
>(
  userSlice.name,
  profileService.getWelcomeProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setProfile({ profile }));
    dispatch(setSessionId({ sessionId: '' }));
  },
  ({ dispatch }) => {
    // JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);

const getProfile = createServiceCall<GetProfileServiceResponse, GetProfileServiceRequest>(
  userSlice.name,
  profileService.getProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setSelectedProfile({ profile }));
  }
);

const updateProfileName = createServiceCall<
  UpdateProfilePersonalInfoServiceResponse,
  UpdateProfilePersonalInfoServiceRequest
>(
  userSlice.name,
  profileService.updateProfileName,
  ({ response: { names }, dispatch }) => {
    dispatch(setProfileName({ names }));
    dispatch(
      showSnackbar({ message: 'Profile name updated', severity: 'success', id: 'profile-name' })
    );
  },
  ({ dispatch }) => {
    dispatch(
      showSnackbar({ message: 'Profile name updated', severity: 'success', id: 'profile-name' })
    );
  }
);

const changeProfileStatus = createServiceCall<
  ChangeProfileStatusServiceResponse,
  ChangeProfileStatusServiceRequest
>(userSlice.name, profileService.changeProfileStatus, ({ response: { active }, dispatch }) => {
  dispatch(setProfileStatus({ active }));
});

const getListToolTips = createServiceCall<ListToolTipsServiceResponse, never>(
  userSlice.name,
  profileService.listToolTips,
  ({ response: { toolTips }, dispatch }) => {
    if (toolTips === undefined) return;
    dispatch(setTooltipsList({ tooltips: toolTips }));
  }
);

const getlistUserInvites = createServiceCall<ListUserInvitesServiceResponse, never>(
  userSlice.name,
  bizService.listUserInvites,
  ({ response: { invites }, dispatch }) => {
    dispatch(setUserIvitesList({ invites: transformInvitesArrayToMap(invites) }));
  },
  ({ dispatch }) => {
    dispatch(setUserIvitesList({ invites: [] }));
  }
);

const pinEntity = createServiceCall<PinItemServiceResponse, PinItemServiceRequest>(
  userSlice.name,
  profileService.pinItem,
  ({ dispatch }) => {
    dispatch(getMyProfile());
  }
);
const unpinEntity = createServiceCall<UnPinItemServiceResponse, UnPinItemServiceRequest>(
  userSlice.name,
  profileService.unPinItem,
  ({ dispatch }) => {
    dispatch(getMyProfile());
  }
);

export const useProfiler = (): {
  selectedProfile: Profile | undefined;
  foundProfiles: TSearchProfiles[];

  listUserInvites: { [key: string]: TUserInvite };
  userPermissions: {
    [key: string]: string;
  } | null;
  listRoles: { [key: string]: { id: string; roles: { roles: IAMRoleStrings[] } }[] };
  getMyProfile: () => void;
  getListToolTips: () => void;
  getWelcomeProfile: (payload: object) => void;
  getProfile: (payload: GetProfileRequest) => void;
  updateProfileName: (payload: {
    names: {
      firstName: string;
      lastName: string;
      nickName: string;
    };
    // birthDate: Date | null;
  }) => void;
  changeProfileStatus: (payload: ChangeProfileStatusServiceRequest) => void;
  getlistUserInvites: () => void;
  pinEntity: (payload: { entityId: string; entityType: Entity_Type }) => void;
  unpinEntity: (payload: { entityId: string; entityType: Entity_Type }) => void;
  profileId: string | undefined | null;
  setProfileId: (
    newValue: string | null | undefined,
    updateType?: UrlUpdateType | undefined
  ) => void;
} => {
  const dispatch = useAppDispatch();
  const [profileId, setProfileId] = useQueryParam('profileId', StringParam);

  return {
    profileId,
    setProfileId,
    selectedProfile: useAppSelector((state) => state.profiler.user.selectedProfile),
    foundProfiles: useAppSelector((state) => state.profiler.user.searchProfiles),
    listUserInvites: useAppSelector((state) => state.profiler.user.listUserInvites),
    userPermissions: useAppSelector((state) => state.profiler.user.userPermissions),
    listRoles: useAppSelector((state) => state.profiler.user.listRoles),
    pinEntity: useCallback(
      (payload) => {
        dispatch(
          pinEntity(
            new PinItemRequest({
              entity: {
                id: new UUID({ value: payload.entityId }),
                type: payload.entityType,
              },
            })
          )
        );
      },
      [dispatch]
    ),
    unpinEntity: useCallback(
      (payload) => {
        dispatch(
          unpinEntity(
            new UnpinItemRequest({
              entity: new Entity({
                id: new UUID({ value: payload.entityId }),
                type: payload.entityType,
              }),
            })
          )
        );
      },
      [dispatch]
    ),

    // We use this method to init app, so to cut down unnecessary requests we check for tokens in advance
    getMyProfile: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(getMyProfile());
      }
    }, [dispatch]),
    getListToolTips: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(getListToolTips());
      }
    }, [dispatch]),

    getWelcomeProfile: useCallback(
      (payload) => {
        const tokens = JWT.getJWTTokens();
        if (tokens) {
          dispatch(getWelcomeProfile(payload));
        }
      },
      [dispatch]
    ),
    getProfile: useCallback(
      (payload) => {
        dispatch(getProfile(payload));
      },
      [dispatch]
    ),
    updateProfileName: useCallback(
      (payload) => {
        const data = new UpdateProfilePersonalInfoRequest({
          names: payload.names,
          // birthDate: Timestamp.fromDate(
          //     // @ts-ignore
          //     payload.birthDate !== '' ? new Date(payload.birthDate) : new Date()
          // ),
        });
        dispatch(updateProfileName(data));
      },
      [dispatch]
    ),
    changeProfileStatus: useCallback(
      (payload) => dispatch(changeProfileStatus(payload)),
      [dispatch]
    ),
    getlistUserInvites: useCallback(() => {
      dispatch(getlistUserInvites());
    }, [dispatch]),
  };
};
