// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/profiler/v1/invite.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AcceptInviteRequest,
  AcceptInviteResponse,
  GenerateInviteLinkRequest,
  GenerateInviteLinkResponse,
  JoinByInviteLinkRequest,
  JoinByInviteLinkResponse,
  ListInvitesRequest,
  ListInvitesResponse,
  ListUserInvitesRequest,
  ListUserInvitesResponse,
  RejectInviteRequest,
  RejectInviteResponse,
  RevokeInviteRequest,
  RevokeInviteResponse,
  SendInvitesRequest,
  SendInvitesResponse,
} from '../../../profiler/invite/v1/invite_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.profiler.v1.InviteAPI
 */
export const InviteAPI = {
  typeName: 'api.profiler.v1.InviteAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.SendInvites
     */
    sendInvites: {
      name: 'SendInvites',
      I: SendInvitesRequest,
      O: SendInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.AcceptInvite
     */
    acceptInvite: {
      name: 'AcceptInvite',
      I: AcceptInviteRequest,
      O: AcceptInviteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.RejectInvite
     */
    rejectInvite: {
      name: 'RejectInvite',
      I: RejectInviteRequest,
      O: RejectInviteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.ListUserInvites
     */
    listUserInvites: {
      name: 'ListUserInvites',
      I: ListUserInvitesRequest,
      O: ListUserInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.ListInvites
     */
    listInvites: {
      name: 'ListInvites',
      I: ListInvitesRequest,
      O: ListInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.GenerateInviteLink
     */
    generateInviteLink: {
      name: 'GenerateInviteLink',
      I: GenerateInviteLinkRequest,
      O: GenerateInviteLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.JoinByInviteLink
     */
    joinByInviteLink: {
      name: 'JoinByInviteLink',
      I: JoinByInviteLinkRequest,
      O: JoinByInviteLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.InviteAPI.RevokeInvite
     */
    revokeInvite: {
      name: 'RevokeInvite',
      I: RevokeInviteRequest,
      O: RevokeInviteResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
