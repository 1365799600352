import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import ManageDashboardsList from '@pages/Marketplace/Dashboards/ManageDashboardsList';
import ListEvaluation from '@pages/Main/MainPage/Evaluations/SortedEvaluationsList';
import ListUserOrgs from '@pages/Main/MainPage/ListUserOrgs';
import ListAppliedDemands from '@pages/Main/MainPage/ListAppliedDemands';
import ListOrders from '@pages/Main/MainPage/ListOrders';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import TabContainer, { TabItem } from '@components/TabContainer/TabContainer';
import ListUserSquads from '@pages/Main/MainPage/ListUserSquads';
import ListUserAchievements from '@pages/Main/MainPage/ListUserAchievements';

const MainPage = () => {
  const { data: userProfile } = useGetMyProfile();
  const tabs: TabItem[] = [
    {
      label: 'Kanbanboards',
      content: (
        <Box display="flex" gap={2} flexDirection="column">
          <ManageDashboardsList />
        </Box>
      ),
    },
    {
      label: 'Organizations',
      content: (
        <Box display="flex" gap={2} flexDirection="column">
          <ListUserOrgs />
        </Box>
      ),
    },
    {
      label: 'Squads',
      content: (
        <Box display="flex" gap={2} flexDirection="column">
          <ListUserSquads />
        </Box>
      ),
    },
    {
      label: 'Orders',
      content: (
        <Box>
          {userProfile?.profile?.profileId && (
            <ListOrders customerId={userProfile?.profile?.profileId} />
          )}
        </Box>
      ),
      requiresPermission: true,
    },
    {
      label: 'Evaluations',
      content: (
        <Box>
          <ListEvaluation />
        </Box>
      ),
      requiresPermission: true,
    },
    {
      label: 'Applied demands',
      content: (
        <Box>
          <ListAppliedDemands />
        </Box>
      ),
      requiresPermission: true,
    },
    {
      label: 'Achievements',
      content: (
        <Box>
          <ListUserAchievements />
        </Box>
      ),
      requiresPermission: true,
    },
  ];

  return <TabContainer pageName="dashboard" tabs={tabs} userPermissions />;
};

export default MainPage;
