import React, { useCallback, useState } from 'react';
import { Autocomplete, Chip, Stack, TextField } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

interface EmailOption {
  title: string;
}

interface EmailAutocompleteControllerProps {
  name: string;
  label?: string;
}

const regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailAutocompleteController: React.FC<EmailAutocompleteControllerProps> = ({
  name,
  label = 'Emails',
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value = [] },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate: (val: string[]) =>
        val.every((email) => regex.test(email)) || 'Please enter a valid email address',
    },
  });

  const [inputValue, setInputValue] = useState<string>('');
  const [localError, setLocalError] = useState<string>('');

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: string[]) => {
      const validEmails = newValue.filter((email) => regex.test(email));
      if (newValue.length !== validEmails.length) {
        setLocalError('Please enter a valid email address');
      } else {
        setLocalError('');
      }
      onChange(validEmails);
    },
    [onChange]
  );

  const handleInputChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setInputValue(newValue);
  }, []);

  const handleTagDelete = useCallback(
    (option: string) => {
      const newSelected = (value as string[]).filter((email) => email !== option);
      onChange(newSelected);
    },
    [onChange, value]
  );

  const renderTag = useCallback(
    (tagValue: readonly string[], getTagProps: (options: { index: number }) => any) =>
      tagValue.map((option: string, index: number) => (
        <Chip
          key={option}
          variant="outlined"
          label={option}
          {...getTagProps({ index })}
          onDelete={() => handleTagDelete(option)}
        />
      )),
    [handleTagDelete]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === ' ') {
        const trimmedInput = inputValue.trim();
        if (trimmedInput === '') return;
        event.preventDefault();
        if (regex.test(trimmedInput)) {
          // Якщо email валідний, додаємо його
          handleChange(event, [...value, trimmedInput]);
          setInputValue('');
          setLocalError('');
        } else {
          setLocalError('Please enter a valid email address');
        }
      }
    },
    [inputValue, value, handleChange]
  );

  return (
    <Stack spacing={3}>
      <Autocomplete
        multiple
        freeSolo
        id="email-autocomplete"
        options={[]}
        value={value}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderTags={renderTag}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="Enter email address"
            type="email"
            error={!!(localError || error)}
            helperText={localError || (error ? error.message : '')}
            onKeyDown={(e) => {
              handleKeyDown(e);
              if (params.inputProps.onKeyDown) {
                params.inputProps.onKeyDown(e as React.KeyboardEvent<HTMLInputElement>);
              }
            }}
          />
        )}
      />
    </Stack>
  );
};

export default EmailAutocompleteController;
