import { getConnectClient } from '@services/api/helpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateOrgRequest,
  CreateOrgResponse,
  GetOrgResponse,
  ListOrgsRequest,
  ListOrgsResponse,
  Org,
} from '@proto/profiler/biz/v1/org_pb';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { getTagType } from '@store/profiler/helpers';
import { showSnackbar } from '@store/snackbars';
import { useAppDispatch } from '@store/helpers';
import { OrgAPI } from '@proto/api/profiler/v1/org_connect';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { GetOrgRequest } from '@proto/api/profiler/v1/org_pb';
import { Filter } from '@proto/grpc/type/v1/entity_pb';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useOrgsStore } from '../../zustand/orgs';
import { useRefetch } from '../../zustand/refetch';

export const orgClient = getConnectClient<typeof OrgAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  OrgAPI
);

export const useCreateOrg = () => {
  const queryClient = useQueryClient();
  const { setIsFetchedListOrgs } = useRefetch();
  const dispatch = useAppDispatch();
  const createOrg = async (payload: CreateOrgRequest): Promise<CreateOrgResponse> => {
    const orgTags: Tag[] = [];
    Object.entries(payload).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.map((element) => {
          const tag = new Tag({
            tagId: element.tagId,
            value: element.value,
            tagType: getTagType[key as keyof typeof getTagType],
            active: true,
          });
          return orgTags.push(tag);
        });
      }
    });
    return orgClient.createOrg(
      new CreateOrgRequest({
        name: payload.name,
        about: payload.about,
        tags: { tags: orgTags },
      })
    );
  };

  return useMutation({
    mutationFn: createOrg,
    onSuccess: () => {
      setIsFetchedListOrgs(true);
      queryClient.invalidateQueries({ queryKey: ['listUserOrgs'] });
      dispatch(
        showSnackbar({ id: 'create-org', message: 'Organization created', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'create-org', message: error.message, severity: 'error' }));
    },
  });
};

export const useGetOrg = (payload: { value: string; case: 'slug' | 'id' }, enabled: boolean) => {
  const getOrg = async (): Promise<GetOrgResponse> => {
    return orgClient.getOrg(
      new GetOrgRequest({
        filter: new Filter({
          by:
            payload.case === 'id'
              ? { case: 'id', value: new UUID({ value: payload.value as string }) }
              : { case: 'slug', value: payload.value as string },
        }),
      })
    );
  };

  return useQuery({
    queryKey: ['currentOrg', payload],
    queryFn: getOrg,
    enabled,
  });
};

export const useSetOrgStatus = () => {
  const queryClient = useQueryClient();
  const setOrgStatus = async (payload: { groupId: string; status: boolean }) => {
    return orgClient.setOrgStatus({
      orgId: new UUID({ value: payload.groupId }),
      active: payload.status,
    });
  };

  return useMutation({
    mutationFn: setOrgStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchMembershipsAndSquads'] });
    },
  });
};

export const useUpdateOrgInfo = () => {
  const queryClient = useQueryClient();
  const updateOrgInfo = async (payload: { orgId: string; about: string }) => {
    return orgClient.updateOrgInfo({
      orgId: new UUID({ value: payload.orgId }),
      about: payload.about,
    });
  };

  return useMutation({
    mutationFn: updateOrgInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentOrg'] });
      toast.success('Org info updated successfully');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useListUserOrgs = (payload: ListOrgsRequest) => {
  const { isFetchedListOrgs, setIsFetchedListOrgs } = useRefetch();

  const listOrgs = async (): Promise<ListOrgsResponse> => {
    try {
      return await orgClient.listOrgs(payload);
    } catch (error) {
      setIsFetchedListOrgs(false);
      throw error;
    }
  };

  return useQuery({
    queryKey: ['listUserOrgs'],
    queryFn: listOrgs,
    enabled: isFetchedListOrgs,
  });
};

export const useListOrgs = (payload: ListOrgsRequest) => {
  const listOrgs = async () => {
    return orgClient.listOrgs(payload);
  };

  return useQuery({
    queryKey: ['listOrgs', payload],
    queryFn: listOrgs,
    select: (data) => {
      return data.orgs?.orgs.reduce((acc, org) => {
        return { ...acc, [org.orgId?.value as string]: org };
      }, {} as { [key: string]: Org });
    },
  });
};

export const useSearchOrgs = (industryDomainIds: number[]) => {
  const { searchedOrgsName } = useOrgsStore();
  const [debouncedName, setDebouncedName] = useState(searchedOrgsName);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedName(searchedOrgsName);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchedOrgsName]);

  const searchOrgs = async () => {
    return orgClient.listOrgs(
      new ListOrgsRequest({
        by: {
          case: 'name',
          value: debouncedName || '',
        },
        industryDomanIds: industryDomainIds,
      })
    );
  };

  return useQuery({
    queryKey: ['searchOrgs', industryDomainIds, debouncedName],
    queryFn: searchOrgs,
    // enabled: !!debouncedName && industryDomainIds.length > 0, // Виконується лише при наявності ID та імені
  });
};
