import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  GetMyProfileRequest,
  GetMyProfileResponse,
  GetProfileRequest,
  GetProfileResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  ProfileName,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
} from '@proto/profiler/user/v1/profile_pb';

import { getConnectClient, makeRequest, PlainMsg } from '@services/api/helpers';
import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ListToolTipsRequest,
  ListToolTipsResponse,
} from '@proto/profiler/user/v1/tool_tips_pb';
import {
  ActivateAvatarRequest,
  ActivateAvatarResponse,
  DeleteAvatarRequest,
  DeleteAvatarResponse,
  ListAvatarsRequest,
  ListAvatarsResponse,
} from '@proto/profiler/avatars/v1/avatar_pb';

import { ProfileAPI } from '@proto/api/profiler/v1/profile_connect';

import { AvatarAPI } from '@proto/api/profiler/v1/avatar_connect';
import { ToolTipsAPI } from '@proto/api/profiler/v1/tool_tips_connect';
import { PinAPI } from '@proto/api/profiler/v1/pin_connect';
import {
  PinItemRequest,
  PinItemResponse,
  UnpinItemRequest,
  UnpinItemResponse,
} from '@proto/profiler/user/v1/pin_pb';

export type GetMyProfileServiceRequest = PlainMsg<GetMyProfileRequest>;
export type GetMyProfileServiceResponse = PlainMsg<GetMyProfileResponse>;
export type UpdateProfilePersonalInfoServiceResponse = PlainMsg<UpdateProfilePersonalInfoResponse>;
export type UpdateProfilePersonalInfoServiceRequest = PlainMsg<UpdateProfilePersonalInfoRequest>;
export type ChangeProfileStatusServiceResponse = PlainMsg<ChangeProfileStatusResponse>;
export type ChangeProfileStatusServiceRequest = PlainMsg<ChangeProfileStatusRequest>;

export type ListProfilesServiceRequest = PlainMsg<ListProfilesRequest>;
export type ListProfilesServiceResponse = PlainMsg<ListProfilesResponse>;

export type GetProfileServiceRequest = PlainMsg<GetProfileRequest>;
export type GetProfileServiceResponse = PlainMsg<GetProfileResponse>;

export type DeleteAvatarServiceRequest = PlainMsg<DeleteAvatarRequest>;
export type DeleteAvatarServiceResponse = PlainMsg<DeleteAvatarResponse>;
export type ListAvatarsServiceRequest = PlainMsg<ListAvatarsRequest>;
export type ListAvatarsServiceResponse = PlainMsg<ListAvatarsResponse>;
export type ActivateAvatarServiceRequest = PlainMsg<ActivateAvatarRequest>;
export type ActivateAvatarServiceResponse = PlainMsg<ActivateAvatarResponse>;
export type PinItemServiceRequest = PlainMsg<PinItemRequest>;
export type PinItemServiceResponse = PlainMsg<PinItemResponse>;
export type UnPinItemServiceRequest = PlainMsg<UnpinItemRequest>;
export type UnPinItemServiceResponse = PlainMsg<UnpinItemResponse>;

export type ListToolTipsServiceResponse = PlainMsg<ListToolTipsResponse>;
export type FinishToolTipsServiceRequest = PlainMsg<FinishToolTipsRequest>;
export type FinishToolTipsServiceResponse = PlainMsg<FinishToolTipsResponse>;

export type TProfileName = PlainMsg<ProfileName>;

export const profileClient = getConnectClient<typeof ProfileAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProfileAPI
);

export const pinClient = getConnectClient<typeof PinAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  PinAPI
);

export const avatarClient = getConnectClient<typeof AvatarAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AvatarAPI
);

export const toolTipsClient = getConnectClient<typeof ToolTipsAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ToolTipsAPI
);

export const profileService = {
  getMyProfile(): Promise<GetMyProfileServiceResponse> {
    return makeRequest(profileClient.getMyProfile, new GetMyProfileRequest());
  },

  pinItem(request: PinItemServiceRequest): Promise<PinItemServiceResponse> {
    return makeRequest(pinClient.pinItem, request);
  },
  unPinItem(request: UnPinItemServiceRequest): Promise<UnPinItemServiceResponse> {
    return makeRequest(pinClient.unpinItem, request);
  },

  deleteAvatar(request: DeleteAvatarServiceRequest): Promise<DeleteAvatarServiceResponse> {
    return makeRequest(avatarClient.deleteAvatar, request);
  },
  listAvatars(request: ListAvatarsServiceRequest): Promise<ListAvatarsServiceResponse> {
    return makeRequest(avatarClient.listAvatars, request);
  },

  activateAvatar(request: ActivateAvatarServiceRequest): Promise<ActivateAvatarServiceResponse> {
    return makeRequest(avatarClient.activateAvatar, request);
  },
  getWelcomeProfile(request: GetMyProfileServiceRequest): Promise<GetMyProfileServiceResponse> {
    return makeRequest(profileClient.getMyProfile, request);
  },
  getProfile(request: GetProfileServiceRequest): Promise<GetProfileServiceResponse> {
    return makeRequest<GetProfileRequest, GetProfileResponse>(profileClient.getProfile, request);
  },

  listProfiles(request: ListProfilesServiceRequest): Promise<ListProfilesServiceResponse> {
    return makeRequest(profileClient.listProfiles, request);
  },

  updateProfileName(
    request: UpdateProfilePersonalInfoServiceRequest
  ): Promise<UpdateProfilePersonalInfoServiceResponse> {
    return makeRequest<UpdateProfilePersonalInfoRequest, UpdateProfilePersonalInfoResponse>(
      profileClient.updateProfilePersonalInfo,
      request
    );
  },

  changeProfileStatus(
    request: ChangeProfileStatusServiceRequest
  ): Promise<ChangeProfileStatusServiceResponse> {
    return makeRequest<ChangeProfileStatusRequest, ChangeProfileStatusResponse>(
      profileClient.changeProfileStatus,
      request
    );
  },
  listToolTips(): Promise<ListToolTipsServiceResponse> {
    return makeRequest<ListToolTipsRequest, ListToolTipsResponse>(
      toolTipsClient.listToolTips,
      new ListToolTipsRequest()
    );
  },
  finishTooltips(request: FinishToolTipsServiceRequest): Promise<FinishToolTipsServiceResponse> {
    return makeRequest<FinishToolTipsRequest, FinishToolTipsResponse>(
      toolTipsClient.finishToolTips,
      request
    );
  },
};
