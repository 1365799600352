import Box from '@mui/material/Box';
import {
  GetProductOrderResponse_Draft,
  ProductOrder,
} from '@proto/marketplace/product/v1/order_pb';
import React from 'react';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import Typography from '@mui/material/Typography';
import { useDialog } from '@components/hooks/useDialog';
import { useTagsList } from '@services/queries/TagsQueries';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import AddIcon from '@mui/icons-material/Add';
import { Chip } from '@mui/material';
import { ListMembersResponse_Membership_Member } from '@proto/profiler/membership/v1/membership_pb';

function transformMembers(data: ListMembersResponse_Membership_Member[] | undefined): {
  [key: string]: Profile;
} {
  if (!data) return {};
  return data.reduce((acc, member) => {
    const key = (member?.profile?.value as Profile)?.profileId?.value as string;
    acc[key] = member?.profile?.value as Profile;
    return acc;
  }, {} as { [key: string]: Profile });
}

const CreateOrderRolesList = ({
  order,
  draft,
}: {
  order: ProductOrder;
  draft?: GetProductOrderResponse_Draft;
}) => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { data: roles } = useTagsList({ tagType: TagType.ROLE, enabled: true });
  // const { data: members } = useListMembers(
  //   new ListMembersRequest({
  //     by: {
  //       case: 'entityIds',
  //       value: new UUIDS({ values: [order?.owner?.id?.value as string] }),
  //     },
  //   }),
  //   !!order?.owner?.id?.value
  // );
  // const membersMapping = transformMembers(members?.memberships[0]?.members);

  return (
    <Box>
      <Typography className="text-white/80 !mb-1">
        Create offer breakdowns (not required)
      </Typography>
      <Box className="flex gap-2 flex-wrap mb-2">
        {(draft as GetProductOrderResponse_Draft)?.roles?.map((role) => {
          const currentRole = roles?.tags?.tags?.find((tag) => tag?.tagId === role?.roleId);

          return (
            <Box
              key={role?.roleId}
              className="max-w-[300px] min-h-[144px] h-auto w-full flex flex-col gap-4 bg-white/5 rounded-xl"
            >
              <Box className="w-full py-4 px-4 !bg-white/20 backdrop-blur-md group-hover:!bg-white/30 !rounded-t-xl  transition flex items-center justify-between z-50 sticky top-0">
                <Chip
                  size="small"
                  label={
                    roles?.tags?.tags?.find((tag) => tag?.tagId === role?.roleId)?.value as string
                  }
                />
                <Box>
                  <Box className="cursor-pointer    !font-bold  flex  items-center gap-2">
                    <Typography>${Number(role.fixed).toFixed(0)}</Typography>
                  </Box>
                </Box>
              </Box>

              {/* <ClearIcon */}
              {/*    className="cursor-pointer" */}
              {/*    onClick={() => deleteRoleMapping(currentRole as Tag)} */}
              {/* /> */}
              {/* <Box className="px-4"> */}
              {/*  {role?.profileIds?.values?.map((member) => ( */}
              {/*    <Box className="flex items-center gap-2 mb-2"> */}
              {/*      <CustomAvatar */}
              {/*        width={30} */}
              {/*        height={30} */}
              {/*        alt={ */}
              {/*          membersMapping[member as keyof typeof membersMapping]?.name?.nickName || */}
              {/*          'User' */}
              {/*        } */}
              {/*        avatarId={membersMapping[member as keyof typeof membersMapping]?.avatar} */}
              {/*        profileId={ */}
              {/*          membersMapping[member as keyof typeof membersMapping]?.profileId?.value */}
              {/*        } */}
              {/*      /> */}
              {/*      <Typography> */}
              {/*        {membersMapping[member as keyof typeof membersMapping]?.name?.nickName} */}
              {/*      </Typography> */}
              {/*    </Box> */}
              {/*  ))} */}
              {/* </Box> */}
            </Box>
          );
        })}
        <Box
          onClick={() => handleOpen()}
          className="max-w-[300px] rounded-xl min-h-[144px] w-full p-4 flex flex-col justify-center items-center gap-4 bg-white/10 cursor-pointer hover:bg-white/20 transition-all"
        >
          <AddIcon />
        </Box>

        {/* {order?.owner && ( */}
        {/*  <CreateOrderRole */}
        {/*    orderId={order?.orderId as UUID} */}
        {/*    handleClose={handleClose} */}
        {/*    isOpen={isOpen} */}
        {/*    owner={order?.owner} */}
        {/*  /> */}
        {/* )} */}
      </Box>
    </Box>
  );
};

export default CreateOrderRolesList;
