import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CreateDashboardButton from '@pages/Marketplace/CreateDashboardButton';
import { useListDashboards } from '@services/queries/MarketplaceQueries';
import Fade from '@mui/material/Fade';
import Card from '@components/Card/Card';
import { replaceSpacesWithDashes } from '@utils/parseStrings';
import { KanbanBoard_DemandsCount } from '@proto/marketplace/dashboard/v1/dashboard_pb';
import { Demand_Status_Status } from '@proto/marketplace/demand/v1/demand_pb';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

type AggregatedDemands = {
  [key in Entity_Type]?: {
    [status in Demand_Status_Status]?: number;
  };
};

function aggregateDemands(demands: KanbanBoard_DemandsCount[]): AggregatedDemands {
  return demands.reduce<AggregatedDemands>((acc, curr) => {
    if (!acc[curr.type]) {
      acc[curr.type] = {};
    }
    if (!acc[curr.type]![curr.status]) {
      acc[curr.type]![curr.status] = 0;
    }
    acc[curr.type]![curr.status]! += curr.count;
    return acc;
  }, {});
}

const EntityStatusLabels: Partial<Record<Demand_Status_Status, string>> = {
  [Demand_Status_Status.DRAFT]: 'Draft',
  [Demand_Status_Status.ACTIVE]: 'Active',
  [Demand_Status_Status.IN_PROGRESS]: 'In Progress',
  [Demand_Status_Status.DONE]: 'Done',
  [Demand_Status_Status.CANCELLED]: 'Cancelled',
};

const EntityTypeLabels: Partial<Record<Entity_Type, string>> = {
  [Entity_Type.INTERVIEW]: 'Interview',
  [Entity_Type.CONTRACT]: 'Contract',
};

const statusOrder: Demand_Status_Status[] = [
  Demand_Status_Status.DRAFT,
  Demand_Status_Status.ACTIVE,
  Demand_Status_Status.IN_PROGRESS,
  Demand_Status_Status.DONE,
  Demand_Status_Status.CANCELLED,
];

type DemandsTableProps = {
  aggregatedDemands: AggregatedDemands;
};

const DemandsTable = ({ aggregatedDemands }: DemandsTableProps) => {
  return (
    <TableContainer
    // className="!bg-black/5 "
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Entity Type</TableCell>
            {statusOrder.map((status) => (
              <TableCell key={status}>{EntityStatusLabels[status] || status}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(aggregatedDemands).map(([entityTypeStr, statuses]) => {
            const entityType = Number(entityTypeStr) as Entity_Type;
            return (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={entityType}>
                <TableCell>{EntityTypeLabels[entityType] || entityType}</TableCell>
                {statusOrder.map((status) => (
                  <TableCell key={status}>
                    {statuses && statuses[status] ? statuses[status] : 0}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ManageDashboardsList = () => {
  const { data, isLoading } = useListDashboards();

  if (isLoading) return <CircularProgress />;

  return (
    <Fade in>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography className="font-bold">Kanbanboards</Typography>
        {!data?.dashboards?.dashboards.length ? (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography>
              One place to track all your active contracts, applications, and interviews.
            </Typography>
            <CreateDashboardButton />
          </Box>
        ) : (
          <Box className="flex flex-col gap-2 w-full">
            {data.dashboards.dashboards.map((dashboard, index) => {
              const aggregatedDemands = aggregateDemands(dashboard.demandsCount);
              return (
                <Link
                  className="NavLink"
                  to={`dashboards/${replaceSpacesWithDashes(dashboard?.title as string)}`}
                  key={(dashboard as any).id || index}
                >
                  <Card className="!p-0">
                    <Box className="flex flex-col py-2 justify-center items-center w-full">
                      <Typography className="hover:underline !font-bold">
                        {dashboard.title}
                      </Typography>
                      <DemandsTable aggregatedDemands={aggregatedDemands} />
                    </Box>
                  </Card>
                </Link>
              );
            })}
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default ManageDashboardsList;
