import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getConnectClient } from '@services/api/helpers';

import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import { MembershipAPI } from '@proto/api/profiler/v1/membership_connect';
import {
  ListMembersRequest,
  ListMembersResponse,
  RemoveMemberRequest,
  RemoveMemberResponse,
  SearchMembersRequest,
  SearchMembersResponse,
} from '@proto/profiler/membership/v1/membership_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useMembersState } from '../../zustand/members';

export const membershipClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  MembershipAPI
);

export const useListMembers = (payload: ListMembersRequest, enabled: boolean) => {
  const membershipsResponse = async (): Promise<ListMembersResponse> => {
    return membershipClient.listMembers(payload);
  };

  return useQuery({
    queryKey: ['listMembers', payload],
    queryFn: membershipsResponse,
    select: (data) => {
      return data;
    },
    enabled,
  });
};

export const useSearchMembers = (entityId: UUID) => {
  const { searchedMembers } = useMembersState();

  console.log({ searchedMembers });
  const searchMembers = async (): Promise<SearchMembersResponse> => {
    return membershipClient.searchMembers(
      new SearchMembersRequest({
        entityId,
        query: searchedMembers,
      })
    );
  };

  return useQuery({
    queryKey: ['searchMembers', searchedMembers, entityId?.value],
    queryFn: searchMembers,
    enabled: !!searchedMembers,
  });
};

export const useRemoveMember = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const removeMember = async (payload: RemoveMemberRequest): Promise<RemoveMemberResponse> => {
    return membershipClient.leaveMembership(payload);
  };

  return useMutation({
    mutationFn: removeMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listMembers'] });
      dispatch(showSnackbar({ id: 'roles', message: 'Member removed', severity: 'success' }));
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'roles', message: error.message, severity: 'error' }));
    },
  });
};
