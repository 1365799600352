// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/dashboad.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateDashboardRequest,
  CreateDashboardResponse,
  DeleteDashboardRequest,
  DeleteDashboardResponse,
  GetDashboardRequest_Selector,
  GetDashboardResponse,
  ListDashboardsRequest,
  ListDashboardsResponse,
  ListFeedItemsRequest,
  ListFeedItemsResponse,
  UpdateDashboardRequest,
  UpdateDashboardResponse,
} from '../../../marketplace/dashboard/v1/dashboard_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.DashboardAPI
 */
export const DashboardAPI = {
  typeName: 'api.marketplace.v1.DashboardAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.DashboardAPI.CreateDashboard
     */
    createDashboard: {
      name: 'CreateDashboard',
      I: CreateDashboardRequest,
      O: CreateDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DashboardAPI.GetDashboard
     */
    getDashboard: {
      name: 'GetDashboard',
      I: GetDashboardRequest_Selector,
      O: GetDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DashboardAPI.ListDashboards
     */
    listDashboards: {
      name: 'ListDashboards',
      I: ListDashboardsRequest,
      O: ListDashboardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DashboardAPI.ListFeedItems
     */
    listFeedItems: {
      name: 'ListFeedItems',
      I: ListFeedItemsRequest,
      O: ListFeedItemsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DashboardAPI.UpdateDashboard
     */
    updateDashboard: {
      name: 'UpdateDashboard',
      I: UpdateDashboardRequest,
      O: UpdateDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.DashboardAPI.DeleteDashboard
     */
    deleteDashboard: {
      name: 'DeleteDashboard',
      I: DeleteDashboardRequest,
      O: DeleteDashboardResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
