import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useGenerateInviteLink } from '@services/queries/ProfilesQueries';
import { GenerateInviteLinkRequest_ExpiresAt } from '@proto/profiler/invite/v1/invite_pb';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import Button from '@mui/material/Button';
import { createSelectOptions } from '@utils/createSelectOptionsFromEnum';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import LinkInput from '@components/LinkInput/LinkInput';

type AddMemberProps = {
  entity: Entity;
};

const expiredOptions = [
  { value: 0, label: 'One time' },
  ...createSelectOptions(GenerateInviteLinkRequest_ExpiresAt, [
    GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_UNSPECIFIED,
  ]),
];

const expiredLabels = {
  [GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_UNSPECIFIED]: 'One time',
  [GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_1_DAY]: '1 day',
  [GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_1_WEEK]: '1 week',
  [GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_1_MONTH]: '1 month',
  [GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_1_YEAR]: '1 year',
};

const GenerateLinkDialog = (props: AddMemberProps) => {
  const { entity } = props;
  const [statusValue, setStatus] = useState(
    GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_UNSPECIFIED.toString()
  );
  const [linkCode, setLinkCode] = useState<string | undefined>(undefined);
  const { mutate: generateInviteLink } = useGenerateInviteLink();

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  return linkCode ? (
    <Box>
      <LinkInput
        defaultRevealed
        label="New invite link generated"
        link={`https://dev.squady.tech/invite-link/?code=${linkCode}`}
      />
    </Box>
  ) : (
    <Box className="flex flex-col items-center justify-center   gap-4  ">
      <Box className="flex flex-col items-center justify-center font-bold  ">
        Generate invite link
      </Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select expire time</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={statusValue}
          label="Select expire time"
          onChange={handleChange}
        >
          {expiredOptions.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                <Typography textTransform="capitalize">
                  {expiredLabels[item.value as keyof typeof expiredLabels]}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (
            (Number(statusValue) as GenerateInviteLinkRequest_ExpiresAt) ===
            GenerateInviteLinkRequest_ExpiresAt.EXPIRES_AT_UNSPECIFIED
          ) {
            return generateInviteLink(
              {
                entity,
                policy: {
                  case: 'oneTime',
                  value: true,
                },
              },
              {
                onSuccess: (data) => {
                  setLinkCode(data.code);
                },
              }
            );
          }

          return generateInviteLink(
            {
              entity,
              policy: {
                case: 'expiresAt',
                value: Number(statusValue) as GenerateInviteLinkRequest_ExpiresAt,
              },
            },
            {
              onSuccess: (data) => {
                setLinkCode(data.code);
              },
            }
          );
        }}
        className="flex gap-1 items-center"
      >
        Generate link
      </Button>
    </Box>
  );
};

export default GenerateLinkDialog;
