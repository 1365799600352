import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export type TAutocompleteItem = {
  tagId: number;
  tagType: string;
  objectId?: string;
  value: string;
};

type TAutocompleteControllerProps = Omit<TextFieldProps, 'name'> & {
  name: string;
  label: string;
  multiple?: boolean;
  defaultValues?: TAutocompleteItem[];
  options: TAutocompleteItem[];
  changeHandler?: (control: any) => void;
  onInputChange?: (e: any, data: any, reason: any) => void;
  getItems: () => void;
  isLoading?: boolean; // додаємо прапорець завантаження
};

const AutocompleteController = (props: TAutocompleteControllerProps): JSX.Element => {
  const {
    name,
    label,
    defaultValues,
    options,
    getItems,
    onInputChange,
    multiple,
    changeHandler,
    isLoading = false, // за замовчуванням false
    ...rest
  } = props;

  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  const handleOpen = () => {
    if (options.length === 0) {
      getItems();
    }
  };
  console.log({ error });
  return (
    <Autocomplete
      multiple={multiple}
      onOpen={handleOpen}
      options={options}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.value}
      value={defaultValues || value}
      onChange={(e, data) => {
        if (changeHandler) {
          onChange(data);
          changeHandler(data);
        } else {
          onChange(data);
        }
      }}
      isOptionEqualToValue={(option, _value) => option.tagId === _value.tagId}
      loading={isLoading} // використання прапорця завантаження
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...rest}
        />
      )}
    />
  );
};

export default AutocompleteController;
