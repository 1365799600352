import { JWT } from '@services/jwt';
import { create } from 'zustand/react';

export type JWTTokens = {
  accessToken: string;
  refreshToken: string;
};

interface AuthState {
  tokens: JWTTokens | null;
  setTokens: (tokens: JWTTokens) => void;
  logout: () => void;
}

export const useJWTStore = create<AuthState>((set) => ({
  tokens: JWT.getJWTTokens(),
  setTokens: (tokens: JWTTokens) => {
    JWT.saveJWTTokens(tokens);
    set({ tokens });
  },
  logout: () => {
    JWT.removeJWTTokens();
    set({ tokens: null });
  },
}));
