// routes.ts

export const ROUTES_PREFIXES = {
  LEGAL: '/legal',
  SQUADS: '/squads',
  OWNER_SQUADS: '/owner-squads',
  EXPLORE_SQUADS: '/explore-squads',
  ORGS: '/orgs',
  OWNER_ORGS: '/owner-orgs',
  EXPLORE_ORGS: '/explore-orgs',
  MARKETPLACE: '/marketplace',
  FEED_LIST: '/feed-list',
  MANAGE_DEMANDS: '/manage-demands',
  INTERWIEW: '/interview',
  EVALUATION: '/evaluation',
  CONTRACT: '/contract',
  DEMAND: '/demand',
  USER: '/user',
  PRODUCT: '/product',
};

export const ROUTES = {
  HOME: '/',
  WELCOME: '/welcome',
  NOT_FOUND: '/404',
  JOIN_BY_INVITE: '/invite-link',
  LOGIN: '/login',
  INVITE_LOGIN: '/invitation',
  MANAGE_DASHBOARDS: '/dashboards',
  PROFILE_SETTING: '/profile-settings',
  REWORK_PROFILE_SETTING: '/rework-profile-settings',
  NOTIFICATIONS: '/notifications',

  SQUADS_LIST: `${ROUTES_PREFIXES.SQUADS}`,
  ORGS_LIST: `${ROUTES_PREFIXES.ORGS}`,
  EXPLORE_SQUADS: `${ROUTES_PREFIXES.SQUADS}${ROUTES_PREFIXES.EXPLORE_SQUADS}`,
  MARKETPLACE: `${ROUTES_PREFIXES.MARKETPLACE}`,
  SQUAD: `${ROUTES_PREFIXES.SQUADS}/:name`,
  MANAGE_SQUAD: `${ROUTES_PREFIXES.SQUADS}/:name/manage`,
  MANAGE_SQUAD_MEMBERS: `${ROUTES_PREFIXES.SQUADS}/:entityId/members`,
  EXPLORE_SQUAD: `${ROUTES_PREFIXES.EXPLORE_SQUADS}/:entityId`,

  ORDER: '/orders/:orderId',

  OWNER_ORGS: `${ROUTES_PREFIXES.OWNER_ORGS}`,
  EXPLORE_ORGS: `${ROUTES_PREFIXES.ORGS}${ROUTES_PREFIXES.EXPLORE_ORGS}`,

  USER: `${ROUTES_PREFIXES.USER}/:profileId`,

  EXPLORE_ORG: `${ROUTES_PREFIXES.EXPLORE_ORGS}/:entityId`,
  ORG: `${ROUTES_PREFIXES.ORGS}/:name`,
  MANAGE_ORG: `${ROUTES_PREFIXES.ORGS}/:name/manage`,
  MANAGE_ORG_MEMBERS: `${ROUTES_PREFIXES.OWNER_ORGS}/:entityId/members`,

  MFA: '/profile-settings/mfa',
  FEED_LIST: `${ROUTES_PREFIXES.FEED_LIST}`,

  MANAGE_DEMANDS: `${ROUTES_PREFIXES.MANAGE_DEMANDS}`,
  _DASHBOARD: '/dashboards/:dashboardId',
  PROFILE_SETTINGS_ONBOARDING: '/profile-settings/onboarding',
  MANAGE_ROLE: '/manage-role/:domainId/:toe/:roleId',
  INTERVIEW: `${ROUTES_PREFIXES.INTERWIEW}/:interviewId`,

  EVALUATION: `${ROUTES_PREFIXES.EVALUATION}/:evaluationId`,
  CONTRACT: `${ROUTES_PREFIXES.CONTRACT}/:contractId`,
  MANAGE_CONTRACT: `/:dashboardId${ROUTES_PREFIXES.CONTRACT}-manage/:demandId`,
  MANAGE_INTERVIEW: `/:dashboardId${ROUTES_PREFIXES.INTERWIEW}-manage/:demandId`,
  MANAGE_EVALUATIONS: `/:dashboardId${ROUTES_PREFIXES.INTERWIEW}-manage/:demandId/evaluations`,

  PRODUCT: `${ROUTES_PREFIXES.PRODUCT}/:productId`,
  BREAKDOWN: `${ROUTES_PREFIXES.PRODUCT}/:productId/breakdown`,
  EDIT_BREAKDOWN: `${ROUTES_PREFIXES.PRODUCT}/:productId/breakdown/edit`,

  USER_INVITES: 'invites/:invites',

  // Юридичні сторінки
  LEGAL_PRIVACY: `${ROUTES_PREFIXES.LEGAL}/privacy-policy`,
  LEGAL_COOKIE: `${ROUTES_PREFIXES.LEGAL}/cookie-policy`,
  LEGAL_TERMS: `${ROUTES_PREFIXES.LEGAL}/terms-of-service`,
};
