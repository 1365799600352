// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file iam/auth/v1/iam.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Entities, Entity, Entity_Type, EntityIds } from '../../../grpc/type/v1/entity_pb.js';
import { Profiles } from '../../../profiler/user/v1/profile_pb.js';
import { Strings } from '../../../grpc/type/v1/types_pb.js';

/**
 * @generated from enum iam.auth.v1.SystemRole
 */
export enum SystemRole {
  /**
   * @generated from enum value: SYSTEM_ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SYSTEM_ROLE_OWNER = 1;
   */
  OWNER = 1,

  /**
   * @generated from enum value: SYSTEM_ROLE_ADMIN = 2;
   */
  ADMIN = 2,

  /**
   * @generated from enum value: SYSTEM_ROLE_MEMBER = 3;
   */
  MEMBER = 3,

  /**
   * @generated from enum value: SYSTEM_ROLE_INTERVIEWER = 4;
   */
  INTERVIEWER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SystemRole)
proto3.util.setEnumType(SystemRole, 'iam.auth.v1.SystemRole', [
  { no: 0, name: 'SYSTEM_ROLE_UNSPECIFIED' },
  { no: 1, name: 'SYSTEM_ROLE_OWNER' },
  { no: 2, name: 'SYSTEM_ROLE_ADMIN' },
  { no: 3, name: 'SYSTEM_ROLE_MEMBER' },
  { no: 4, name: 'SYSTEM_ROLE_INTERVIEWER' },
]);

/**
 * @generated from message iam.auth.v1.RWX
 */
export class RWX extends Message<RWX> {
  /**
   * @generated from field: bool r = 1;
   */
  r = false;

  /**
   * @generated from field: bool w = 2;
   */
  w = false;

  /**
   * @generated from field: bool x = 3;
   */
  x = false;

  constructor(data?: PartialMessage<RWX>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RWX';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'r', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: 'w', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'x', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RWX {
    return new RWX().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RWX {
    return new RWX().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RWX {
    return new RWX().fromJsonString(jsonString, options);
  }

  static equals(
    a: RWX | PlainMessage<RWX> | undefined,
    b: RWX | PlainMessage<RWX> | undefined
  ): boolean {
    return proto3.util.equals(RWX, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.EnforceRequest
 */
export class EnforceRequest extends Message<EnforceRequest> {
  /**
   * @generated from oneof iam.auth.v1.EnforceRequest.entity
   */
  entity:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID id = 1;
         */
        value: UUID;
        case: 'id';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS ids = 2;
         */
        value: UUIDS;
        case: 'ids';
      }
    | {
        /**
         * @generated from field: iam.auth.v1.EnforceRequest.ToEInDomain toe_in_domain = 3;
         */
        value: EnforceRequest_ToEInDomain;
        case: 'toeInDomain';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EnforceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.EnforceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'message', T: UUID, oneof: 'entity' },
    { no: 2, name: 'ids', kind: 'message', T: UUIDS, oneof: 'entity' },
    {
      no: 3,
      name: 'toe_in_domain',
      kind: 'message',
      T: EnforceRequest_ToEInDomain,
      oneof: 'entity',
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnforceRequest {
    return new EnforceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnforceRequest {
    return new EnforceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnforceRequest {
    return new EnforceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: EnforceRequest | PlainMessage<EnforceRequest> | undefined,
    b: EnforceRequest | PlainMessage<EnforceRequest> | undefined
  ): boolean {
    return proto3.util.equals(EnforceRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.EnforceRequest.ToEInDomain
 */
export class EnforceRequest_ToEInDomain extends Message<EnforceRequest_ToEInDomain> {
  /**
   * @generated from field: grpc.type.v1.Entity.Type type = 1;
   */
  type = Entity_Type.UNSPECIFIED;

  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 2;
   */
  domainId?: UUID;

  constructor(data?: PartialMessage<EnforceRequest_ToEInDomain>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.EnforceRequest.ToEInDomain';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
    { no: 2, name: 'domain_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): EnforceRequest_ToEInDomain {
    return new EnforceRequest_ToEInDomain().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): EnforceRequest_ToEInDomain {
    return new EnforceRequest_ToEInDomain().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EnforceRequest_ToEInDomain {
    return new EnforceRequest_ToEInDomain().fromJsonString(jsonString, options);
  }

  static equals(
    a: EnforceRequest_ToEInDomain | PlainMessage<EnforceRequest_ToEInDomain> | undefined,
    b: EnforceRequest_ToEInDomain | PlainMessage<EnforceRequest_ToEInDomain> | undefined
  ): boolean {
    return proto3.util.equals(EnforceRequest_ToEInDomain, a, b);
  }
}

/**
 * <uuid, RWX>
 *
 * @generated from message iam.auth.v1.IdsRWX
 */
export class IdsRWX extends Message<IdsRWX> {
  /**
   * @generated from field: map<string, iam.auth.v1.RWX> ids = 1;
   */
  ids: { [key: string]: RWX } = {};

  constructor(data?: PartialMessage<IdsRWX>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.IdsRWX';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'ids',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: RWX },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdsRWX {
    return new IdsRWX().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdsRWX {
    return new IdsRWX().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdsRWX {
    return new IdsRWX().fromJsonString(jsonString, options);
  }

  static equals(
    a: IdsRWX | PlainMessage<IdsRWX> | undefined,
    b: IdsRWX | PlainMessage<IdsRWX> | undefined
  ): boolean {
    return proto3.util.equals(IdsRWX, a, b);
  }
}

/**
 * Returns a map of permissions for each id in the request
 * For ToEInDomain, the keys are objectIds of requested type in the domain
 *
 * @generated from message iam.auth.v1.EnforceResponse
 */
export class EnforceResponse extends Message<EnforceResponse> {
  /**
   * @generated from field: iam.auth.v1.IdsRWX ids_rwx = 1;
   */
  idsRwx?: IdsRWX;

  constructor(data?: PartialMessage<EnforceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.EnforceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids_rwx', kind: 'message', T: IdsRWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnforceResponse {
    return new EnforceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnforceResponse {
    return new EnforceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnforceResponse {
    return new EnforceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: EnforceResponse | PlainMessage<EnforceResponse> | undefined,
    b: EnforceResponse | PlainMessage<EnforceResponse> | undefined
  ): boolean {
    return proto3.util.equals(EnforceResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.NewDomainRequest
 */
export class NewDomainRequest extends Message<NewDomainRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity domain = 1;
   */
  domain?: Entity;

  constructor(data?: PartialMessage<NewDomainRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.NewDomainRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewDomainRequest {
    return new NewDomainRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewDomainRequest {
    return new NewDomainRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewDomainRequest {
    return new NewDomainRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: NewDomainRequest | PlainMessage<NewDomainRequest> | undefined,
    b: NewDomainRequest | PlainMessage<NewDomainRequest> | undefined
  ): boolean {
    return proto3.util.equals(NewDomainRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.NewDomainResponse
 */
export class NewDomainResponse extends Message<NewDomainResponse> {
  constructor(data?: PartialMessage<NewDomainResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.NewDomainResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewDomainResponse {
    return new NewDomainResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewDomainResponse {
    return new NewDomainResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewDomainResponse {
    return new NewDomainResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: NewDomainResponse | PlainMessage<NewDomainResponse> | undefined,
    b: NewDomainResponse | PlainMessage<NewDomainResponse> | undefined
  ): boolean {
    return proto3.util.equals(NewDomainResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DropDomainRequest
 */
export class DropDomainRequest extends Message<DropDomainRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  constructor(data?: PartialMessage<DropDomainRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DropDomainRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DropDomainRequest {
    return new DropDomainRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DropDomainRequest {
    return new DropDomainRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DropDomainRequest {
    return new DropDomainRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DropDomainRequest | PlainMessage<DropDomainRequest> | undefined,
    b: DropDomainRequest | PlainMessage<DropDomainRequest> | undefined
  ): boolean {
    return proto3.util.equals(DropDomainRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DropDomainResponse
 */
export class DropDomainResponse extends Message<DropDomainResponse> {
  constructor(data?: PartialMessage<DropDomainResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DropDomainResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DropDomainResponse {
    return new DropDomainResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DropDomainResponse {
    return new DropDomainResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DropDomainResponse {
    return new DropDomainResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DropDomainResponse | PlainMessage<DropDomainResponse> | undefined,
    b: DropDomainResponse | PlainMessage<DropDomainResponse> | undefined
  ): boolean {
    return proto3.util.equals(DropDomainResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateRoleRequest
 */
export class CreateRoleRequest extends Message<CreateRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  /**
   * @generated from oneof iam.auth.v1.CreateRoleRequest.role
   */
  role:
    | {
        /**
         * @generated from field: string name = 2;
         */
        value: string;
        case: 'name';
      }
    | {
        /**
         * @generated from field: iam.auth.v1.SystemRole system = 3;
         */
        value: SystemRole;
        case: 'system';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'role' },
    { no: 3, name: 'system', kind: 'enum', T: proto3.getEnumType(SystemRole), oneof: 'role' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateRoleRequest | PlainMessage<CreateRoleRequest> | undefined,
    b: CreateRoleRequest | PlainMessage<CreateRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateRoleResponse
 */
export class CreateRoleResponse extends Message<CreateRoleResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  constructor(data?: PartialMessage<CreateRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRoleResponse {
    return new CreateRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRoleResponse {
    return new CreateRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateRoleResponse {
    return new CreateRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateRoleResponse | PlainMessage<CreateRoleResponse> | undefined,
    b: CreateRoleResponse | PlainMessage<CreateRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteRoleRequest
 */
export class DeleteRoleRequest extends Message<DeleteRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  constructor(data?: PartialMessage<DeleteRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRoleRequest {
    return new DeleteRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRoleRequest {
    return new DeleteRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRoleRequest {
    return new DeleteRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteRoleRequest | PlainMessage<DeleteRoleRequest> | undefined,
    b: DeleteRoleRequest | PlainMessage<DeleteRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteRoleResponse
 */
export class DeleteRoleResponse extends Message<DeleteRoleResponse> {
  constructor(data?: PartialMessage<DeleteRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRoleResponse {
    return new DeleteRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRoleResponse {
    return new DeleteRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteRoleResponse {
    return new DeleteRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteRoleResponse | PlainMessage<DeleteRoleResponse> | undefined,
    b: DeleteRoleResponse | PlainMessage<DeleteRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AddUserToRoleRequest
 */
export class AddUserToRoleRequest extends Message<AddUserToRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from oneof iam.auth.v1.AddUserToRoleRequest.role
   */
  role:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID role_id = 2;
         */
        value: UUID;
        case: 'roleId';
      }
    | {
        /**
         * @generated from field: iam.auth.v1.AddUserToRoleRequest.SysRole sys_role = 3;
         */
        value: AddUserToRoleRequest_SysRole;
        case: 'sysRole';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AddUserToRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AddUserToRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'message', T: UUID, oneof: 'role' },
    { no: 3, name: 'sys_role', kind: 'message', T: AddUserToRoleRequest_SysRole, oneof: 'role' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddUserToRoleRequest {
    return new AddUserToRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddUserToRoleRequest {
    return new AddUserToRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddUserToRoleRequest {
    return new AddUserToRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddUserToRoleRequest | PlainMessage<AddUserToRoleRequest> | undefined,
    b: AddUserToRoleRequest | PlainMessage<AddUserToRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddUserToRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AddUserToRoleRequest.SysRole
 */
export class AddUserToRoleRequest_SysRole extends Message<AddUserToRoleRequest_SysRole> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  /**
   * @generated from field: iam.auth.v1.SystemRole system = 2;
   */
  system = SystemRole.UNSPECIFIED;

  constructor(data?: PartialMessage<AddUserToRoleRequest_SysRole>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AddUserToRoleRequest.SysRole';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
    { no: 2, name: 'system', kind: 'enum', T: proto3.getEnumType(SystemRole) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AddUserToRoleRequest_SysRole {
    return new AddUserToRoleRequest_SysRole().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AddUserToRoleRequest_SysRole {
    return new AddUserToRoleRequest_SysRole().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddUserToRoleRequest_SysRole {
    return new AddUserToRoleRequest_SysRole().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddUserToRoleRequest_SysRole | PlainMessage<AddUserToRoleRequest_SysRole> | undefined,
    b: AddUserToRoleRequest_SysRole | PlainMessage<AddUserToRoleRequest_SysRole> | undefined
  ): boolean {
    return proto3.util.equals(AddUserToRoleRequest_SysRole, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AddUserToRoleResponse
 */
export class AddUserToRoleResponse extends Message<AddUserToRoleResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  constructor(data?: PartialMessage<AddUserToRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AddUserToRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AddUserToRoleResponse {
    return new AddUserToRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddUserToRoleResponse {
    return new AddUserToRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddUserToRoleResponse {
    return new AddUserToRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddUserToRoleResponse | PlainMessage<AddUserToRoleResponse> | undefined,
    b: AddUserToRoleResponse | PlainMessage<AddUserToRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddUserToRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteUserFromRoleRequest
 */
export class DeleteUserFromRoleRequest extends Message<DeleteUserFromRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 2;
   */
  profileId?: UUID;

  constructor(data?: PartialMessage<DeleteUserFromRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteUserFromRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
    { no: 2, name: 'profile_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteUserFromRoleRequest {
    return new DeleteUserFromRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteUserFromRoleRequest {
    return new DeleteUserFromRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteUserFromRoleRequest {
    return new DeleteUserFromRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteUserFromRoleRequest | PlainMessage<DeleteUserFromRoleRequest> | undefined,
    b: DeleteUserFromRoleRequest | PlainMessage<DeleteUserFromRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteUserFromRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteUserFromRoleResponse
 */
export class DeleteUserFromRoleResponse extends Message<DeleteUserFromRoleResponse> {
  constructor(data?: PartialMessage<DeleteUserFromRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteUserFromRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteUserFromRoleResponse {
    return new DeleteUserFromRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteUserFromRoleResponse {
    return new DeleteUserFromRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteUserFromRoleResponse {
    return new DeleteUserFromRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteUserFromRoleResponse | PlainMessage<DeleteUserFromRoleResponse> | undefined,
    b: DeleteUserFromRoleResponse | PlainMessage<DeleteUserFromRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteUserFromRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreatePolicyRequest
 */
export class CreatePolicyRequest extends Message<CreatePolicyRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity.Type toe = 2;
   */
  toe = Entity_Type.UNSPECIFIED;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 3;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<CreatePolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreatePolicyRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
    { no: 2, name: 'toe', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
    { no: 3, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePolicyRequest {
    return new CreatePolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePolicyRequest {
    return new CreatePolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreatePolicyRequest {
    return new CreatePolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreatePolicyRequest | PlainMessage<CreatePolicyRequest> | undefined,
    b: CreatePolicyRequest | PlainMessage<CreatePolicyRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreatePolicyRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreatePolicyResponse
 */
export class CreatePolicyResponse extends Message<CreatePolicyResponse> {
  constructor(data?: PartialMessage<CreatePolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreatePolicyResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePolicyResponse {
    return new CreatePolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePolicyResponse {
    return new CreatePolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreatePolicyResponse {
    return new CreatePolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreatePolicyResponse | PlainMessage<CreatePolicyResponse> | undefined,
    b: CreatePolicyResponse | PlainMessage<CreatePolicyResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreatePolicyResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeletePolicyRequest
 */
export class DeletePolicyRequest extends Message<DeletePolicyRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity.Type toe = 2;
   */
  toe = Entity_Type.UNSPECIFIED;

  constructor(data?: PartialMessage<DeletePolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeletePolicyRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
    { no: 2, name: 'toe', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePolicyRequest {
    return new DeletePolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePolicyRequest {
    return new DeletePolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeletePolicyRequest {
    return new DeletePolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeletePolicyRequest | PlainMessage<DeletePolicyRequest> | undefined,
    b: DeletePolicyRequest | PlainMessage<DeletePolicyRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeletePolicyRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeletePolicyResponse
 */
export class DeletePolicyResponse extends Message<DeletePolicyResponse> {
  constructor(data?: PartialMessage<DeletePolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeletePolicyResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePolicyResponse {
    return new DeletePolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePolicyResponse {
    return new DeletePolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeletePolicyResponse {
    return new DeletePolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeletePolicyResponse | PlainMessage<DeletePolicyResponse> | undefined,
    b: DeletePolicyResponse | PlainMessage<DeletePolicyResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeletePolicyResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateEntitiesRequest
 */
export class CreateEntitiesRequest extends Message<CreateEntitiesRequest> {
  /**
   * @generated from oneof iam.auth.v1.CreateEntitiesRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID domain_id = 1;
         */
        value: UUID;
        case: 'domainId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUID parent_id = 2;
         */
        value: UUID;
        case: 'parentId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated grpc.type.v1.EntityIds entities = 3;
   */
  entities: EntityIds[] = [];

  constructor(data?: PartialMessage<CreateEntitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateEntitiesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 2, name: 'parent_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 3, name: 'entities', kind: 'message', T: EntityIds, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateEntitiesRequest {
    return new CreateEntitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEntitiesRequest {
    return new CreateEntitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateEntitiesRequest {
    return new CreateEntitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateEntitiesRequest | PlainMessage<CreateEntitiesRequest> | undefined,
    b: CreateEntitiesRequest | PlainMessage<CreateEntitiesRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateEntitiesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateEntitiesResponse
 */
export class CreateEntitiesResponse extends Message<CreateEntitiesResponse> {
  constructor(data?: PartialMessage<CreateEntitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateEntitiesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateEntitiesResponse {
    return new CreateEntitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateEntitiesResponse {
    return new CreateEntitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateEntitiesResponse {
    return new CreateEntitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateEntitiesResponse | PlainMessage<CreateEntitiesResponse> | undefined,
    b: CreateEntitiesResponse | PlainMessage<CreateEntitiesResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateEntitiesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteEntitiesRequest
 */
export class DeleteEntitiesRequest extends Message<DeleteEntitiesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS entity_ids = 1;
   */
  entityIds?: UUIDS;

  constructor(data?: PartialMessage<DeleteEntitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteEntitiesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEntitiesRequest {
    return new DeleteEntitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteEntitiesRequest {
    return new DeleteEntitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEntitiesRequest {
    return new DeleteEntitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteEntitiesRequest | PlainMessage<DeleteEntitiesRequest> | undefined,
    b: DeleteEntitiesRequest | PlainMessage<DeleteEntitiesRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteEntitiesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteEntitiesResponse
 */
export class DeleteEntitiesResponse extends Message<DeleteEntitiesResponse> {
  constructor(data?: PartialMessage<DeleteEntitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteEntitiesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEntitiesResponse {
    return new DeleteEntitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEntitiesResponse {
    return new DeleteEntitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEntitiesResponse {
    return new DeleteEntitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteEntitiesResponse | PlainMessage<DeleteEntitiesResponse> | undefined,
    b: DeleteEntitiesResponse | PlainMessage<DeleteEntitiesResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteEntitiesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListEntitiesRequest
 */
export class ListEntitiesRequest extends Message<ListEntitiesRequest> {
  /**
   * if not set (UNSPECIFIED), return all types
   *
   * @generated from field: grpc.type.v1.Entity.Type entity_type = 1;
   */
  entityType = Entity_Type.UNSPECIFIED;

  constructor(data?: PartialMessage<ListEntitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListEntitiesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_type', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEntitiesRequest {
    return new ListEntitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEntitiesRequest {
    return new ListEntitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEntitiesRequest {
    return new ListEntitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEntitiesRequest | PlainMessage<ListEntitiesRequest> | undefined,
    b: ListEntitiesRequest | PlainMessage<ListEntitiesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListEntitiesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListEntitiesResponse
 */
export class ListEntitiesResponse extends Message<ListEntitiesResponse> {
  /**
   * @generated from field: repeated grpc.type.v1.EntityIds entities = 1;
   */
  entities: EntityIds[] = [];

  constructor(data?: PartialMessage<ListEntitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListEntitiesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entities', kind: 'message', T: EntityIds, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEntitiesResponse {
    return new ListEntitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEntitiesResponse {
    return new ListEntitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEntitiesResponse {
    return new ListEntitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEntitiesResponse | PlainMessage<ListEntitiesResponse> | undefined,
    b: ListEntitiesResponse | PlainMessage<ListEntitiesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListEntitiesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DelegationPayload
 */
export class DelegationPayload extends Message<DelegationPayload> {
  /**
   * @generated from field: grpc.type.v1.UUID subject_id = 1;
   */
  subjectId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity object = 2;
   */
  object?: Entity;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 3;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<DelegationPayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DelegationPayload';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'message', T: UUID },
    { no: 2, name: 'object', kind: 'message', T: Entity },
    { no: 3, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DelegationPayload {
    return new DelegationPayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DelegationPayload {
    return new DelegationPayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DelegationPayload {
    return new DelegationPayload().fromJsonString(jsonString, options);
  }

  static equals(
    a: DelegationPayload | PlainMessage<DelegationPayload> | undefined,
    b: DelegationPayload | PlainMessage<DelegationPayload> | undefined
  ): boolean {
    return proto3.util.equals(DelegationPayload, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateDelegationsRequest
 */
export class CreateDelegationsRequest extends Message<CreateDelegationsRequest> {
  /**
   * @generated from field: repeated iam.auth.v1.DelegationPayload delegations = 1;
   */
  delegations: DelegationPayload[] = [];

  constructor(data?: PartialMessage<CreateDelegationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateDelegationsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'delegations', kind: 'message', T: DelegationPayload, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateDelegationsRequest {
    return new CreateDelegationsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateDelegationsRequest {
    return new CreateDelegationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateDelegationsRequest {
    return new CreateDelegationsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateDelegationsRequest | PlainMessage<CreateDelegationsRequest> | undefined,
    b: CreateDelegationsRequest | PlainMessage<CreateDelegationsRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateDelegationsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateDelegationsResponse
 */
export class CreateDelegationsResponse extends Message<CreateDelegationsResponse> {
  constructor(data?: PartialMessage<CreateDelegationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateDelegationsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateDelegationsResponse {
    return new CreateDelegationsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateDelegationsResponse {
    return new CreateDelegationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateDelegationsResponse {
    return new CreateDelegationsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateDelegationsResponse | PlainMessage<CreateDelegationsResponse> | undefined,
    b: CreateDelegationsResponse | PlainMessage<CreateDelegationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateDelegationsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteDelegationsRequest
 */
export class DeleteDelegationsRequest extends Message<DeleteDelegationsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID subject_id = 1;
   */
  subjectId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUIDS entity_ids = 2;
   */
  entityIds?: UUIDS;

  constructor(data?: PartialMessage<DeleteDelegationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteDelegationsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'message', T: UUID },
    { no: 2, name: 'entity_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteDelegationsRequest {
    return new DeleteDelegationsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteDelegationsRequest {
    return new DeleteDelegationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteDelegationsRequest {
    return new DeleteDelegationsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteDelegationsRequest | PlainMessage<DeleteDelegationsRequest> | undefined,
    b: DeleteDelegationsRequest | PlainMessage<DeleteDelegationsRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteDelegationsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteDelegationsResponse
 */
export class DeleteDelegationsResponse extends Message<DeleteDelegationsResponse> {
  constructor(data?: PartialMessage<DeleteDelegationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteDelegationsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteDelegationsResponse {
    return new DeleteDelegationsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteDelegationsResponse {
    return new DeleteDelegationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteDelegationsResponse {
    return new DeleteDelegationsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteDelegationsResponse | PlainMessage<DeleteDelegationsResponse> | undefined,
    b: DeleteDelegationsResponse | PlainMessage<DeleteDelegationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteDelegationsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListRolesRequest
 */
export class ListRolesRequest extends Message<ListRolesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  /**
   * @generated from field: optional grpc.type.v1.UUID subject_id = 2;
   */
  subjectId?: UUID;

  constructor(data?: PartialMessage<ListRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListRolesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
    { no: 2, name: 'subject_id', kind: 'message', T: UUID, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolesRequest {
    return new ListRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolesRequest {
    return new ListRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolesRequest {
    return new ListRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListRolesRequest | PlainMessage<ListRolesRequest> | undefined,
    b: ListRolesRequest | PlainMessage<ListRolesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListRolesResponse
 */
export class ListRolesResponse extends Message<ListRolesResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID subject_id = 1;
   */
  subjectId?: UUID;

  /**
   * @generated from field: repeated iam.auth.v1.ListRolesResponse.Role roles = 2;
   */
  roles: ListRolesResponse_Role[] = [];

  constructor(data?: PartialMessage<ListRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListRolesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'message', T: UUID },
    { no: 2, name: 'roles', kind: 'message', T: ListRolesResponse_Role, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolesResponse {
    return new ListRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolesResponse {
    return new ListRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolesResponse {
    return new ListRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListRolesResponse | PlainMessage<ListRolesResponse> | undefined,
    b: ListRolesResponse | PlainMessage<ListRolesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListRolesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListRolesResponse.Role
 */
export class ListRolesResponse_Role extends Message<ListRolesResponse_Role> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  /**
   * @generated from field: string name = 2;
   */
  name = '';

  /**
   * @generated from field: iam.auth.v1.SystemRole system = 3;
   */
  system = SystemRole.UNSPECIFIED;

  constructor(data?: PartialMessage<ListRolesResponse_Role>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListRolesResponse.Role';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'system', kind: 'enum', T: proto3.getEnumType(SystemRole) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListRolesResponse_Role {
    return new ListRolesResponse_Role().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListRolesResponse_Role {
    return new ListRolesResponse_Role().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListRolesResponse_Role {
    return new ListRolesResponse_Role().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListRolesResponse_Role | PlainMessage<ListRolesResponse_Role> | undefined,
    b: ListRolesResponse_Role | PlainMessage<ListRolesResponse_Role> | undefined
  ): boolean {
    return proto3.util.equals(ListRolesResponse_Role, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.GetRoleRequest
 */
export class GetRoleRequest extends Message<GetRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp offset = 2;
   */
  offset?: Timestamp;

  constructor(data?: PartialMessage<GetRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GetRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
    { no: 2, name: 'offset', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoleRequest {
    return new GetRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoleRequest {
    return new GetRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoleRequest {
    return new GetRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetRoleRequest | PlainMessage<GetRoleRequest> | undefined,
    b: GetRoleRequest | PlainMessage<GetRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.Policy
 */
export class Policy extends Message<Policy> {
  /**
   * @generated from field: grpc.type.v1.Entity.Type toe = 1;
   */
  toe = Entity_Type.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 3;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<Policy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.Policy';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'toe', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
    { no: 2, name: 'created', kind: 'message', T: Timestamp },
    { no: 3, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Policy {
    return new Policy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Policy {
    return new Policy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Policy {
    return new Policy().fromJsonString(jsonString, options);
  }

  static equals(
    a: Policy | PlainMessage<Policy> | undefined,
    b: Policy | PlainMessage<Policy> | undefined
  ): boolean {
    return proto3.util.equals(Policy, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.GetRoleResponse
 */
export class GetRoleResponse extends Message<GetRoleResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID role_id = 1;
   */
  roleId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 2;
   */
  domainId?: UUID;

  /**
   * @generated from field: string name = 3;
   */
  name = '';

  /**
   * @generated from field: iam.auth.v1.SystemRole system = 4;
   */
  system = SystemRole.UNSPECIFIED;

  /**
   * @generated from field: repeated iam.auth.v1.Policy policies = 5;
   */
  policies: Policy[] = [];

  /**
   * @generated from field: profiler.user.v1.Profiles members = 6;
   */
  members?: Profiles;

  constructor(data?: PartialMessage<GetRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GetRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'message', T: UUID },
    { no: 2, name: 'domain_id', kind: 'message', T: UUID },
    { no: 3, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'system', kind: 'enum', T: proto3.getEnumType(SystemRole) },
    { no: 5, name: 'policies', kind: 'message', T: Policy, repeated: true },
    { no: 6, name: 'members', kind: 'message', T: Profiles },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoleResponse {
    return new GetRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoleResponse {
    return new GetRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoleResponse {
    return new GetRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetRoleResponse | PlainMessage<GetRoleResponse> | undefined,
    b: GetRoleResponse | PlainMessage<GetRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListUserPermissionsRequest
 */
export class ListUserPermissionsRequest extends Message<ListUserPermissionsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  constructor(data?: PartialMessage<ListUserPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListUserPermissionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUserPermissionsRequest {
    return new ListUserPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUserPermissionsRequest {
    return new ListUserPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUserPermissionsRequest {
    return new ListUserPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUserPermissionsRequest | PlainMessage<ListUserPermissionsRequest> | undefined,
    b: ListUserPermissionsRequest | PlainMessage<ListUserPermissionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListUserPermissionsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListUserPermissionsResponse
 */
export class ListUserPermissionsResponse extends Message<ListUserPermissionsResponse> {
  /**
   * @generated from field: repeated iam.auth.v1.Policy policies = 1;
   */
  policies: Policy[] = [];

  constructor(data?: PartialMessage<ListUserPermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListUserPermissionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'policies', kind: 'message', T: Policy, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUserPermissionsResponse {
    return new ListUserPermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUserPermissionsResponse {
    return new ListUserPermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUserPermissionsResponse {
    return new ListUserPermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUserPermissionsResponse | PlainMessage<ListUserPermissionsResponse> | undefined,
    b: ListUserPermissionsResponse | PlainMessage<ListUserPermissionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListUserPermissionsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListEntityTypesRequest
 */
export class ListEntityTypesRequest extends Message<ListEntityTypesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS entity_ids = 1;
   */
  entityIds?: UUIDS;

  constructor(data?: PartialMessage<ListEntityTypesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListEntityTypesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEntityTypesRequest {
    return new ListEntityTypesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEntityTypesRequest {
    return new ListEntityTypesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEntityTypesRequest {
    return new ListEntityTypesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEntityTypesRequest | PlainMessage<ListEntityTypesRequest> | undefined,
    b: ListEntityTypesRequest | PlainMessage<ListEntityTypesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListEntityTypesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListEntityTypesResponse
 */
export class ListEntityTypesResponse extends Message<ListEntityTypesResponse> {
  /**
   * @generated from field: grpc.type.v1.Entities entities = 1;
   */
  entities?: Entities;

  constructor(data?: PartialMessage<ListEntityTypesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListEntityTypesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entities', kind: 'message', T: Entities },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEntityTypesResponse {
    return new ListEntityTypesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEntityTypesResponse {
    return new ListEntityTypesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEntityTypesResponse {
    return new ListEntityTypesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEntityTypesResponse | PlainMessage<ListEntityTypesResponse> | undefined,
    b: ListEntityTypesResponse | PlainMessage<ListEntityTypesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListEntityTypesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListDomainMembersWithPermissionsRequest
 */
export class ListDomainMembersWithPermissionsRequest extends Message<ListDomainMembersWithPermissionsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID domain_id = 1;
   */
  domainId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity.Type toe = 2;
   */
  toe = Entity_Type.UNSPECIFIED;

  constructor(data?: PartialMessage<ListDomainMembersWithPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListDomainMembersWithPermissionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'message', T: UUID },
    { no: 2, name: 'toe', kind: 'enum', T: proto3.getEnumType(Entity_Type) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDomainMembersWithPermissionsRequest {
    return new ListDomainMembersWithPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDomainMembersWithPermissionsRequest {
    return new ListDomainMembersWithPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDomainMembersWithPermissionsRequest {
    return new ListDomainMembersWithPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListDomainMembersWithPermissionsRequest
      | PlainMessage<ListDomainMembersWithPermissionsRequest>
      | undefined,
    b:
      | ListDomainMembersWithPermissionsRequest
      | PlainMessage<ListDomainMembersWithPermissionsRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(ListDomainMembersWithPermissionsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListDomainMembersWithPermissionsResponse
 */
export class ListDomainMembersWithPermissionsResponse extends Message<ListDomainMembersWithPermissionsResponse> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 1;
   */
  profileIds?: UUIDS;

  constructor(data?: PartialMessage<ListDomainMembersWithPermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListDomainMembersWithPermissionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDomainMembersWithPermissionsResponse {
    return new ListDomainMembersWithPermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDomainMembersWithPermissionsResponse {
    return new ListDomainMembersWithPermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDomainMembersWithPermissionsResponse {
    return new ListDomainMembersWithPermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListDomainMembersWithPermissionsResponse
      | PlainMessage<ListDomainMembersWithPermissionsResponse>
      | undefined,
    b:
      | ListDomainMembersWithPermissionsResponse
      | PlainMessage<ListDomainMembersWithPermissionsResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(ListDomainMembersWithPermissionsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListDelegationsRequest
 */
export class ListDelegationsRequest extends Message<ListDelegationsRequest> {
  /**
   * @generated from field: google.protobuf.Timestamp offset = 1;
   */
  offset?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.Entity domain = 2;
   */
  domain?: Entity;

  /**
   * @generated from field: grpc.type.v1.Entity selector = 3;
   */
  selector?: Entity;

  constructor(data?: PartialMessage<ListDelegationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListDelegationsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'offset', kind: 'message', T: Timestamp },
    { no: 2, name: 'domain', kind: 'message', T: Entity },
    { no: 3, name: 'selector', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDelegationsRequest {
    return new ListDelegationsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDelegationsRequest {
    return new ListDelegationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDelegationsRequest {
    return new ListDelegationsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListDelegationsRequest | PlainMessage<ListDelegationsRequest> | undefined,
    b: ListDelegationsRequest | PlainMessage<ListDelegationsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListDelegationsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListDelegationsResponse
 */
export class ListDelegationsResponse extends Message<ListDelegationsResponse> {
  /**
   * @generated from field: repeated iam.auth.v1.ListDelegationsResponse.Delegation delegations = 1;
   */
  delegations: ListDelegationsResponse_Delegation[] = [];

  constructor(data?: PartialMessage<ListDelegationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListDelegationsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'delegations',
      kind: 'message',
      T: ListDelegationsResponse_Delegation,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDelegationsResponse {
    return new ListDelegationsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDelegationsResponse {
    return new ListDelegationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDelegationsResponse {
    return new ListDelegationsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListDelegationsResponse | PlainMessage<ListDelegationsResponse> | undefined,
    b: ListDelegationsResponse | PlainMessage<ListDelegationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListDelegationsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListDelegationsResponse.Delegation
 */
export class ListDelegationsResponse_Delegation extends Message<ListDelegationsResponse_Delegation> {
  /**
   * @generated from field: iam.auth.v1.DelegationPayload delegation = 1;
   */
  delegation?: DelegationPayload;

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  constructor(data?: PartialMessage<ListDelegationsResponse_Delegation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListDelegationsResponse.Delegation';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'delegation', kind: 'message', T: DelegationPayload },
    { no: 3, name: 'created', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDelegationsResponse_Delegation {
    return new ListDelegationsResponse_Delegation().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDelegationsResponse_Delegation {
    return new ListDelegationsResponse_Delegation().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDelegationsResponse_Delegation {
    return new ListDelegationsResponse_Delegation().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListDelegationsResponse_Delegation
      | PlainMessage<ListDelegationsResponse_Delegation>
      | undefined,
    b:
      | ListDelegationsResponse_Delegation
      | PlainMessage<ListDelegationsResponse_Delegation>
      | undefined
  ): boolean {
    return proto3.util.equals(ListDelegationsResponse_Delegation, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListEmailsByUserIdRequest
 */
export class ListEmailsByUserIdRequest extends Message<ListEmailsByUserIdRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 1;
   */
  profileIds?: UUIDS;

  constructor(data?: PartialMessage<ListEmailsByUserIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListEmailsByUserIdRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEmailsByUserIdRequest {
    return new ListEmailsByUserIdRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEmailsByUserIdRequest {
    return new ListEmailsByUserIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEmailsByUserIdRequest {
    return new ListEmailsByUserIdRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEmailsByUserIdRequest | PlainMessage<ListEmailsByUserIdRequest> | undefined,
    b: ListEmailsByUserIdRequest | PlainMessage<ListEmailsByUserIdRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListEmailsByUserIdRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListEmailsByUserIdResponse
 */
export class ListEmailsByUserIdResponse extends Message<ListEmailsByUserIdResponse> {
  /**
   * profileId => email
   *
   * @generated from field: map<string, string> emails = 1;
   */
  emails: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ListEmailsByUserIdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListEmailsByUserIdResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'emails',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEmailsByUserIdResponse {
    return new ListEmailsByUserIdResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEmailsByUserIdResponse {
    return new ListEmailsByUserIdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEmailsByUserIdResponse {
    return new ListEmailsByUserIdResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEmailsByUserIdResponse | PlainMessage<ListEmailsByUserIdResponse> | undefined,
    b: ListEmailsByUserIdResponse | PlainMessage<ListEmailsByUserIdResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListEmailsByUserIdResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListUsersByEmailsRequest
 */
export class ListUsersByEmailsRequest extends Message<ListUsersByEmailsRequest> {
  /**
   * @generated from field: grpc.type.v1.Strings emails = 1;
   */
  emails?: Strings;

  constructor(data?: PartialMessage<ListUsersByEmailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListUsersByEmailsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'emails', kind: 'message', T: Strings },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUsersByEmailsRequest {
    return new ListUsersByEmailsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUsersByEmailsRequest {
    return new ListUsersByEmailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUsersByEmailsRequest {
    return new ListUsersByEmailsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUsersByEmailsRequest | PlainMessage<ListUsersByEmailsRequest> | undefined,
    b: ListUsersByEmailsRequest | PlainMessage<ListUsersByEmailsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListUsersByEmailsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListUsersByEmailsResponse
 */
export class ListUsersByEmailsResponse extends Message<ListUsersByEmailsResponse> {
  /**
   * email => profileId
   *
   * @generated from field: map<string, string> profile_ids = 1;
   */
  profileIds: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ListUsersByEmailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListUsersByEmailsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'profile_ids',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUsersByEmailsResponse {
    return new ListUsersByEmailsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUsersByEmailsResponse {
    return new ListUsersByEmailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUsersByEmailsResponse {
    return new ListUsersByEmailsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUsersByEmailsResponse | PlainMessage<ListUsersByEmailsResponse> | undefined,
    b: ListUsersByEmailsResponse | PlainMessage<ListUsersByEmailsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListUsersByEmailsResponse, a, b);
  }
}
