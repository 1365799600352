// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/invite/v1/invite.proto (package profiler.invite.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Strings } from '../../../grpc/type/v1/types_pb.js';
import { Profile, ProfileName } from '../../user/v1/profile_pb.js';

/**
 * @generated from message profiler.invite.v1.SendInvitesRequest
 */
export class SendInvitesRequest extends Message<SendInvitesRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from oneof profiler.invite.v1.SendInvitesRequest.recipients
   */
  recipients:
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS profiles = 2;
         */
        value: UUIDS;
        case: 'profiles';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.Strings emails = 3;
         */
        value: Strings;
        case: 'emails';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SendInvitesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.SendInvitesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'profiles', kind: 'message', T: UUIDS, oneof: 'recipients' },
    { no: 3, name: 'emails', kind: 'message', T: Strings, oneof: 'recipients' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendInvitesRequest {
    return new SendInvitesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendInvitesRequest {
    return new SendInvitesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendInvitesRequest {
    return new SendInvitesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendInvitesRequest | PlainMessage<SendInvitesRequest> | undefined,
    b: SendInvitesRequest | PlainMessage<SendInvitesRequest> | undefined
  ): boolean {
    return proto3.util.equals(SendInvitesRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.SendInvitesResponse
 */
export class SendInvitesResponse extends Message<SendInvitesResponse> {
  constructor(data?: PartialMessage<SendInvitesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.SendInvitesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendInvitesResponse {
    return new SendInvitesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendInvitesResponse {
    return new SendInvitesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendInvitesResponse {
    return new SendInvitesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendInvitesResponse | PlainMessage<SendInvitesResponse> | undefined,
    b: SendInvitesResponse | PlainMessage<SendInvitesResponse> | undefined
  ): boolean {
    return proto3.util.equals(SendInvitesResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.AcceptInviteRequest
 */
export class AcceptInviteRequest extends Message<AcceptInviteRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  constructor(data?: PartialMessage<AcceptInviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.AcceptInviteRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptInviteRequest {
    return new AcceptInviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptInviteRequest {
    return new AcceptInviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptInviteRequest {
    return new AcceptInviteRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AcceptInviteRequest | PlainMessage<AcceptInviteRequest> | undefined,
    b: AcceptInviteRequest | PlainMessage<AcceptInviteRequest> | undefined
  ): boolean {
    return proto3.util.equals(AcceptInviteRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.AcceptInviteResponse
 */
export class AcceptInviteResponse extends Message<AcceptInviteResponse> {
  constructor(data?: PartialMessage<AcceptInviteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.AcceptInviteResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptInviteResponse {
    return new AcceptInviteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptInviteResponse {
    return new AcceptInviteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptInviteResponse {
    return new AcceptInviteResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AcceptInviteResponse | PlainMessage<AcceptInviteResponse> | undefined,
    b: AcceptInviteResponse | PlainMessage<AcceptInviteResponse> | undefined
  ): boolean {
    return proto3.util.equals(AcceptInviteResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.RejectInviteRequest
 */
export class RejectInviteRequest extends Message<RejectInviteRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  constructor(data?: PartialMessage<RejectInviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.RejectInviteRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RejectInviteRequest {
    return new RejectInviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RejectInviteRequest {
    return new RejectInviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RejectInviteRequest {
    return new RejectInviteRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RejectInviteRequest | PlainMessage<RejectInviteRequest> | undefined,
    b: RejectInviteRequest | PlainMessage<RejectInviteRequest> | undefined
  ): boolean {
    return proto3.util.equals(RejectInviteRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.RejectInviteResponse
 */
export class RejectInviteResponse extends Message<RejectInviteResponse> {
  constructor(data?: PartialMessage<RejectInviteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.RejectInviteResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RejectInviteResponse {
    return new RejectInviteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RejectInviteResponse {
    return new RejectInviteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RejectInviteResponse {
    return new RejectInviteResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RejectInviteResponse | PlainMessage<RejectInviteResponse> | undefined,
    b: RejectInviteResponse | PlainMessage<RejectInviteResponse> | undefined
  ): boolean {
    return proto3.util.equals(RejectInviteResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.UserInvite
 */
export class UserInvite extends Message<UserInvite> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp invited_at = 2;
   */
  invitedAt?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.Entity entity = 3;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<UserInvite>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.UserInvite';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
    { no: 2, name: 'invited_at', kind: 'message', T: Timestamp },
    { no: 3, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInvite {
    return new UserInvite().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInvite {
    return new UserInvite().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInvite {
    return new UserInvite().fromJsonString(jsonString, options);
  }

  static equals(
    a: UserInvite | PlainMessage<UserInvite> | undefined,
    b: UserInvite | PlainMessage<UserInvite> | undefined
  ): boolean {
    return proto3.util.equals(UserInvite, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesRequest
 */
export class ListInvitesRequest extends Message<ListInvitesRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<ListInvitesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvitesRequest {
    return new ListInvitesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvitesRequest {
    return new ListInvitesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesRequest {
    return new ListInvitesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesRequest | PlainMessage<ListInvitesRequest> | undefined,
    b: ListInvitesRequest | PlainMessage<ListInvitesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesResponse
 */
export class ListInvitesResponse extends Message<ListInvitesResponse> {
  /**
   * @generated from field: repeated profiler.invite.v1.ListInvitesResponse.Invite invites = 1;
   */
  invites: ListInvitesResponse_Invite[] = [];

  constructor(data?: PartialMessage<ListInvitesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invites', kind: 'message', T: ListInvitesResponse_Invite, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvitesResponse {
    return new ListInvitesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvitesResponse {
    return new ListInvitesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse {
    return new ListInvitesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesResponse | PlainMessage<ListInvitesResponse> | undefined,
    b: ListInvitesResponse | PlainMessage<ListInvitesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesResponse.Invite
 */
export class ListInvitesResponse_Invite extends Message<ListInvitesResponse_Invite> {
  /**
   * @generated from field: profiler.user.v1.Profile inviter = 1;
   */
  inviter?: Profile;

  /**
   * @generated from field: google.protobuf.Timestamp invited_at = 2;
   */
  invitedAt?: Timestamp;

  /**
   * @generated from oneof profiler.invite.v1.ListInvitesResponse.Invite.invite
   */
  invite:
    | {
        /**
         * @generated from field: profiler.invite.v1.ListInvitesResponse.Direct direct = 3;
         */
        value: ListInvitesResponse_Direct;
        case: 'direct';
      }
    | {
        /**
         * @generated from field: profiler.invite.v1.ListInvitesResponse.Link link = 4;
         */
        value: ListInvitesResponse_Link;
        case: 'link';
      }
    | {
        /**
         * @generated from field: string email = 5;
         */
        value: string;
        case: 'email';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListInvitesResponse_Invite>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesResponse.Invite';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'inviter', kind: 'message', T: Profile },
    { no: 2, name: 'invited_at', kind: 'message', T: Timestamp },
    { no: 3, name: 'direct', kind: 'message', T: ListInvitesResponse_Direct, oneof: 'invite' },
    { no: 4, name: 'link', kind: 'message', T: ListInvitesResponse_Link, oneof: 'invite' },
    { no: 5, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'invite' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInvitesResponse_Invite {
    return new ListInvitesResponse_Invite().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse_Invite {
    return new ListInvitesResponse_Invite().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse_Invite {
    return new ListInvitesResponse_Invite().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesResponse_Invite | PlainMessage<ListInvitesResponse_Invite> | undefined,
    b: ListInvitesResponse_Invite | PlainMessage<ListInvitesResponse_Invite> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesResponse_Invite, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesResponse.Direct
 */
export class ListInvitesResponse_Direct extends Message<ListInvitesResponse_Direct> {
  /**
   * @generated from field: grpc.type.v1.UUID invite_id = 1;
   */
  inviteId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 2;
   */
  profileId?: UUID;

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 3;
   */
  name?: ProfileName;

  constructor(data?: PartialMessage<ListInvitesResponse_Direct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesResponse.Direct';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_id', kind: 'message', T: UUID },
    { no: 2, name: 'profile_id', kind: 'message', T: UUID },
    { no: 3, name: 'name', kind: 'message', T: ProfileName },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInvitesResponse_Direct {
    return new ListInvitesResponse_Direct().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse_Direct {
    return new ListInvitesResponse_Direct().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse_Direct {
    return new ListInvitesResponse_Direct().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesResponse_Direct | PlainMessage<ListInvitesResponse_Direct> | undefined,
    b: ListInvitesResponse_Direct | PlainMessage<ListInvitesResponse_Direct> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesResponse_Direct, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListInvitesResponse.Link
 */
export class ListInvitesResponse_Link extends Message<ListInvitesResponse_Link> {
  /**
   * @generated from field: string code = 1;
   */
  code = '';

  /**
   * @generated from oneof profiler.invite.v1.ListInvitesResponse.Link.policy
   */
  policy:
    | {
        /**
         * @generated from field: google.protobuf.Timestamp expires_at = 3;
         */
        value: Timestamp;
        case: 'expiresAt';
      }
    | {
        /**
         * @generated from field: bool one_time = 4;
         */
        value: boolean;
        case: 'oneTime';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListInvitesResponse_Link>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListInvitesResponse.Link';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'expires_at', kind: 'message', T: Timestamp, oneof: 'policy' },
    { no: 4, name: 'one_time', kind: 'scalar', T: 8 /* ScalarType.BOOL */, oneof: 'policy' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInvitesResponse_Link {
    return new ListInvitesResponse_Link().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse_Link {
    return new ListInvitesResponse_Link().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInvitesResponse_Link {
    return new ListInvitesResponse_Link().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInvitesResponse_Link | PlainMessage<ListInvitesResponse_Link> | undefined,
    b: ListInvitesResponse_Link | PlainMessage<ListInvitesResponse_Link> | undefined
  ): boolean {
    return proto3.util.equals(ListInvitesResponse_Link, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListUserInvitesRequest
 */
export class ListUserInvitesRequest extends Message<ListUserInvitesRequest> {
  constructor(data?: PartialMessage<ListUserInvitesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListUserInvitesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUserInvitesRequest {
    return new ListUserInvitesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesRequest {
    return new ListUserInvitesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesRequest {
    return new ListUserInvitesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUserInvitesRequest | PlainMessage<ListUserInvitesRequest> | undefined,
    b: ListUserInvitesRequest | PlainMessage<ListUserInvitesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListUserInvitesRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.ListUserInvitesResponse
 */
export class ListUserInvitesResponse extends Message<ListUserInvitesResponse> {
  /**
   * @generated from field: repeated profiler.invite.v1.UserInvite invites = 1;
   */
  invites: UserInvite[] = [];

  constructor(data?: PartialMessage<ListUserInvitesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.ListUserInvitesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invites', kind: 'message', T: UserInvite, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListUserInvitesResponse {
    return new ListUserInvitesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesResponse {
    return new ListUserInvitesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListUserInvitesResponse {
    return new ListUserInvitesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListUserInvitesResponse | PlainMessage<ListUserInvitesResponse> | undefined,
    b: ListUserInvitesResponse | PlainMessage<ListUserInvitesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListUserInvitesResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.GenerateInviteLinkRequest
 */
export class GenerateInviteLinkRequest extends Message<GenerateInviteLinkRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from oneof profiler.invite.v1.GenerateInviteLinkRequest.policy
   */
  policy:
    | {
        /**
         * @generated from field: profiler.invite.v1.GenerateInviteLinkRequest.ExpiresAt expires_at = 2;
         */
        value: GenerateInviteLinkRequest_ExpiresAt;
        case: 'expiresAt';
      }
    | {
        /**
         * @generated from field: bool one_time = 3;
         */
        value: boolean;
        case: 'oneTime';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GenerateInviteLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.GenerateInviteLinkRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    {
      no: 2,
      name: 'expires_at',
      kind: 'enum',
      T: proto3.getEnumType(GenerateInviteLinkRequest_ExpiresAt),
      oneof: 'policy',
    },
    { no: 3, name: 'one_time', kind: 'scalar', T: 8 /* ScalarType.BOOL */, oneof: 'policy' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GenerateInviteLinkRequest {
    return new GenerateInviteLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GenerateInviteLinkRequest {
    return new GenerateInviteLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GenerateInviteLinkRequest {
    return new GenerateInviteLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GenerateInviteLinkRequest | PlainMessage<GenerateInviteLinkRequest> | undefined,
    b: GenerateInviteLinkRequest | PlainMessage<GenerateInviteLinkRequest> | undefined
  ): boolean {
    return proto3.util.equals(GenerateInviteLinkRequest, a, b);
  }
}

/**
 * @generated from enum profiler.invite.v1.GenerateInviteLinkRequest.ExpiresAt
 */
export enum GenerateInviteLinkRequest_ExpiresAt {
  /**
   * @generated from enum value: EXPIRES_AT_UNSPECIFIED = 0;
   */
  EXPIRES_AT_UNSPECIFIED = 0,

  /**
   * @generated from enum value: EXPIRES_AT_1_DAY = 1;
   */
  EXPIRES_AT_1_DAY = 1,

  /**
   * @generated from enum value: EXPIRES_AT_1_WEEK = 2;
   */
  EXPIRES_AT_1_WEEK = 2,

  /**
   * @generated from enum value: EXPIRES_AT_1_MONTH = 3;
   */
  EXPIRES_AT_1_MONTH = 3,

  /**
   * @generated from enum value: EXPIRES_AT_1_YEAR = 4;
   */
  EXPIRES_AT_1_YEAR = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(GenerateInviteLinkRequest_ExpiresAt)
proto3.util.setEnumType(
  GenerateInviteLinkRequest_ExpiresAt,
  'profiler.invite.v1.GenerateInviteLinkRequest.ExpiresAt',
  [
    { no: 0, name: 'EXPIRES_AT_UNSPECIFIED' },
    { no: 1, name: 'EXPIRES_AT_1_DAY' },
    { no: 2, name: 'EXPIRES_AT_1_WEEK' },
    { no: 3, name: 'EXPIRES_AT_1_MONTH' },
    { no: 4, name: 'EXPIRES_AT_1_YEAR' },
  ]
);

/**
 * @generated from message profiler.invite.v1.GenerateInviteLinkResponse
 */
export class GenerateInviteLinkResponse extends Message<GenerateInviteLinkResponse> {
  /**
   * @generated from field: string code = 1;
   */
  code = '';

  constructor(data?: PartialMessage<GenerateInviteLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.GenerateInviteLinkResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GenerateInviteLinkResponse {
    return new GenerateInviteLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GenerateInviteLinkResponse {
    return new GenerateInviteLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GenerateInviteLinkResponse {
    return new GenerateInviteLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GenerateInviteLinkResponse | PlainMessage<GenerateInviteLinkResponse> | undefined,
    b: GenerateInviteLinkResponse | PlainMessage<GenerateInviteLinkResponse> | undefined
  ): boolean {
    return proto3.util.equals(GenerateInviteLinkResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.RevokeInviteRequest
 */
export class RevokeInviteRequest extends Message<RevokeInviteRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 1;
   */
  entityId?: UUID;

  /**
   * @generated from oneof profiler.invite.v1.RevokeInviteRequest.invite
   */
  invite:
    | {
        /**
         * @generated from field: string code = 2;
         */
        value: string;
        case: 'code';
      }
    | {
        /**
         * @generated from field: string email = 3;
         */
        value: string;
        case: 'email';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUID invite_id = 4;
         */
        value: UUID;
        case: 'inviteId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RevokeInviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.RevokeInviteRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: UUID },
    { no: 2, name: 'code', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'invite' },
    { no: 3, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'invite' },
    { no: 4, name: 'invite_id', kind: 'message', T: UUID, oneof: 'invite' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RevokeInviteRequest {
    return new RevokeInviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RevokeInviteRequest {
    return new RevokeInviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RevokeInviteRequest {
    return new RevokeInviteRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RevokeInviteRequest | PlainMessage<RevokeInviteRequest> | undefined,
    b: RevokeInviteRequest | PlainMessage<RevokeInviteRequest> | undefined
  ): boolean {
    return proto3.util.equals(RevokeInviteRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.RevokeInviteResponse
 */
export class RevokeInviteResponse extends Message<RevokeInviteResponse> {
  constructor(data?: PartialMessage<RevokeInviteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.RevokeInviteResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RevokeInviteResponse {
    return new RevokeInviteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RevokeInviteResponse {
    return new RevokeInviteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RevokeInviteResponse {
    return new RevokeInviteResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RevokeInviteResponse | PlainMessage<RevokeInviteResponse> | undefined,
    b: RevokeInviteResponse | PlainMessage<RevokeInviteResponse> | undefined
  ): boolean {
    return proto3.util.equals(RevokeInviteResponse, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.JoinByInviteLinkRequest
 */
export class JoinByInviteLinkRequest extends Message<JoinByInviteLinkRequest> {
  /**
   * @generated from field: string code = 1;
   */
  code = '';

  constructor(data?: PartialMessage<JoinByInviteLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.JoinByInviteLinkRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): JoinByInviteLinkRequest {
    return new JoinByInviteLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): JoinByInviteLinkRequest {
    return new JoinByInviteLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): JoinByInviteLinkRequest {
    return new JoinByInviteLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: JoinByInviteLinkRequest | PlainMessage<JoinByInviteLinkRequest> | undefined,
    b: JoinByInviteLinkRequest | PlainMessage<JoinByInviteLinkRequest> | undefined
  ): boolean {
    return proto3.util.equals(JoinByInviteLinkRequest, a, b);
  }
}

/**
 * @generated from message profiler.invite.v1.JoinByInviteLinkResponse
 */
export class JoinByInviteLinkResponse extends Message<JoinByInviteLinkResponse> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<JoinByInviteLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.invite.v1.JoinByInviteLinkResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): JoinByInviteLinkResponse {
    return new JoinByInviteLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): JoinByInviteLinkResponse {
    return new JoinByInviteLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): JoinByInviteLinkResponse {
    return new JoinByInviteLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: JoinByInviteLinkResponse | PlainMessage<JoinByInviteLinkResponse> | undefined,
    b: JoinByInviteLinkResponse | PlainMessage<JoinByInviteLinkResponse> | undefined
  ): boolean {
    return proto3.util.equals(JoinByInviteLinkResponse, a, b);
  }
}
