import Box from '@mui/material/Box';
import { useListMembers } from '@services/queries/MembershipsQueries';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';
import { AddUserToRoleRequest } from '@proto/api/iam/v1/iam_pb';
import { DeleteUserFromRoleRequest } from '@proto/iam/auth/v1/iam_pb';
import { useAddUserToRole, useDeleteUserFromRole, useGetRole } from '@services/queries/IAMQueries';
import Typography from '@mui/material/Typography';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { ListMembersRequest } from '@proto/profiler/membership/v1/membership_pb';

const RolesAutocomplete = ({ entityId, roleId }: { entityId: string; roleId: UUID }) => {
  const { data: getRole, isLoading } = useGetRole(roleId);
  const { mutate: addUserToRole } = useAddUserToRole();
  const { mutate: deleteUserFromRole } = useDeleteUserFromRole();
  const { data: listMembers } = useListMembers(
    new ListMembersRequest({
      by: {
        case: 'entityId',
        value: new UUID({ value: entityId }),
      },
    }),
    true
  );

  const defaultOptions = getRole?.members?.profiles?.map((profile) => {
    return { label: profile?.name?.nickName, value: profile.profileId?.value };
  });
  const items = listMembers?.memberships[0].members
    .map((member) => member.profile.value as Profile)
    ?.map((profile) => {
      return { label: profile?.name?.nickName, value: profile.profileId?.value };
    });

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    reaeson: string,
    details: any
  ) => {
    if (reaeson === 'selectOption') {
      return addUserToRole(
        new AddUserToRoleRequest({
          profileId: new UUID({ value: details.option.value }),
          roleId,
        })
      );
    }
    if (reaeson === 'removeOption') {
      return deleteUserFromRole(
        new DeleteUserFromRoleRequest({
          profileId: new UUID({ value: details.option.value }),
          roleId,
        })
      );
    }
    return 'success';
  };

  return (
    <Box>
      <Typography variant="subtitle2" mb={2}>
        Add users to role
      </Typography>
      {items && !isLoading && (
        <Autocomplete
          renderInput={(params) => <TextField {...params} label="Choose peoples" />}
          multiple
          defaultValue={defaultOptions}
          onChange={handleChange}
          options={items}
        />
      )}
    </Box>
  );
};

export default RolesAutocomplete;
