import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Contract, Contract_Traits_Hourly } from '@proto/marketplace/demand/v1/contract_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Traits } from '@proto/profiler/user/v1/profile_pb';
import PinUnpinButton from '@components/PinUnpinButton/PinUnpinButton';
import { typesDemand } from '@pages/Marketplace/Feed/DemandCard';

const DemandCardHeader = ({
  demand,
  matches,
  demandId,
}: {
  demand: Demand;
  matches?: (Traits & { experience: number }) | undefined;
  demandId?: string;
}) => {
  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  return (
    <Box className="flex md:items-center flex-col md:flex-row gap-4">
      <Box className="flex items-center justify-between gap-4">
        <Box className="flex items-center justify-between gap-4">
          <Box display="flex" alignItems="center">
            <Typography
              fontSize="13px"
              fontWeight="bold"
              className={`capitalize ${matches ? 'text-[#7986cb]' : 'text-[white]'}`}
            >
              {demand?.entity?.case}
            </Typography>
          </Box>
          {demand.entity.value?.tags?.tags
            .filter((tag) => tag.tagType === TagType.ROLE)
            .map((tag) => {
              return (
                <Chip
                  size="small"
                  color={`${matches ? 'primary' : 'default'}`}
                  key={tag.tagId}
                  variant="outlined"
                  className="!font-bold"
                  label={tag.value}
                />
              );
            })}
        </Box>
        <div className="hidden md:block w-2 h-2 rounded-full bg-white/70" />

        {demand?.entity?.case && demandId && (
          <Box className="md:hidden" onClick={handleCheckboxClick}>
            <PinUnpinButton type={typesDemand[demand?.entity?.case]} id={demandId} />
          </Box>
        )}
      </Box>

      <Box display="flex" className="font-bold items-center" flexDirection="row" gap={2}>
        {(demand.entity.value as Contract)?.traits?.payment?.case === 'fixed' ? (
          <Box display="flex" gap={2} alignItems="center" color={matches ? '#7986cb' : 'white'}>
            <Typography fontSize="14px">
              Fix ${Number((demand.entity.value as Contract)?.traits?.payment?.value)}
            </Typography>
            <div className="w-2 h-2 rounded-full bg-white/70" />
          </Box>
        ) : null}

        {(demand.entity.value as Contract)?.traits?.payment?.case === 'hourly' &&
        Number((demand.entity.value as Contract)?.traits?.payment?.value) !== 0 ? (
          <Box gap={2} className="flex items-center">
            <Box className="flex cursor-pointer   items-center   gap-2">
              <Typography fontSize="14px">
                $
                {Number(
                  (
                    (demand.entity.value as Contract)?.traits?.payment
                      ?.value as Contract_Traits_Hourly
                  ).rate
                ).toFixed(0)}
                /h
              </Typography>
              <Typography fontSize="14px">
                {
                  (
                    (demand.entity.value as Contract)?.traits?.payment
                      ?.value as Contract_Traits_Hourly
                  )?.capacity
                }{' '}
                capacity
              </Typography>
            </Box>
            <div className="w-2 h-2 rounded-full bg-white/70" />
          </Box>
        ) : null}

        {(demand.entity.value as Interview)?.traits?.price ? (
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            // color={matches?.rate > 0 ? '#6FF9BF' : 'white'}
          >
            <Typography fontSize="14px">
              Fix: ${Number((demand.entity.value as Interview)?.traits?.price)}
            </Typography>
            <div className="w-2 h-2 rounded-full bg-white/70" />
          </Box>
        ) : null}

        {demand.entity.value?.traits?.experience ? (
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            color={matches && matches.experience > 0 ? '#7986cb' : 'white'}
          >
            <Typography fontSize="14px">
              {' '}
              {demand.entity.value.traits.experience}+ experience years{' '}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default DemandCardHeader;
