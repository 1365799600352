import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useProfiler } from '@store/profiler';
import Box from '@mui/material/Box';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import Joi from 'joi';
import { Chip } from '@mui/material';
import { useSendInvite, useSendInviteByEmails } from '@services/queries/ProfilesQueries';
import EmailAutocomplete from '@components/AddMemberDialog/EmailsAutocomplete';
import { Strings } from '@proto/grpc/type/v1/types_pb';
import GenerateLinkDialog from '@components/GenerateLink/GenerateLinkDialog';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';

type AddMemberProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId: string;
  entity: Entity_Type;
  objectName?: string;
};

export const SendInviteByEmailSchema = {
  email: Joi.array().min(1).required(),
};

const AddMemberDialog = (props: AddMemberProps) => {
  const { isOpen, handleClose, entityId, entity, objectName } = props;
  const { selectedProfile } = useProfiler();

  const { mutate: sendInvitesByEmails } = useSendInviteByEmails();
  const [inviteType, setInviteType] = React.useState<'email' | 'user'>('user');
  const { mutate: sendInvites } = useSendInvite();
  const sendInvite = () => {
    if (selectedProfile && entity && entityId) {
      sendInvites({
        id: entityId,
        type: entity,
        profileIds: [selectedProfile?.profileId?.value as string],
      });
    }
  };

  const handleSubmit = () => {
    sendInvite();
    handleClose();
  };

  return (
    <Box>
      <Dialog onClose={handleClose} open={isOpen}>
        <Box display="flex" flexDirection="column" px={2} py={4} gap={2} sx={{ width: '600px' }}>
          <Typography textAlign="center" variant="subtitle1">
            New invite {objectName ? `to ${objectName}` : ''}
          </Typography>
          <Box display="flex" justifyContent="center">
            {inviteType === 'user' ? (
              <Chip
                size="small"
                variant="outlined"
                onClick={() => setInviteType('email')}
                label="Send invitation by e-mail"
              />
            ) : (
              <Chip
                size="small"
                variant="outlined"
                onClick={() => setInviteType('user')}
                label="Find a user on our platform"
              />
            )}
          </Box>

          {inviteType === 'user' ? (
            <Box display="flex" flexDirection="column" gap={1}>
              <GenerateLinkDialog
                entity={
                  new Entity({
                    id: new UUID({ value: entityId }),
                    type: entity,
                  })
                }
              />
            </Box>
          ) : (
            <Form
              width="100%"
              display="flex"
              alignSelf="center"
              flexDirection="column"
              formProps={{ defaultValues: { email: [] } }}
              submitHandler={(data) => {
                sendInvitesByEmails({
                  id: entityId,
                  type: entity,
                  emails: new Strings({ values: data.email }),
                });
                handleClose();
              }}
              resolverSchema={SendInviteByEmailSchema}
            >
              <Box display="flex" flexDirection="column" gap={4}>
                <EmailAutocomplete name="email" />
                <Box display="flex" justifyContent="center">
                  <LoadingButton type="submit" variant="contained">
                    Send Invite
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddMemberDialog;
