// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/supply.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';

/**
 * @generated from message marketplace.demand.v1.Supply
 */
export class Supply extends Message<Supply> {
  /**
   * @generated from field: grpc.type.v1.UUID supply_id = 1;
   */
  supplyId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity supplier = 2;
   */
  supplier?: Entity;

  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 3;
   */
  profileIds?: UUIDS;

  /**
   * @generated from field: marketplace.demand.v1.Supply.Status status = 4;
   */
  status?: Supply_Status;

  constructor(data?: PartialMessage<Supply>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Supply';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'message', T: UUID },
    { no: 2, name: 'supplier', kind: 'message', T: Entity },
    { no: 3, name: 'profile_ids', kind: 'message', T: UUIDS },
    { no: 4, name: 'status', kind: 'message', T: Supply_Status },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Supply {
    return new Supply().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Supply {
    return new Supply().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Supply {
    return new Supply().fromJsonString(jsonString, options);
  }

  static equals(
    a: Supply | PlainMessage<Supply> | undefined,
    b: Supply | PlainMessage<Supply> | undefined
  ): boolean {
    return proto3.util.equals(Supply, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Supply.Status
 */
export class Supply_Status extends Message<Supply_Status> {
  /**
   * @generated from field: google.protobuf.Timestamp applied_at = 4;
   */
  appliedAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp approved_at = 5;
   */
  approvedAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp rejected_at = 6;
   */
  rejectedAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp released_at = 7;
   */
  releasedAt?: Timestamp;

  constructor(data?: PartialMessage<Supply_Status>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Supply.Status';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: 'applied_at', kind: 'message', T: Timestamp },
    { no: 5, name: 'approved_at', kind: 'message', T: Timestamp, opt: true },
    { no: 6, name: 'rejected_at', kind: 'message', T: Timestamp, opt: true },
    { no: 7, name: 'released_at', kind: 'message', T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Supply_Status {
    return new Supply_Status().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Supply_Status {
    return new Supply_Status().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Supply_Status {
    return new Supply_Status().fromJsonString(jsonString, options);
  }

  static equals(
    a: Supply_Status | PlainMessage<Supply_Status> | undefined,
    b: Supply_Status | PlainMessage<Supply_Status> | undefined
  ): boolean {
    return proto3.util.equals(Supply_Status, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SupplyList
 */
export class SupplyList extends Message<SupplyList> {
  /**
   * @generated from field: repeated marketplace.demand.v1.Supply list = 1;
   */
  list: Supply[] = [];

  constructor(data?: PartialMessage<SupplyList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SupplyList';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'list', kind: 'message', T: Supply, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupplyList {
    return new SupplyList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupplyList {
    return new SupplyList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupplyList {
    return new SupplyList().fromJsonString(jsonString, options);
  }

  static equals(
    a: SupplyList | PlainMessage<SupplyList> | undefined,
    b: SupplyList | PlainMessage<SupplyList> | undefined
  ): boolean {
    return proto3.util.equals(SupplyList, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SupplyResponse
 */
export class SupplyResponse extends Message<SupplyResponse> {
  /**
   * @generated from oneof marketplace.demand.v1.SupplyResponse.view
   */
  view:
    | {
        /**
         * @generated from field: marketplace.demand.v1.SupplyList list = 1;
         */
        value: SupplyList;
        case: 'list';
      }
    | {
        /**
         * @generated from field: int32 count = 2;
         */
        value: number;
        case: 'count';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SupplyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SupplyResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'list', kind: 'message', T: SupplyList, oneof: 'view' },
    { no: 2, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */, oneof: 'view' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupplyResponse {
    return new SupplyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupplyResponse {
    return new SupplyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupplyResponse {
    return new SupplyResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SupplyResponse | PlainMessage<SupplyResponse> | undefined,
    b: SupplyResponse | PlainMessage<SupplyResponse> | undefined
  ): boolean {
    return proto3.util.equals(SupplyResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.InitSupplyContactsOpeningRequest
 */
export class InitSupplyContactsOpeningRequest extends Message<InitSupplyContactsOpeningRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID supply_id = 1;
   */
  supplyId?: UUID;

  constructor(data?: PartialMessage<InitSupplyContactsOpeningRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.InitSupplyContactsOpeningRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): InitSupplyContactsOpeningRequest {
    return new InitSupplyContactsOpeningRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): InitSupplyContactsOpeningRequest {
    return new InitSupplyContactsOpeningRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): InitSupplyContactsOpeningRequest {
    return new InitSupplyContactsOpeningRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | InitSupplyContactsOpeningRequest
      | PlainMessage<InitSupplyContactsOpeningRequest>
      | undefined,
    b: InitSupplyContactsOpeningRequest | PlainMessage<InitSupplyContactsOpeningRequest> | undefined
  ): boolean {
    return proto3.util.equals(InitSupplyContactsOpeningRequest, a, b);
  }
}

/**
 * payment form
 *
 * @generated from message marketplace.demand.v1.InitSupplyContactsOpeningResponse
 */
export class InitSupplyContactsOpeningResponse extends Message<InitSupplyContactsOpeningResponse> {
  constructor(data?: PartialMessage<InitSupplyContactsOpeningResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.InitSupplyContactsOpeningResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): InitSupplyContactsOpeningResponse {
    return new InitSupplyContactsOpeningResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): InitSupplyContactsOpeningResponse {
    return new InitSupplyContactsOpeningResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): InitSupplyContactsOpeningResponse {
    return new InitSupplyContactsOpeningResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | InitSupplyContactsOpeningResponse
      | PlainMessage<InitSupplyContactsOpeningResponse>
      | undefined,
    b:
      | InitSupplyContactsOpeningResponse
      | PlainMessage<InitSupplyContactsOpeningResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(InitSupplyContactsOpeningResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.OpenSupplyContactsRequest
 */
export class OpenSupplyContactsRequest extends Message<OpenSupplyContactsRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID supply_id = 1;
   */
  supplyId?: UUID;

  constructor(data?: PartialMessage<OpenSupplyContactsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.OpenSupplyContactsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): OpenSupplyContactsRequest {
    return new OpenSupplyContactsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): OpenSupplyContactsRequest {
    return new OpenSupplyContactsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OpenSupplyContactsRequest {
    return new OpenSupplyContactsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: OpenSupplyContactsRequest | PlainMessage<OpenSupplyContactsRequest> | undefined,
    b: OpenSupplyContactsRequest | PlainMessage<OpenSupplyContactsRequest> | undefined
  ): boolean {
    return proto3.util.equals(OpenSupplyContactsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.OpenSupplyContactsResponse
 */
export class OpenSupplyContactsResponse extends Message<OpenSupplyContactsResponse> {
  constructor(data?: PartialMessage<OpenSupplyContactsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.OpenSupplyContactsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): OpenSupplyContactsResponse {
    return new OpenSupplyContactsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): OpenSupplyContactsResponse {
    return new OpenSupplyContactsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OpenSupplyContactsResponse {
    return new OpenSupplyContactsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: OpenSupplyContactsResponse | PlainMessage<OpenSupplyContactsResponse> | undefined,
    b: OpenSupplyContactsResponse | PlainMessage<OpenSupplyContactsResponse> | undefined
  ): boolean {
    return proto3.util.equals(OpenSupplyContactsResponse, a, b);
  }
}
