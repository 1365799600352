import Joi from 'joi';
import { Traits } from '@proto/profiler/user/v1/profile_pb';

export enum FieldNames {
  RATE = 'rate',
  CAPACITY = 'capacity',
  EXPIRIENCE = 'experience',
  INTERVIEWER = 'interviewer',
  CREATE_INTERVIEW = 'createInterview',
  ONLY_SQUADERS = 'onlySquaders',
}

export const FIELD_LABELS = {
  [FieldNames.RATE]: 'Rate',
  [FieldNames.CAPACITY]: 'Capacity',
  [FieldNames.INTERVIEWER]: 'Interviewer',
  [FieldNames.EXPIRIENCE]: 'Experience',
  [FieldNames.CREATE_INTERVIEW]: 'Create interview',
  [FieldNames.ONLY_SQUADERS]: 'Only for squad members',
};

export const getDefaultValues = (data: Traits | undefined) => ({
  traits: {
    [FieldNames.RATE]: Number(data?.rate) || 0,
    [FieldNames.CAPACITY]: data?.capacity || 0,
    [FieldNames.EXPIRIENCE]: data?.experience || 0,
    [FieldNames.INTERVIEWER]: data?.interviewer || false,
  },
});

export const myProfileTraitsFormSchema = {
  traits: Joi.object({
    [FieldNames.RATE]: Joi.number(),
    [FieldNames.CAPACITY]: Joi.number(),
    [FieldNames.EXPIRIENCE]: Joi.number(),
    [FieldNames.INTERVIEWER]: Joi.boolean(),
  }),
};
