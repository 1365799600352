import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const FeedOwner = ({ feedOwner }: { feedOwner: any }) => {
  return (
    <Box>
      {feedOwner?.ownerType === 'org' && (
        <Box display="flex" gap={0.5}>
          <Typography fontWeight="bold">Owner:</Typography>
          <Link className="NavLink" to={`orgs/${feedOwner?.ownerId?.value}`}>
            <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>
              {feedOwner?.ownerName}
            </Typography>
          </Link>
        </Box>
      )}

      {feedOwner?.ownerType === 'profile' && (
        <Box display="flex" gap={0.5}>
          <Typography fontWeight="bold">Owner:</Typography>
          <Link className="NavLink" to={`/user/${feedOwner?.ownerId?.value}`}>
            <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>
              @{feedOwner?.ownerNickname}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default FeedOwner;
