import React, { Suspense } from 'react';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { useDialog } from '@components/hooks/useDialog';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProduct } from '@services/queries/ProductsQueries';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import CreateBreakDownDialog from '@pages/Product/Steps/CreateBreakDownDialog';
import BreakdownDnDCard from '@pages/Product/BreakDowns/BreakdownDnDCard';
import { BreakDown_Role, Step_Complexity } from '@proto/marketplace/product/v1/break_down_pb';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export function sortBreakDownRolesBySteps(roles: BreakDown_Role[]): BreakDown_Role[] {
  return roles.sort((a, b) => b.steps.length - a.steps.length);
}

export const complexityLabels = {
  [Step_Complexity.LOW]: 'Low',
  [Step_Complexity.MEDIUM]: 'Medium',
  [Step_Complexity.HIGH]: 'High',
};

export const complexityColors = {
  [Step_Complexity.LOW]: '!bg-[#81c784]',
  [Step_Complexity.MEDIUM]: '!bg-[#fff176]',
  [Step_Complexity.HIGH]: '!bg-[#e57373]',
};

const DraggableList = () => {
  const navigate = useNavigate();

  const { productId } = useParams();
  const { data: product, error } = useGetProduct(productId as string);
  const {
    isOpen: isOpenCreateBreakdown,
    handleOpen: handleOpenCreateBreakdown,
    handleClose: handleCloseCreateBreakdown,
  } = useDialog();
  if (error?.message?.includes('NotFound') || error?.message?.includes('InvalidArgument')) {
    navigate('/404');
  }
  return (
    <Page
      description={`Steps ${product?.product?.title}`}
      title={`Steps ${product?.product?.title}`}
    >
      <Container>
        <Box className="flex mb-2 justify-center items-center">
          <AddCircleIcon
            onClick={handleOpenCreateBreakdown}
            className="hover:text-[#1c54b2] !transition !duration-300 cursor-pointer"
          />
        </Box>

        {product && (
          <Box
            className="flex gap-2"
            sx={{
              height: 'calc(100vh - 195px)',
              overflowX: 'auto',
              overflowY: 'auto',
            }}
          >
            {product?.product?.breakDown &&
              sortBreakDownRolesBySteps(product?.product?.breakDown?.roles)?.map((role, index) => (
                <BreakdownDnDCard breakdown={role} />
              ))}
          </Box>
        )}
      </Container>

      <Suspense>
        <CreateBreakDownDialog
          handleClose={handleCloseCreateBreakdown}
          isOpen={isOpenCreateBreakdown}
          productId={new UUID({ value: productId })}
        />
      </Suspense>
    </Page>
  );
};

export default DraggableList;
