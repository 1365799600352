// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/marketplace/v1/order.proto (package api.marketplace.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetProductOrderRequest } from './order_pb.js';
import {
  CancelProductOrderRequest,
  CancelProductOrderResponse,
  CreateProductOrderOfferRequest_Payload,
  CreateProductOrderOfferResponse,
  CreateProductOrderRequest,
  CreateProductOrderResponse,
  GetProductOrderResponse,
  ListProductOrdersRequest,
  ListProductOrdersResponse,
  UpdateProductOrderOfferDraftRequest,
  UpdateProductOrderOfferDraftResponse,
} from '../../../marketplace/product/v1/order_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.marketplace.v1.ProductOrderAPI
 */
export const ProductOrderAPI = {
  typeName: 'api.marketplace.v1.ProductOrderAPI',
  methods: {
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.GetProductOrder
     */
    getProductOrder: {
      name: 'GetProductOrder',
      I: GetProductOrderRequest,
      O: GetProductOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.ListProductOrders
     */
    listProductOrders: {
      name: 'ListProductOrders',
      I: ListProductOrdersRequest,
      O: ListProductOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.CreateProductOrder
     */
    createProductOrder: {
      name: 'CreateProductOrder',
      I: CreateProductOrderRequest,
      O: CreateProductOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.CancelProductOrder
     */
    cancelProductOrder: {
      name: 'CancelProductOrder',
      I: CancelProductOrderRequest,
      O: CancelProductOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.CreateProductOrderOffer
     */
    createProductOrderOffer: {
      name: 'CreateProductOrderOffer',
      I: CreateProductOrderOfferRequest_Payload,
      O: CreateProductOrderOfferResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.marketplace.v1.ProductOrderAPI.UpdateProductOrderOfferDraft
     */
    updateProductOrderOfferDraft: {
      name: 'UpdateProductOrderOfferDraft',
      I: UpdateProductOrderOfferDraftRequest,
      O: UpdateProductOrderOfferDraftResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
