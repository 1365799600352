// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/user/v1/profile.proto (package profiler.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Tags } from '../../tags/v1/tags_pb.js';
import { PinnedItem } from './pin_pb.js';
import { IpV4 } from '../../../grpc/type/v1/ip_pb.js';
import { Entities, Entity } from '../../../grpc/type/v1/entity_pb.js';
import { Progress } from '../../../rewarder/achievements/v1/achievements_pb.js';

/**
 * @generated from message profiler.user.v1.Profile
 */
export class Profile extends Message<Profile> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 3;
   */
  birthDate?: Timestamp;

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 4;
   */
  name?: ProfileName;

  /**
   * @generated from field: profiler.user.v1.Traits traits = 5;
   */
  traits?: Traits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 6;
   */
  tags?: Tags;

  /**
   * @generated from field: string avatar = 7;
   */
  avatar = '';

  /**
   * @generated from field: repeated profiler.user.v1.PinnedItem pinned_items = 8;
   */
  pinnedItems: PinnedItem[] = [];

  constructor(data?: PartialMessage<Profile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.Profile';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'birth_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'name', kind: 'message', T: ProfileName },
    { no: 5, name: 'traits', kind: 'message', T: Traits },
    { no: 6, name: 'tags', kind: 'message', T: Tags },
    { no: 7, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'pinned_items', kind: 'message', T: PinnedItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profile {
    return new Profile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJsonString(jsonString, options);
  }

  static equals(
    a: Profile | PlainMessage<Profile> | undefined,
    b: Profile | PlainMessage<Profile> | undefined
  ): boolean {
    return proto3.util.equals(Profile, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.Profiles
 */
export class Profiles extends Message<Profiles> {
  /**
   * @generated from field: repeated profiler.user.v1.Profile profiles = 1;
   */
  profiles: Profile[] = [];

  constructor(data?: PartialMessage<Profiles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.Profiles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profiles {
    return new Profiles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profiles {
    return new Profiles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profiles {
    return new Profiles().fromJsonString(jsonString, options);
  }

  static equals(
    a: Profiles | PlainMessage<Profiles> | undefined,
    b: Profiles | PlainMessage<Profiles> | undefined
  ): boolean {
    return proto3.util.equals(Profiles, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetProfileRequest
 */
export class GetProfileRequest extends Message<GetProfileRequest> {
  /**
   * @generated from oneof profiler.user.v1.GetProfileRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID profile_id = 1;
         */
        value: UUID;
        case: 'profileId';
      }
    | {
        /**
         * @generated from field: string nick_name = 2;
         */
        value: string;
        case: 'nickName';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetProfileRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 2, name: 'nick_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'by' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined,
    b: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProfileRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetProfileResponse
 */
export class GetProfileResponse extends Message<GetProfileResponse> {
  /**
   * @generated from field: profiler.user.v1.Profile profile = 1;
   */
  profile?: Profile;

  constructor(data?: PartialMessage<GetProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetProfileResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProfileResponse {
    return new GetProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined,
    b: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetProfileResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetMyProfileRequest
 */
export class GetMyProfileRequest extends Message<GetMyProfileRequest> {
  constructor(data?: PartialMessage<GetMyProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetMyProfileRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMyProfileRequest {
    return new GetMyProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMyProfileRequest {
    return new GetMyProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetMyProfileRequest {
    return new GetMyProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetMyProfileRequest | PlainMessage<GetMyProfileRequest> | undefined,
    b: GetMyProfileRequest | PlainMessage<GetMyProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetMyProfileRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetMyProfileResponse
 */
export class GetMyProfileResponse extends Message<GetMyProfileResponse> {
  /**
   * @generated from field: profiler.user.v1.Profile profile = 1;
   */
  profile?: Profile;

  constructor(data?: PartialMessage<GetMyProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetMyProfileResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMyProfileResponse {
    return new GetMyProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMyProfileResponse {
    return new GetMyProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetMyProfileResponse {
    return new GetMyProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetMyProfileResponse | PlainMessage<GetMyProfileResponse> | undefined,
    b: GetMyProfileResponse | PlainMessage<GetMyProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetMyProfileResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ProfileName
 */
export class ProfileName extends Message<ProfileName> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = '';

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = '';

  /**
   * @generated from field: string nick_name = 3;
   */
  nickName = '';

  constructor(data?: PartialMessage<ProfileName>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ProfileName';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'first_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'last_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'nick_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileName {
    return new ProfileName().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileName {
    return new ProfileName().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileName {
    return new ProfileName().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProfileName | PlainMessage<ProfileName> | undefined,
    b: ProfileName | PlainMessage<ProfileName> | undefined
  ): boolean {
    return proto3.util.equals(ProfileName, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.CreateProfileRequest
 */
export class CreateProfileRequest extends Message<CreateProfileRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: optional profiler.user.v1.ProfileName name = 2;
   */
  name?: ProfileName;

  /**
   * @generated from field: string country = 3;
   */
  country = '';

  /**
   * @generated from field: grpc.type.v1.IpV4 ip = 4;
   */
  ip?: IpV4;

  /**
   * @generated from field: optional string invite_code = 5;
   */
  inviteCode?: string;

  constructor(data?: PartialMessage<CreateProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.CreateProfileRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'name', kind: 'message', T: ProfileName, opt: true },
    { no: 3, name: 'country', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'ip', kind: 'message', T: IpV4 },
    { no: 5, name: 'invite_code', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProfileRequest {
    return new CreateProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProfileRequest {
    return new CreateProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileRequest {
    return new CreateProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProfileRequest | PlainMessage<CreateProfileRequest> | undefined,
    b: CreateProfileRequest | PlainMessage<CreateProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.CreateProfileResponse
 */
export class CreateProfileResponse extends Message<CreateProfileResponse> {
  /**
   * @generated from field: optional grpc.type.v1.Entity invite_target = 1;
   */
  inviteTarget?: Entity;

  constructor(data?: PartialMessage<CreateProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.CreateProfileResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'invite_target', kind: 'message', T: Entity, opt: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProfileResponse {
    return new CreateProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProfileResponse {
    return new CreateProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileResponse {
    return new CreateProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProfileResponse | PlainMessage<CreateProfileResponse> | undefined,
    b: CreateProfileResponse | PlainMessage<CreateProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ChangeProfileStatusRequest
 */
export class ChangeProfileStatusRequest extends Message<ChangeProfileStatusRequest> {
  /**
   * @generated from field: bool active = 1;
   */
  active = false;

  constructor(data?: PartialMessage<ChangeProfileStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ChangeProfileStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ChangeProfileStatusRequest {
    return new ChangeProfileStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusRequest {
    return new ChangeProfileStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusRequest {
    return new ChangeProfileStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChangeProfileStatusRequest | PlainMessage<ChangeProfileStatusRequest> | undefined,
    b: ChangeProfileStatusRequest | PlainMessage<ChangeProfileStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(ChangeProfileStatusRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ChangeProfileStatusResponse
 */
export class ChangeProfileStatusResponse extends Message<ChangeProfileStatusResponse> {
  /**
   * @generated from field: bool active = 1;
   */
  active = false;

  constructor(data?: PartialMessage<ChangeProfileStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ChangeProfileStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ChangeProfileStatusResponse {
    return new ChangeProfileStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusResponse {
    return new ChangeProfileStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusResponse {
    return new ChangeProfileStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChangeProfileStatusResponse | PlainMessage<ChangeProfileStatusResponse> | undefined,
    b: ChangeProfileStatusResponse | PlainMessage<ChangeProfileStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(ChangeProfileStatusResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListProfilesRequest
 */
export class ListProfilesRequest extends Message<ListProfilesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profiles = 1;
   */
  profiles?: UUIDS;

  constructor(data?: PartialMessage<ListProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListProfilesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProfilesRequest {
    return new ListProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProfilesRequest {
    return new ListProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesRequest {
    return new ListProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfilesRequest | PlainMessage<ListProfilesRequest> | undefined,
    b: ListProfilesRequest | PlainMessage<ListProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListProfilesResponse
 */
export class ListProfilesResponse extends Message<ListProfilesResponse> {
  /**
   * @generated from field: profiler.user.v1.Profiles profiles = 1;
   */
  profiles?: Profiles;

  constructor(data?: PartialMessage<ListProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListProfilesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profiles },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProfilesResponse {
    return new ListProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProfilesResponse {
    return new ListProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesResponse {
    return new ListProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfilesResponse | PlainMessage<ListProfilesResponse> | undefined,
    b: ListProfilesResponse | PlainMessage<ListProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.AnonymusProfile
 */
export class AnonymusProfile extends Message<AnonymusProfile> {
  /**
   * @generated from field: string hidden_name = 1;
   */
  hiddenName = '';

  /**
   * @generated from field: profiler.user.v1.Traits traits = 2;
   */
  traits?: Traits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 3;
   */
  tags?: Tags;

  /**
   * @generated from field: rewarder.achievements.v1.Progress achievements = 4;
   */
  achievements?: Progress;

  constructor(data?: PartialMessage<AnonymusProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.AnonymusProfile';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'hidden_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'traits', kind: 'message', T: Traits },
    { no: 3, name: 'tags', kind: 'message', T: Tags },
    { no: 4, name: 'achievements', kind: 'message', T: Progress },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnonymusProfile {
    return new AnonymusProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnonymusProfile {
    return new AnonymusProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnonymusProfile {
    return new AnonymusProfile().fromJsonString(jsonString, options);
  }

  static equals(
    a: AnonymusProfile | PlainMessage<AnonymusProfile> | undefined,
    b: AnonymusProfile | PlainMessage<AnonymusProfile> | undefined
  ): boolean {
    return proto3.util.equals(AnonymusProfile, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.AnonymusProfiles
 */
export class AnonymusProfiles extends Message<AnonymusProfiles> {
  /**
   * @generated from field: repeated profiler.user.v1.AnonymusProfile profiles = 1;
   */
  profiles: AnonymusProfile[] = [];

  constructor(data?: PartialMessage<AnonymusProfiles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.AnonymusProfiles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: AnonymusProfile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnonymusProfiles {
    return new AnonymusProfiles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnonymusProfiles {
    return new AnonymusProfiles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnonymusProfiles {
    return new AnonymusProfiles().fromJsonString(jsonString, options);
  }

  static equals(
    a: AnonymusProfiles | PlainMessage<AnonymusProfiles> | undefined,
    b: AnonymusProfiles | PlainMessage<AnonymusProfiles> | undefined
  ): boolean {
    return proto3.util.equals(AnonymusProfiles, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListAnonymusProfilesRequest
 */
export class ListAnonymusProfilesRequest extends Message<ListAnonymusProfilesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 1;
   */
  profileIds?: UUIDS;

  constructor(data?: PartialMessage<ListAnonymusProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListAnonymusProfilesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListAnonymusProfilesRequest {
    return new ListAnonymusProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListAnonymusProfilesRequest {
    return new ListAnonymusProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAnonymusProfilesRequest {
    return new ListAnonymusProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAnonymusProfilesRequest | PlainMessage<ListAnonymusProfilesRequest> | undefined,
    b: ListAnonymusProfilesRequest | PlainMessage<ListAnonymusProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListAnonymusProfilesRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListAnonymusProfilesResponse
 */
export class ListAnonymusProfilesResponse extends Message<ListAnonymusProfilesResponse> {
  /**
   * @generated from field: profiler.user.v1.AnonymusProfiles profiles = 1;
   */
  profiles?: AnonymusProfiles;

  constructor(data?: PartialMessage<ListAnonymusProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListAnonymusProfilesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: AnonymusProfiles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListAnonymusProfilesResponse {
    return new ListAnonymusProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListAnonymusProfilesResponse {
    return new ListAnonymusProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAnonymusProfilesResponse {
    return new ListAnonymusProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAnonymusProfilesResponse | PlainMessage<ListAnonymusProfilesResponse> | undefined,
    b: ListAnonymusProfilesResponse | PlainMessage<ListAnonymusProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListAnonymusProfilesResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfilePersonalInfoRequest
 */
export class UpdateProfilePersonalInfoRequest extends Message<UpdateProfilePersonalInfoRequest> {
  /**
   * @generated from field: profiler.user.v1.ProfileName names = 1;
   */
  names?: ProfileName;

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 2;
   */
  birthDate?: Timestamp;

  constructor(data?: PartialMessage<UpdateProfilePersonalInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfilePersonalInfoRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'names', kind: 'message', T: ProfileName },
    { no: 2, name: 'birth_date', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfilePersonalInfoRequest {
    return new UpdateProfilePersonalInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoRequest {
    return new UpdateProfilePersonalInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoRequest {
    return new UpdateProfilePersonalInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateProfilePersonalInfoRequest
      | PlainMessage<UpdateProfilePersonalInfoRequest>
      | undefined,
    b: UpdateProfilePersonalInfoRequest | PlainMessage<UpdateProfilePersonalInfoRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfilePersonalInfoRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfilePersonalInfoResponse
 */
export class UpdateProfilePersonalInfoResponse extends Message<UpdateProfilePersonalInfoResponse> {
  /**
   * @generated from field: profiler.user.v1.ProfileName names = 1;
   */
  names?: ProfileName;

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 2;
   */
  birthDate?: Timestamp;

  constructor(data?: PartialMessage<UpdateProfilePersonalInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfilePersonalInfoResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'names', kind: 'message', T: ProfileName },
    { no: 2, name: 'birth_date', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfilePersonalInfoResponse {
    return new UpdateProfilePersonalInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoResponse {
    return new UpdateProfilePersonalInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoResponse {
    return new UpdateProfilePersonalInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateProfilePersonalInfoResponse
      | PlainMessage<UpdateProfilePersonalInfoResponse>
      | undefined,
    b:
      | UpdateProfilePersonalInfoResponse
      | PlainMessage<UpdateProfilePersonalInfoResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfilePersonalInfoResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListInterviewersRequest
 */
export class ListInterviewersRequest extends Message<ListInterviewersRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profiles = 1;
   */
  profiles?: UUIDS;

  constructor(data?: PartialMessage<ListInterviewersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListInterviewersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInterviewersRequest {
    return new ListInterviewersRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersRequest {
    return new ListInterviewersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersRequest {
    return new ListInterviewersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInterviewersRequest | PlainMessage<ListInterviewersRequest> | undefined,
    b: ListInterviewersRequest | PlainMessage<ListInterviewersRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListInterviewersRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListInterviewersResponse
 */
export class ListInterviewersResponse extends Message<ListInterviewersResponse> {
  /**
   * @generated from field: profiler.user.v1.Profiles profiles = 1;
   */
  profiles?: Profiles;

  constructor(data?: PartialMessage<ListInterviewersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListInterviewersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profiles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInterviewersResponse {
    return new ListInterviewersResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersResponse {
    return new ListInterviewersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersResponse {
    return new ListInterviewersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInterviewersResponse | PlainMessage<ListInterviewersResponse> | undefined,
    b: ListInterviewersResponse | PlainMessage<ListInterviewersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListInterviewersResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SearchSupplyProfilesFilter
 */
export class SearchSupplyProfilesFilter extends Message<SearchSupplyProfilesFilter> {
  /**
   * @generated from field: profiler.user.v1.Traits traits = 1;
   */
  traits?: Traits;

  /**
   * @generated from field: repeated int32 tag_ids = 2;
   */
  tagIds: number[] = [];

  constructor(data?: PartialMessage<SearchSupplyProfilesFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SearchSupplyProfilesFilter';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: Traits },
    { no: 2, name: 'tag_ids', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchSupplyProfilesFilter {
    return new SearchSupplyProfilesFilter().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesFilter {
    return new SearchSupplyProfilesFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesFilter {
    return new SearchSupplyProfilesFilter().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchSupplyProfilesFilter | PlainMessage<SearchSupplyProfilesFilter> | undefined,
    b: SearchSupplyProfilesFilter | PlainMessage<SearchSupplyProfilesFilter> | undefined
  ): boolean {
    return proto3.util.equals(SearchSupplyProfilesFilter, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SupplyProfile
 */
export class SupplyProfile extends Message<SupplyProfile> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: profiler.user.v1.Traits traits = 2;
   */
  traits?: Traits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 3;
   */
  tags?: Tags;

  /**
   * @generated from field: grpc.type.v1.Entities membership = 4;
   */
  membership?: Entities;

  /**
   * @generated from field: string avatar = 5;
   */
  avatar = '';

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 6;
   */
  name?: ProfileName;

  constructor(data?: PartialMessage<SupplyProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SupplyProfile';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'traits', kind: 'message', T: Traits },
    { no: 3, name: 'tags', kind: 'message', T: Tags },
    { no: 4, name: 'membership', kind: 'message', T: Entities },
    { no: 5, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'name', kind: 'message', T: ProfileName },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupplyProfile {
    return new SupplyProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupplyProfile {
    return new SupplyProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupplyProfile {
    return new SupplyProfile().fromJsonString(jsonString, options);
  }

  static equals(
    a: SupplyProfile | PlainMessage<SupplyProfile> | undefined,
    b: SupplyProfile | PlainMessage<SupplyProfile> | undefined
  ): boolean {
    return proto3.util.equals(SupplyProfile, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SupplyProfiles
 */
export class SupplyProfiles extends Message<SupplyProfiles> {
  /**
   * @generated from field: repeated profiler.user.v1.SupplyProfile profiles = 1;
   */
  profiles: SupplyProfile[] = [];

  constructor(data?: PartialMessage<SupplyProfiles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SupplyProfiles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: SupplyProfile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupplyProfiles {
    return new SupplyProfiles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupplyProfiles {
    return new SupplyProfiles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupplyProfiles {
    return new SupplyProfiles().fromJsonString(jsonString, options);
  }

  static equals(
    a: SupplyProfiles | PlainMessage<SupplyProfiles> | undefined,
    b: SupplyProfiles | PlainMessage<SupplyProfiles> | undefined
  ): boolean {
    return proto3.util.equals(SupplyProfiles, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SearchSupplyProfilesRequest
 */
export class SearchSupplyProfilesRequest extends Message<SearchSupplyProfilesRequest> {
  /**
   * map<demand_id, filter>
   *
   * @generated from field: map<string, profiler.user.v1.SearchSupplyProfilesFilter> search = 1;
   */
  search: { [key: string]: SearchSupplyProfilesFilter } = {};

  constructor(data?: PartialMessage<SearchSupplyProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SearchSupplyProfilesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'search',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: SearchSupplyProfilesFilter },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchSupplyProfilesRequest {
    return new SearchSupplyProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesRequest {
    return new SearchSupplyProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesRequest {
    return new SearchSupplyProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchSupplyProfilesRequest | PlainMessage<SearchSupplyProfilesRequest> | undefined,
    b: SearchSupplyProfilesRequest | PlainMessage<SearchSupplyProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(SearchSupplyProfilesRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SearchSupplyProfilesResponse
 */
export class SearchSupplyProfilesResponse extends Message<SearchSupplyProfilesResponse> {
  /**
   * map<demand_id, profiles>
   *
   * @generated from field: map<string, profiler.user.v1.SupplyProfiles> profiles = 1;
   */
  profiles: { [key: string]: SupplyProfiles } = {};

  constructor(data?: PartialMessage<SearchSupplyProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SearchSupplyProfilesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'profiles',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: SupplyProfiles },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchSupplyProfilesResponse {
    return new SearchSupplyProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesResponse {
    return new SearchSupplyProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesResponse {
    return new SearchSupplyProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchSupplyProfilesResponse | PlainMessage<SearchSupplyProfilesResponse> | undefined,
    b: SearchSupplyProfilesResponse | PlainMessage<SearchSupplyProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(SearchSupplyProfilesResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.Traits
 */
export class Traits extends Message<Traits> {
  /**
   * $ per hour (microdollars: 1/1_000_000)
   *
   * @generated from field: int64 rate = 1;
   */
  rate = protoInt64.zero;

  /**
   * hours per week
   *
   * @generated from field: int32 capacity = 2;
   */
  capacity = 0;

  /**
   * is profile an interviewer
   *
   * @generated from field: bool interviewer = 3;
   */
  interviewer = false;

  /**
   * is profile a member of squad
   *
   * @generated from field: bool squader = 4;
   */
  squader = false;

  /**
   * years of experience
   *
   * @generated from field: int32 experience = 5;
   */
  experience = 0;

  /**
   * timezone (IANA)
   *
   * @generated from field: string tz = 6;
   */
  tz = '';

  constructor(data?: PartialMessage<Traits>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.Traits';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rate', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'capacity', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'interviewer', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: 'squader', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'experience', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'tz', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Traits {
    return new Traits().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Traits {
    return new Traits().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Traits {
    return new Traits().fromJsonString(jsonString, options);
  }

  static equals(
    a: Traits | PlainMessage<Traits> | undefined,
    b: Traits | PlainMessage<Traits> | undefined
  ): boolean {
    return proto3.util.equals(Traits, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfileTraitsRequest
 */
export class UpdateProfileTraitsRequest extends Message<UpdateProfileTraitsRequest> {
  /**
   * @generated from field: profiler.user.v1.Traits traits = 1;
   */
  traits?: Traits;

  constructor(data?: PartialMessage<UpdateProfileTraitsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfileTraitsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: Traits },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileTraitsRequest {
    return new UpdateProfileTraitsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsRequest {
    return new UpdateProfileTraitsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsRequest {
    return new UpdateProfileTraitsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProfileTraitsRequest | PlainMessage<UpdateProfileTraitsRequest> | undefined,
    b: UpdateProfileTraitsRequest | PlainMessage<UpdateProfileTraitsRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileTraitsRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfileTraitsResponse
 */
export class UpdateProfileTraitsResponse extends Message<UpdateProfileTraitsResponse> {
  /**
   * @generated from field: profiler.user.v1.Traits traits = 1;
   */
  traits?: Traits;

  constructor(data?: PartialMessage<UpdateProfileTraitsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfileTraitsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: Traits },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileTraitsResponse {
    return new UpdateProfileTraitsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsResponse {
    return new UpdateProfileTraitsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsResponse {
    return new UpdateProfileTraitsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProfileTraitsResponse | PlainMessage<UpdateProfileTraitsResponse> | undefined,
    b: UpdateProfileTraitsResponse | PlainMessage<UpdateProfileTraitsResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileTraitsResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetTermsOfUseRequest
 */
export class GetTermsOfUseRequest extends Message<GetTermsOfUseRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  constructor(data?: PartialMessage<GetTermsOfUseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetTermsOfUseRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTermsOfUseRequest | PlainMessage<GetTermsOfUseRequest> | undefined,
    b: GetTermsOfUseRequest | PlainMessage<GetTermsOfUseRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetTermsOfUseRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetTermsOfUseResponse
 */
export class GetTermsOfUseResponse extends Message<GetTermsOfUseResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp accepted = 2;
   */
  accepted?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.IpV4 ip = 3;
   */
  ip?: IpV4;

  constructor(data?: PartialMessage<GetTermsOfUseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetTermsOfUseResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'accepted', kind: 'message', T: Timestamp },
    { no: 3, name: 'ip', kind: 'message', T: IpV4 },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetTermsOfUseResponse {
    return new GetTermsOfUseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTermsOfUseResponse {
    return new GetTermsOfUseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTermsOfUseResponse {
    return new GetTermsOfUseResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTermsOfUseResponse | PlainMessage<GetTermsOfUseResponse> | undefined,
    b: GetTermsOfUseResponse | PlainMessage<GetTermsOfUseResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetTermsOfUseResponse, a, b);
  }
}
