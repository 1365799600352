// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/profiler/v1/profile.proto (package api.profiler.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  GetMyProfileRequest,
  GetMyProfileResponse,
  GetProfileRequest,
  GetProfileResponse,
  GetTermsOfUseResponse,
  ListInterviewersRequest,
  ListInterviewersResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  SearchSupplyProfilesRequest,
  SearchSupplyProfilesResponse,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
  UpdateProfileTraitsRequest,
  UpdateProfileTraitsResponse,
} from '../../../profiler/user/v1/profile_pb.js';
import { MethodKind } from '@bufbuild/protobuf';
import { GetTermsOfUseRequest } from './profile_pb.js';

/**
 * @generated from service api.profiler.v1.ProfileAPI
 */
export const ProfileAPI = {
  typeName: 'api.profiler.v1.ProfileAPI',
  methods: {
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.GetProfile
     */
    getProfile: {
      name: 'GetProfile',
      I: GetProfileRequest,
      O: GetProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.GetMyProfile
     */
    getMyProfile: {
      name: 'GetMyProfile',
      I: GetMyProfileRequest,
      O: GetMyProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.ChangeProfileStatus
     */
    changeProfileStatus: {
      name: 'ChangeProfileStatus',
      I: ChangeProfileStatusRequest,
      O: ChangeProfileStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.UpdateProfilePersonalInfo
     */
    updateProfilePersonalInfo: {
      name: 'UpdateProfilePersonalInfo',
      I: UpdateProfilePersonalInfoRequest,
      O: UpdateProfilePersonalInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.ListProfiles
     */
    listProfiles: {
      name: 'ListProfiles',
      I: ListProfilesRequest,
      O: ListProfilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.ListInterviewers
     */
    listInterviewers: {
      name: 'ListInterviewers',
      I: ListInterviewersRequest,
      O: ListInterviewersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.SearchSupplyProfiles
     */
    searchSupplyProfiles: {
      name: 'SearchSupplyProfiles',
      I: SearchSupplyProfilesRequest,
      O: SearchSupplyProfilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.UpdateProfileTraits
     */
    updateProfileTraits: {
      name: 'UpdateProfileTraits',
      I: UpdateProfileTraitsRequest,
      O: UpdateProfileTraitsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.profiler.v1.ProfileAPI.GetTermsOfUse
     */
    getTermsOfUse: {
      name: 'GetTermsOfUse',
      I: GetTermsOfUseRequest,
      O: GetTermsOfUseResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
