import { useListProductOrders } from '@services/queries/StepsQueries';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import {
  ListProductOrdersRequest,
  ProductOrderStatus,
} from '@proto/marketplace/product/v1/order_pb';
import Box from '@mui/material/Box';
import Card from '@components/Card/Card';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { getLink } from '@components/NotificationPopover/NotificationCard';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';

export const orderStatusLabels = {
  [ProductOrderStatus.PENDING]: 'Pending',
  [ProductOrderStatus.OFFER_DRAFT]: 'Offer draft',
  [ProductOrderStatus.OFFER_SENT]: 'Offer sent',
  [ProductOrderStatus.COMPLETED]: 'Completed',
  [ProductOrderStatus.CANCELLED]: 'Cancelled',
  [ProductOrderStatus.IN_PROGRESS]: 'In progress',
};

export const orderStatusColors = {
  [ProductOrderStatus.PENDING]: '#fff176',
  [ProductOrderStatus.OFFER_DRAFT]: '#ffab91',
  [ProductOrderStatus.OFFER_SENT]: '#64b5f6',
  [ProductOrderStatus.IN_PROGRESS]: '#64b5f6',
  [ProductOrderStatus.COMPLETED]: '#81c784',
  [ProductOrderStatus.CANCELLED]: '#e57373',
};

const ListOrders = ({ customerId }: { customerId: UUID }) => {
  const navigate = useNavigate();
  const { data: listOrders } = useListProductOrders({
    enabled: !!customerId,
    payload: new ListProductOrdersRequest({
      by: {
        case: 'customerId',
        value: customerId,
      },
    }),
  });

  return (
    <div>
      <Typography fontWeight="bold">Orders</Typography>

      <Box className="flex flex-col gap-2">
        {listOrders?.orders?.map((order) => (
          <Box
            className="cursor-pointer"
            onClick={() =>
              navigate(
                getLink(
                  new Entity({
                    id: order.orderId,
                    type: Entity_Type.ORDER,
                  })
                )
              )
            }
          >
            <Card key={order.orderId?.value}>
              <Box className="cursor-pointer">
                <Box className="flex justify-between cursor-pointer">
                  <Typography className="font-bold">{order.productTitle}</Typography>
                  <Box>{new Date(order?.created?.toDate() || '').toLocaleDateString()}</Box>
                </Box>
                {/* Використовуємо inline стиль для зміни кольору */}
                <Box
                  style={{
                    color: orderStatusColors[order.status as keyof typeof orderStatusColors],
                  }}
                >
                  {orderStatusLabels[order.status as keyof typeof orderStatusLabels]}
                </Box>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default ListOrders;
