import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { Event_Bell } from '@proto/notificator/stream/v1/events_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';
import { useDeleteEvent, useMarkAsRead } from '@services/queries/Chat';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { useStreamStore } from '../../zustand/subscribe';
import { useNotificationsState } from '../../zustand/notifications';

const getTimeAgo = (timestamp: Timestamp): string => {
  const now = new Date();
  const date = timestamp.toDate();
  const diffMs = now.getTime() - date.getTime();

  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
};

// Використовуємо Partial<Record<...>> для визначення лише потрібних ключів
export const linkTypes: Partial<Record<Entity_Type, string>> = {
  [Entity_Type.SQUAD]: 'squads',
  [Entity_Type.ORG]: 'orgs',
  [Entity_Type.INTERVIEW]: 'interview',
  [Entity_Type.ORDER]: 'orders',
  [Entity_Type.USER]: 'user',
};

const entityLabels: Partial<Record<Entity_Type, string>> = {
  [Entity_Type.SQUAD]: 'squad',
  [Entity_Type.ORG]: 'organization',
  [Entity_Type.INTERVIEW]: 'interview',
  [Entity_Type.ORDER]: 'order',
};

export const getLink = (entity: Entity): string =>
  `/${linkTypes[entity.type]!}/${entity?.id?.value}`;

interface NotificationCardProps {
  notification: Event_Bell;
  read?: Timestamp;
  showSettings?: boolean;
  showMarkAsRead?: boolean;
  closePopover?: () => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  read,
  showSettings = false,
  showMarkAsRead = false,
  closePopover,
}) => {
  const navigate = useNavigate();
  const { removeBellNotification } = useStreamStore();
  const { mutate: deleteEvent } = useDeleteEvent();
  const { mutate: markAsRead } = useMarkAsRead();
  const { selectedNotificationsIds, toggleSelectId } = useNotificationsState();
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const { payload, created } = notification;
  const { id, message, entity, subject } = payload!; // ми припускаємо, що payload завжди існує
  const notificationId = id?.value as string;
  const isRead = read ? isInvalidTimestamp(read.toJsonString()) : undefined;
  const isChecked =
    selectedNotificationsIds?.some((item) => item.value === notificationId) || false;
  const timeAgo = getTimeAgo(created as Timestamp);
  const link = getLink(entity as Entity);

  const handleCardClick = () => {
    if (!isRead) {
      markAsRead(new UUIDS({ values: [notificationId] }), {
        onSuccess: () => {
          removeBellNotification(notificationId);
          closePopover?.();
          navigate(link);
        },
        onError: () => {
          closePopover?.();
          navigate(link);
        },
      });
    } else {
      navigate(link);
    }
  };

  const handleInnerMarkAsRead = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isRead) {
      markAsRead(new UUIDS({ values: [notificationId] }));
    }
  };

  const handleMarkAsReadIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    markAsRead(new UUIDS({ values: [notificationId] }), {
      onSuccess: () => removeBellNotification(notificationId),
    });
  };

  const handleDeleteIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    deleteEvent(new UUIDS({ values: [notificationId] }), {
      onSuccess: () => removeBellNotification(notificationId),
    });
  };

  return (
    <>
      <Box
        key={subject}
        onClick={handleCardClick}
        className="group transition-all cursor-pointer hover:bg-white/10 rounded"
      >
        <Box className="flex justify-between min-h-[50px] items-center px-2">
          <Box className="flex items-center gap-1">
            {showSettings && (
              <Checkbox checked={isChecked} onChange={() => toggleSelectId(id as UUID)} />
            )}

            {showSettings && isRead !== undefined && (
              <Tooltip title={isRead ? 'Read' : 'Mark as read'} placement="right">
                <Box
                  onClick={handleInnerMarkAsRead}
                  className={`mr-2 w-7 h-7 flex items-center justify-center border rounded-full ${
                    isRead ? 'bg-white/20 border-white/80' : 'bg-transparent border-white/40'
                  }`}
                >
                  <Box
                    className={`w-2.5 h-2.5 rounded-full cursor-pointer border ${
                      isRead ? 'bg-white/80 border-white/80' : 'bg-transparent border-white/40'
                    }`}
                  />
                </Box>
              </Tooltip>
            )}
            <Box>
              <Typography fontWeight="bold">{subject}</Typography>
              {message && (
                <Typography>
                  {`${message} for an ${entityLabels[(entity as Entity).type]!}`}
                </Typography>
              )}
            </Box>

            {/* <Typography> */}
            {/*  {closePopover */}
            {/*    ? message || subject */}
            {/*    : `${message || subject} for an ${entityLabels[(entity as Entity).type]!}`} */}
            {/* </Typography> */}
          </Box>

          {showMarkAsRead && (
            <Box className="flex items-center gap-2">
              <Typography variant="caption">{timeAgo}</Typography>
              <Tooltip title={isRead ? 'Read' : 'Mark as read'} placement="right">
                <Box
                  onClick={handleMarkAsReadIconClick}
                  className="w-7 h-7 flex cursor-pointer items-center justify-center bg-white/20 hover:bg-white/40 transition-all border-white/80 border rounded-full"
                >
                  <Box className="w-2.5 h-2.5 rounded-full cursor-pointer bg-white/80 border-white/80 border" />
                </Box>
              </Tooltip>
            </Box>
          )}

          {showSettings && (
            <Box className="flex items-center gap-2 transition-all">
              <Box
                onClick={handleDeleteIconClick}
                className="cursor-pointer hidden group-hover:block"
              >
                <DeleteIcon className="hover:text-[#ab003c] transition-all" />
              </Box>
              <Typography variant="caption">{timeAgo}</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <ConfirmDialog
        isOpen={isConfirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleConfirm}
        title="Action confirmation"
        message="Are you sure you want to continue?"
      />
    </>
  );
};

export default NotificationCard;
