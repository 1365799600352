import { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';

import MDEditor, { commands, ICommand } from '@uiw/react-md-editor';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';

type TWysiwygControllerProps = Omit<TextFieldProps, 'name'> & {
  name: string;
};

const WysiwygController = (props: TWysiwygControllerProps) => {
  const { name } = props;
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });
  const h1Command: ICommand = {
    name: 'title',
    keyCommand: 'title',
    buttonProps: { 'aria-label': 'Insert Title (H1)' },
    icon: <span>T1</span>,
    execute: (state, api) => {
      const newText = `# ${state.selectedText || 'Title'}`;
      api.replaceSelection(newText);
    },
  };

  const h2Command: ICommand = {
    name: 'subtitle',
    keyCommand: 'subtitle',
    buttonProps: { 'aria-label': 'Insert Subtitle (H2)' },
    icon: <span>T2</span>,
    execute: (state, api) => {
      const newText = `## ${state.selectedText || 'Subtitle'}`;
      api.replaceSelection(newText);
    },
  };

  const h3Command: ICommand = {
    name: 'subheading',
    keyCommand: 'subheading',
    buttonProps: { 'aria-label': 'Insert Subheading (H3)' },
    icon: <span>T3</span>,
    execute: (state, api) => {
      const newText = `### ${state.selectedText || 'Subheading'}`;
      api.replaceSelection(newText);
    },
  };

  const customToolbar = [
    commands.bold,
    commands.italic,
    commands.divider,
    h1Command,
    h2Command,
    h3Command,
    commands.divider,
    commands.orderedListCommand,
    commands.unorderedListCommand,
  ];

  return (
    <Box>
      <MDEditor
        className={`editorCustomStyle ${error ? '!border-red-500' : ''}`}
        value={value}
        onChange={onChange}
        commands={customToolbar}
        extraCommands={[commands.codeEdit, commands.codePreview]}
        preview="edit"
      />
      {error && <Box className="px-[14px] text-[12px] text-red-500">{error.message}</Box>}
    </Box>
  );
};

export default WysiwygController;
