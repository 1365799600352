import styled from '@mui/material/styles/styled';
import Box, { BoxProps } from '@mui/material/Box';
import Container from '@mui/material/Container';
import { PropsWithChildren } from 'react';

export interface BackgroundBoxProps extends BoxProps {
  component?: React.ElementType;
}

export const BackgroundBox = styled(Box)<BackgroundBoxProps>(({ theme }) => ({
  zIndex: 0,
}));

export type LayoutContainerProps = {
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
};

export const LayoutContainer = ({
  children,
  tag = 'div',
  className,
}: PropsWithChildren<LayoutContainerProps>) => (
  <BackgroundBox component={tag} className={className}>
    <Container maxWidth="xl">{children}</Container>
  </BackgroundBox>
);
