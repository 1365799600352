import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import PinUnpinButton from '@components/PinUnpinButton/PinUnpinButton';
import { useListAppliedDemands, useListFeedItems } from '@services/queries/MarketplaceQueries';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import CheckIcon from '@mui/icons-material/Check';
import { extractInterviews } from '@pages/Marketplace/Feed/FeedByDemand/PreAppliedDemandCard';
import DemandCardHeader from '@pages/Marketplace/Feed/DemandCardHeader';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';

export const typesDemand = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

const DemandCard = React.memo(
  ({ demand }: { demand: Demand }) => {
    const { data } = useListFeedItems();
    const { setDemandId } = useMarketplace();
    const { data: appliedDemands } = useListAppliedDemands({ refetch: false });
    const demandId = getDemandId(demand);
    const roleTags = demand.entity.value?.tags?.tags.filter((tag) => tag.tagType !== TagType.ROLE);
    const matches =
      (demandId &&
        Object?.values(
          data?.recomendations?.recommendations[demandId?.value as string]?.recommendations || {}
        ).map((recommendation) => recommendation?.suppliers.map((supplier) => supplier))) ||
      [];

    const isDemandPresent = useMemo(() => {
      const interviews = extractInterviews(appliedDemands?.demands);
      return interviews?.some(
        (interview) => (interview.entity.value as Interview)?.interviewId?.value === demandId?.value
      );
    }, [appliedDemands, demandId]);

    const toggleDemand = () => {
      setDemandId({ demandId: demandId?.value || '', demandType: demand?.entity?.case || '' });
    };

    const handleCheckboxClick = (e: React.MouseEvent) => {
      e.stopPropagation();
    };
    return (
      <Box onClick={toggleDemand}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className="rounded-xl  p-4 flex flex-col gap-4   bg-white/5 hover:bg-white/10 transition"
        >
          <Box display="flex" className="md:items-center md:justify-between flex-col md:flex-row">
            <DemandCardHeader
              demand={demand}
              matches={matches?.length ? matches[0][0]?.traits : undefined}
              demandId={demandId?.value}
            />

            <Box className="flex items-center">
              {isDemandPresent ? (
                <Box className="flex items-center">
                  <CheckIcon color="primary" />
                  <Typography color="#7986cb">Applied</Typography>
                </Box>
              ) : (
                ''
              )}
              {demand?.entity?.case && demandId && (
                <Box className="hidden md:block" onClick={handleCheckboxClick}>
                  <PinUnpinButton type={typesDemand[demand?.entity?.case]} id={demandId?.value} />
                </Box>
              )}
            </Box>
          </Box>

          {roleTags && !!roleTags?.length && (
            <Tags
              matchedTags={matches.length && matches[0][0]?.matchedTags?.tags}
              showType
              tags={roleTags}
            />
          )}
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      (prevProps.demand.entity.value as Interview)?.interviewId?.value ===
      (nextProps.demand.entity.value as Interview)?.interviewId?.value
    );
  }
);

export default DemandCard;
