import { create } from 'zustand/react';

interface InvitesState {
  inviteCode: string | undefined;
  setInviteCode: (searchedMembers: string | undefined) => void;
}

export const useMembersState = create<InvitesState>((set, get) => ({
  inviteCode: undefined,
  setInviteCode: (inviteCode: string | undefined) => set({ inviteCode }),
}));
