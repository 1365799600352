import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Box } from '@mui/material';
import SelectDemandStatus from '@pages/Marketplace/Dashboards/SelectDemandStatus';
import UpdateInterviewRequirements from '@pages/Marketplace/Dashboards/UpdateInterviewRequirements';
import UpdateDemandTraits from '@pages/Marketplace/Dashboards/UpdateDemandTraits';
import Tags from '@components/Tags/Tags';
import { useAllocateSlots, useGetDemand } from '@services/queries/MarketplaceQueries';
import ManageEvaluationsList from '@pages/Marketplace/Dashboards/ManageEvaluationsList';
import Button from '@mui/material/Button';
import TabContainer, { TabItem } from '@components/TabContainer/TabContainer';
import ListInterviewers from '@pages/Marketplace/Dashboards/ListInterviewers';
import ListInvitedCandidates from '@pages/Marketplace/ManageDemands/Interviews/ListInvitedCandidates';
import ManageDemandHeader from '@pages/Marketplace/Dashboards/ManageDemandHeader';

const ManageInterviewPage = () => {
  const navigate = useNavigate();
  const { demandId, dashboardId } = useParams();
  const { data: demand, error } = useGetDemand({ demandId: demandId || '', type: 'interview' });
  const interview = demand?.entity?.value;
  const { mutate } = useAllocateSlots();

  if (error?.message?.includes('NotFound') || error?.message?.includes('InvalidArgument')) {
    navigate('/404');
  }

  const tabs: TabItem[] = [
    {
      label: 'Info',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          <Box display="flex" flexDirection="column" gap={1}>
            {demand && <UpdateDemandTraits demand={demand} />}
            {interview?.tags?.tags && <Tags tags={interview.tags.tags} />}
            {demand && <UpdateInterviewRequirements demand={demand} />}
            <Box display="flex">
              <SelectDemandStatus status={demand?.status?.status?.toString() || ''} />
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      label: 'Evaluations',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          {demand && <ManageEvaluationsList demand={demand} />}
          <Button onClick={() => mutate({ interviewId: demandId as string })}>Allocate</Button>
        </Box>
      ),
    },
    {
      label: 'Candidates',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          {demandId && <ListInvitedCandidates objectName="Interview" entityId={demandId} />}
        </Box>
      ),
    },
    {
      label: 'Interviewers',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          {demand && <ListInterviewers demand={demand} />}
        </Box>
      ),
    },
  ];

  return <TabContainer tabs={tabs} userPermissions />;
};

export default ManageInterviewPage;
