import { createServiceSlice } from '@store/helpers';
import { TUserInvite } from '@store/profiler/helpers';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { TListMembersRolesResponseUser, UserCaseReducer, UserState } from './profiler';

export const userSlice = createServiceSlice<UserState, UserCaseReducer>({
  name: 'profiler',
  initialState: {
    profile: undefined,
    pinnedItems: undefined,
    selectedProfile: undefined,
    currentProfile: undefined,
    userProfiles: {},
    searchProfiles: [],
    listMembers: {} as { string: { profiles: Profile[] } },
    invitesLists: {} as { string: any },
    listRoles: {} as { string: TListMembersRolesResponseUser[] },
    listUserInvites: {} as { string: TUserInvite[] },
    uploadAvatarUrl: undefined,
    userPermissions: undefined,
    userAvatars: undefined,
    tooltipsList: undefined,
    listMemberShips: undefined,
    showProfileNameSnackbar: false,
    showErrorProfileNameSnackbar: false,
    showTraitsSnackbar: false,
    showErrorTraitsSnackbar: false,
  },
  reducers: {
    setListMembersRoles: (state, action) => {
      state.listRoles = Object.assign(state.listRoles, action.payload);
    },
    setUserProfiles: (state, action) => {
      state.userProfiles = Object.assign(state.userProfiles, action.payload);
    },
    setPinnedItems: (state, action) => {
      const { pinnedItems } = action.payload;
      state.pinnedItems = pinnedItems;
    },
    setInvitesLists: (state, action) => {
      state.invitesLists = Object.assign(state.invitesLists, action.payload);
    },
    setListMembers: (state, action) => {
      const { profiles } = action.payload;

      state.listMembers = Object.assign(state.listMembers, profiles);
    },
    setListMemberShips: (state, action) => {
      const { memberships } = action.payload;

      state.listMemberShips = memberships;
    },
    setTooltipsList: (state, action) => {
      const { tooltips } = action.payload;

      state.tooltipsList = tooltips;
    },
    setUserAvatars: (state, action) => {
      const { avatars } = action.payload;

      state.userAvatars = avatars;
    },
    setSelectedProfile: (state, action) => {
      const { profile = undefined } = action.payload;

      state.selectedProfile = profile;
    },
    setCurrentProfile: (state, action) => {
      const { profile = undefined } = action.payload;

      state.currentProfile = profile;
    },
    deleteSelectedProfile: (state) => {
      state.selectedProfile = undefined;
    },

    setProfile: (state, action) => {
      const { profile = undefined } = action.payload;

      state.profile = profile;
    },
    deleteProfile: (state) => {
      state.profile = undefined;
    },

    setUserIvitesList: (state, action) => {
      const { invites } = action.payload;

      state.listUserInvites = invites;
    },
    setProfileName: (state, action) => {
      const { names } = action.payload;

      Object.assign(state.profile, { name: names });
    },

    setProfileStatus: (state, action) => {
      const { active } = action.payload;

      Object.assign(state.profile, { active });
    },

    setProfileTraits: (state, action) => {
      const { traits } = action.payload;

      Object.assign(state.profile, { traits });
    },
    setFoundProfiles: (state, action) => {
      // @ts-ignore
      state.searchProfiles = action.payload;
    },
    setUploadAvatarUrl: (state, action) => {
      const { url } = action.payload;

      state.uploadAvatarUrl = url;
    },
  },
});
