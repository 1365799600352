import Box from '@mui/material/Box';
import React from 'react';
import FeedProfileTraitsForm from '@pages/Marketplace/Feed/FeedProfileSettings/FeedProfileTraitsForm';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import InterviewerButton from '@pages/Marketplace/Feed/FeedProfileSettings/InterviewerButton';
import FiltersFeedItems from '@pages/Marketplace/Feed/FiltersFeedItems';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import { useFeed } from '../../../../zustand/feed';

const FeedProfileSettings = () => {
  const { setIsPinnedReturn, isPinnedReturn } = useFeed();

  return (
    <Fade in className="hidden md:block">
      <aside className="md:max-w-[420px]  bg-transparent  flex-shrink-0 h-full sticky top-28">
        <Box className="flex  bg-white/5 p-4 !backdrop-blur-3xl mb-2	rounded-xl flex-col">
          {/* <EventAvailableIcon/> */}
          <Box className="flex" gap={2}>
            <FiltersFeedItems />
            <Box className="flex items-center">
              <Typography lineHeight="16px" fontSize="13px">
                Show pinned
              </Typography>
              <Checkbox
                onChange={() => setIsPinnedReturn(!isPinnedReturn)}
                value={isPinnedReturn}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" className=" bg-white/5 p-4 rounded-xl" flexDirection="column" gap={2}>
          {/* <UserInvitesList /> */}
          <Box display="flex" flexDirection="column" gap={1}>
            <InterviewerButton />
            <FeedProfileTraitsForm />
            <ProfileTags />
          </Box>
        </Box>
      </aside>
    </Fade>
  );
};

export default FeedProfileSettings;
