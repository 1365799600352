import Dialog from '@mui/material/Dialog';
import CreateDashboardForm from '@pages/Marketplace/Dashboards/CreateDashboardForm';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Entity } from '@proto/grpc/type/v1/entity_pb';

type DashboardDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  entity?: Entity;
};

const CreateDashboardDialog = (props: DashboardDialogProps) => {
  const { isOpen, handleClose, entity } = props;

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        sx={{
          width: { xs: '300px', sm: '400px', md: '700px' },
        }}
        py={5}
        px={{ xs: 3 }}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box display="flex" gap={1}>
          <DashboardIcon />
          <Typography variant="subtitle1">Create Your Dashboard</Typography>
        </Box>
        <Typography>
          Create a dashboard to manage all your collaboration in Squady. Yup, it’s a good old
          Kanboard board to track the status of your applications, active contracts and interviews.
          You can create one or multiple dashboards - whatever is more convenient for you.
        </Typography>
        <Typography variant="subtitle2">Dashboard Details:</Typography>
        <CreateDashboardForm handleCloseModal={handleClose} owner={entity} />
      </Box>
    </Dialog>
  );
};

export default CreateDashboardDialog;
