import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';
import { replaceDashesWithSpaces, replaceSpacesWithDashes } from '@utils/parseStrings';
import Avatar from '@components/Avatar/Avatar';
import SupplyDemandCard from '@pages/Marketplace/Dashboards/SupplyDemandCard';
import Button from '@mui/material/Button';
import React from 'react';
import {
  useApproveContractor,
  useGetDashboard,
  useRejectContractor,
} from '@services/queries/MarketplaceQueries';
import { useListSquads } from '@services/queries/SquadsQueries';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { mergeSupplies } from '@pages/Marketplace/Dashboards/config';
import Fade from '@mui/material/Fade';
import InfoIcon from '@mui/icons-material/Info';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { useListOrgs } from '@services/queries/OrgsQueries';
import { ListOrgsRequest } from '@proto/profiler/biz/v1/org_pb';
import { ListSquadsRequest } from '@proto/profiler/biz/v1/squad_pb';
import { GetDashboardRequest_Selector } from '@proto/marketplace/dashboard/v1/dashboard_pb';
import { isUUID } from '@utils/isUUID';

const entityLabels = {
  [Entity_Type.SQUAD]: 'Squad',
  [Entity_Type.ORG]: 'Organization',
};

const SupplyList = () => {
  const { dashboardId } = useParams();
  const { mutate: approveContractor } = useApproveContractor();
  const { mutate: rejectContractor } = useRejectContractor();
  const { data } = useGetDashboard({
    case: isUUID(dashboardId as string) ? 'dashboardId' : 'title',
    value: isUUID(dashboardId as string)
      ? (dashboardId as string)
      : replaceDashesWithSpaces(dashboardId as string),
  });

  const suppliesList = mergeSupplies(
    data?.demands?.contracts ? Object.entries(data.demands.contracts) : []
  );

  const { data: listSquads } = useListSquads(
    new ListSquadsRequest({
      by: {
        case: 'ids',
        value: new UUIDS({
          values: Object.values(suppliesList)
            ?.filter((supply) => supply.supplier?.type === Entity_Type.SQUAD)
            .map((supply) => supply.supplier?.id?.value as string),
        }),
      },
    })
  );

  const { data: listOrgs } = useListOrgs(
    new ListOrgsRequest({
      by: {
        case: 'ids',
        value: new UUIDS({
          values: Object.values(suppliesList)
            ?.filter((supply) => supply.supplier?.type === Entity_Type.ORG)
            .map((supply) => supply.supplier?.id?.value as string),
        }),
      },
    })
  );

  const mergedList = { ...listSquads, ...listOrgs };

  return (
    <Fade in timeout={500}>
      <Box display="flex" alignItems="center" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
          {!Object.entries(suppliesList)?.length ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap={2}
            >
              <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
                <InfoIcon />
                <Typography variant="subtitle2">You not have supplies</Typography>
              </Box>
            </Box>
          ) : null}

          {Object.entries(suppliesList)?.map(([supplyId, supply]) => {
            const { contractIds } = supply;
            return (
              <Box
                display="flex"
                gap={4}
                px={2}
                py={1}
                sx={{ borderRadius: '16px', border: '1px solid rgba(255, 255, 255, 0.12)' }}
              >
                <Box>
                  <Link
                    className="NavLink"
                    to={`${ROUTES_PREFIXES.SQUADS}/${replaceSpacesWithDashes(
                      `${mergedList[supply?.supplier?.id?.value as string]?.name}`
                    )}`}
                  >
                    <Box>
                      <Typography>Supplier</Typography>
                      <Box display="flex" gap={1} alignItems="center">
                        <Avatar
                          alt={`${mergedList[supply?.supplier?.id?.value as string]?.name}`}
                          profileId={mergedList[supply?.supplier?.id?.value as string]?.name}
                          avatarId={mergedList[supply?.supplier?.id?.value as string]?.avatar}
                        />
                        <Typography>
                          {mergedList[supply?.supplier?.id?.value as string]?.name}
                        </Typography>
                      </Box>
                      <Box>{entityLabels[supply?.supplier?.type as keyof typeof entityLabels]}</Box>
                    </Box>
                  </Link>
                </Box>
                <Box>
                  <Typography>Demands</Typography>
                  <Box display="flex" gap={1}>
                    {contractIds?.map((contractId) => {
                      return (
                        <Box>
                          <SupplyDemandCard demand={data?.demands?.contracts[contractId].entity} />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() =>
                      approveContractor({
                        supplyId,
                      })
                    }
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() =>
                      rejectContractor({
                        supplyId,
                      })
                    }
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Fade>
  );
};

export default SupplyList;
