import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { LineSeriesType } from '@mui/x-charts';
import { Demand, Demand_Status_Status } from '@proto/marketplace/demand/v1/demand_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { Box } from '@mui/material';
import { useDashboard } from 'zustand/dashboard';

interface ChartProps {
  demands: Demand[];
}

const DemandChart: React.FC<ChartProps> = ({ demands }) => {
  const { setHoveredDemandChartIds } = useDashboard();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const statusLabels = React.useMemo(
    () => ({
      [Demand_Status_Status.UNSPECIFIED]: 'Unspecified',
      [Demand_Status_Status.DRAFT]: 'Draft',
      [Demand_Status_Status.ACTIVE]: 'Active',
      [Demand_Status_Status.IN_PROGRESS]: 'In Progress',
      [Demand_Status_Status.DONE]: 'Done',
      [Demand_Status_Status.CANCELLED]: 'Cancelled',
    }),
    []
  );

  const statusColors = React.useMemo(
    () => ({
      [statusLabels[Demand_Status_Status.DRAFT]]: '#bdbdbd',
      [statusLabels[Demand_Status_Status.ACTIVE]]: '#26a69a',
      [statusLabels[Demand_Status_Status.IN_PROGRESS]]: '#2196f3',
      [statusLabels[Demand_Status_Status.DONE]]: '#7e57c2',
      [statusLabels[Demand_Status_Status.CANCELLED]]: '#c62828',
    }),
    [statusLabels]
  );

  const memoizedData = React.useMemo(() => {
    const localAggregated: Record<string, Record<string, number>> = {};
    const localAggregatedIds: Record<string, string[]> = {};
    const localStatuses = new Set<string>();

    demands.forEach((demand) => {
      let updatedAt: Date | undefined;
      let demandId: string | undefined;

      if (demand.entity.case === 'interview' && demand.entity.value) {
        const interview = demand.entity.value as Interview;
        updatedAt = interview.updatedAt?.toDate();
        demandId = interview.interviewId?.value as string;
      } else if (demand.entity.case === 'contract' && demand.entity.value) {
        const contract = demand.entity.value as Contract;
        updatedAt = contract.updatedAt?.toDate();
        demandId = contract.contractId?.value as string;
      }
      if (!updatedAt) return;

      const dateKey = updatedAt.toISOString().split('T')[0];

      if (!localAggregated[dateKey]) {
        localAggregated[dateKey] = {};
      }
      const statusEnum = demand.status?.status ?? Demand_Status_Status.UNSPECIFIED;
      const statusLabel = statusLabels[statusEnum] || 'Unknown';

      if (statusEnum !== Demand_Status_Status.UNSPECIFIED) {
        localStatuses.add(statusLabel);
      }
      localAggregated[dateKey][statusLabel] = (localAggregated[dateKey][statusLabel] || 0) + 1;

      if (!localAggregatedIds[dateKey]) {
        localAggregatedIds[dateKey] = [];
      }
      if (demandId) {
        localAggregatedIds[dateKey].push(demandId);
      }
    });

    const sortedDateKeys = Object.keys(localAggregated).sort();

    const localXAxisData: Date[] = [];
    if (sortedDateKeys.length > 0) {
      const firstDate = new Date(sortedDateKeys[0]);
      const startDate = new Date(firstDate);
      startDate.setDate(firstDate.getDate() - 1);
      localXAxisData.push(startDate);
    }
    sortedDateKeys.forEach((dateStr) => {
      localXAxisData.push(new Date(dateStr));
    });

    const localSeries: Array<LineSeriesType> = Array.from(localStatuses).map((status) => {
      let runningTotal = 0;
      const data = [0];
      sortedDateKeys.forEach((dateKey) => {
        const count = localAggregated[dateKey][status] ?? 0;
        runningTotal += count;
        data.push(runningTotal);
      });
      return {
        type: 'line' as const,
        label: status,
        data,
        color: statusColors[status],
        valueFormatter: (value) => (value == null ? '0' : value.toString()),
      };
    });

    return {
      localAggregatedIds,
      localXAxisData,
      localSeries,
    };
  }, [demands, statusLabels, statusColors]);

  const { localAggregatedIds, localXAxisData, localSeries } = memoizedData;

  const handleMouseMove = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!containerRef.current) return;
      const rect = containerRef.current.getBoundingClientRect();
      const relativeX = event.clientX - rect.left;
      const containerWidth = rect.width;
      const index = Math.floor((relativeX / containerWidth) * localXAxisData.length);
      if (index >= 0 && index < localXAxisData.length) {
        const hoveredDate = localXAxisData[index];
        const dateKey = hoveredDate.toISOString().split('T')[0];
        const ids = localAggregatedIds[dateKey] || [];
        setHoveredDemandChartIds(ids);
      } else {
        setHoveredDemandChartIds([]);
      }
    },
    [localXAxisData, localAggregatedIds, setHoveredDemandChartIds]
  );

  const handleMouseLeave = React.useCallback(() => {
    setHoveredDemandChartIds([]);
  }, [setHoveredDemandChartIds]);

  return (
    <Box
      ref={containerRef}
      sx={{ width: '100%', height: 300 }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <LineChart
        series={localSeries}
        xAxis={[
          {
            data: localXAxisData,
            scaleType: 'band',
            valueFormatter: (value: Date) =>
              value.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
          },
        ]}
        yAxis={[
          {
            label: 'Count',
            tickMinStep: 1,
          },
        ]}
      />
    </Box>
  );
};

export default DemandChart;
