import Box from '@mui/material/Box';
import { useDialog } from '@components/hooks/useDialog';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import CreateDemandForm from '@pages/Marketplace/ManageDemands/CreateDemandForm';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import { isUUID } from '@utils/isUUID';
import { replaceDashesWithSpaces } from '@utils/parseStrings';
import { useGetDashboard } from '@services/queries/MarketplaceQueries';

const CreateDemandButton = () => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { dashboardId } = useParams();
  const { data } = useGetDashboard({
    case: isUUID(dashboardId as string) ? 'dashboardId' : 'title',
    value: isUUID(dashboardId as string)
      ? (dashboardId as string)
      : replaceDashesWithSpaces(dashboardId as string),
  });

  return (
    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
      <Tooltip placement="right-start" title="Create demand">
        <AddIcon cursor="pointer" onClick={handleOpen} />
      </Tooltip>
      <Suspense fallback={null}>
        {dashboardId && (
          <CreateDemandForm
            dashboardId={data?.kanbanBoard?.dashboardId?.value as string}
            handleClose={handleClose}
            isOpen={isOpen}
          />
        )}
      </Suspense>
    </Box>
  );
};

export default CreateDemandButton;
