// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file profiler/biz/v1/squad.proto (package profiler.biz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Profiles } from '../../user/v1/profile_pb.js';
import { Tags } from '../../tags/v1/tags_pb.js';
import { RWX } from '../../../iam/auth/v1/iam_pb.js';
import { Filter } from '../../../grpc/type/v1/entity_pb.js';

/**
 * @generated from message profiler.biz.v1.Squad
 */
export class Squad extends Message<Squad> {
  /**
   * @generated from field: grpc.type.v1.UUID squad_id = 1;
   */
  squadId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: string owner_id = 3;
   */
  ownerId = '';

  /**
   * @generated from field: bool active = 4;
   */
  active = false;

  /**
   * @generated from field: string name = 5;
   */
  name = '';

  /**
   * @generated from field: string about = 6;
   */
  about = '';

  /**
   * @generated from field: string avatar = 7;
   */
  avatar = '';

  /**
   * @generated from oneof profiler.biz.v1.Squad.members
   */
  members:
    | {
        /**
         * @generated from field: profiler.user.v1.Profiles profiles = 8;
         */
        value: Profiles;
        case: 'profiles';
      }
    | {
        /**
         * @generated from field: profiler.tags.v1.Tags tags = 9;
         */
        value: Tags;
        case: 'tags';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool available = 10;
   */
  available = false;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 11;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<Squad>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.Squad';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squad_id', kind: 'message', T: UUID },
    { no: 2, name: 'created', kind: 'message', T: Timestamp },
    { no: 3, name: 'owner_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'about', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'profiles', kind: 'message', T: Profiles, oneof: 'members' },
    { no: 9, name: 'tags', kind: 'message', T: Tags, oneof: 'members' },
    { no: 10, name: 'available', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Squad {
    return new Squad().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Squad {
    return new Squad().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Squad {
    return new Squad().fromJsonString(jsonString, options);
  }

  static equals(
    a: Squad | PlainMessage<Squad> | undefined,
    b: Squad | PlainMessage<Squad> | undefined
  ): boolean {
    return proto3.util.equals(Squad, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.Squads
 */
export class Squads extends Message<Squads> {
  /**
   * @generated from field: repeated profiler.biz.v1.Squad squads = 1;
   */
  squads: Squad[] = [];

  constructor(data?: PartialMessage<Squads>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.Squads';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squads', kind: 'message', T: Squad, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Squads {
    return new Squads().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Squads {
    return new Squads().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Squads {
    return new Squads().fromJsonString(jsonString, options);
  }

  static equals(
    a: Squads | PlainMessage<Squads> | undefined,
    b: Squads | PlainMessage<Squads> | undefined
  ): boolean {
    return proto3.util.equals(Squads, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.CreateSquadRequest
 */
export class CreateSquadRequest extends Message<CreateSquadRequest> {
  /**
   * @generated from field: string name = 2;
   */
  name = '';

  /**
   * @generated from field: string about = 3;
   */
  about = '';

  constructor(data?: PartialMessage<CreateSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.CreateSquadRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'about', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSquadRequest {
    return new CreateSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSquadRequest {
    return new CreateSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateSquadRequest {
    return new CreateSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateSquadRequest | PlainMessage<CreateSquadRequest> | undefined,
    b: CreateSquadRequest | PlainMessage<CreateSquadRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateSquadRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.CreateSquadResponse
 */
export class CreateSquadResponse extends Message<CreateSquadResponse> {
  /**
   * @generated from field: profiler.biz.v1.Squad squad = 1;
   */
  squad?: Squad;

  constructor(data?: PartialMessage<CreateSquadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.CreateSquadResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squad', kind: 'message', T: Squad },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSquadResponse {
    return new CreateSquadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSquadResponse {
    return new CreateSquadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateSquadResponse {
    return new CreateSquadResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateSquadResponse | PlainMessage<CreateSquadResponse> | undefined,
    b: CreateSquadResponse | PlainMessage<CreateSquadResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateSquadResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.SetSquadStatusRequest
 */
export class SetSquadStatusRequest extends Message<SetSquadStatusRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID squad_id = 1;
   */
  squadId?: UUID;

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  constructor(data?: PartialMessage<SetSquadStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.SetSquadStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squad_id', kind: 'message', T: UUID },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetSquadStatusRequest {
    return new SetSquadStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetSquadStatusRequest {
    return new SetSquadStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetSquadStatusRequest {
    return new SetSquadStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetSquadStatusRequest | PlainMessage<SetSquadStatusRequest> | undefined,
    b: SetSquadStatusRequest | PlainMessage<SetSquadStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetSquadStatusRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.SetSquadStatusResponse
 */
export class SetSquadStatusResponse extends Message<SetSquadStatusResponse> {
  constructor(data?: PartialMessage<SetSquadStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.SetSquadStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetSquadStatusResponse {
    return new SetSquadStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetSquadStatusResponse {
    return new SetSquadStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetSquadStatusResponse {
    return new SetSquadStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetSquadStatusResponse | PlainMessage<SetSquadStatusResponse> | undefined,
    b: SetSquadStatusResponse | PlainMessage<SetSquadStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetSquadStatusResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.GetSquadRequest
 */
export class GetSquadRequest extends Message<GetSquadRequest> {
  /**
   * @generated from field: grpc.type.v1.Filter filter = 1;
   */
  filter?: Filter;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.GetSquadRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'filter', kind: 'message', T: Filter },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSquadRequest {
    return new GetSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSquadRequest {
    return new GetSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSquadRequest {
    return new GetSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetSquadRequest | PlainMessage<GetSquadRequest> | undefined,
    b: GetSquadRequest | PlainMessage<GetSquadRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetSquadRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.GetSquadResponse
 */
export class GetSquadResponse extends Message<GetSquadResponse> {
  /**
   * @generated from field: profiler.biz.v1.Squad squad = 1;
   */
  squad?: Squad;

  constructor(data?: PartialMessage<GetSquadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.GetSquadResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squad', kind: 'message', T: Squad },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSquadResponse {
    return new GetSquadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSquadResponse {
    return new GetSquadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSquadResponse {
    return new GetSquadResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetSquadResponse | PlainMessage<GetSquadResponse> | undefined,
    b: GetSquadResponse | PlainMessage<GetSquadResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetSquadResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListSquadsRequest
 */
export class ListSquadsRequest extends Message<ListSquadsRequest> {
  /**
   * @generated from oneof profiler.biz.v1.ListSquadsRequest.by
   */
  by:
    | {
        /**
         * @generated from field: profiler.tags.v1.Tags tags = 1;
         */
        value: Tags;
        case: 'tags';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS ids = 2;
         */
        value: UUIDS;
        case: 'ids';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * If tags is empty then offset is using for pagination by created field otherwise for squaders filtering
   *
   * @generated from field: google.protobuf.Timestamp offset = 3;
   */
  offset?: Timestamp;

  constructor(data?: PartialMessage<ListSquadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListSquadsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tags', kind: 'message', T: Tags, oneof: 'by' },
    { no: 2, name: 'ids', kind: 'message', T: UUIDS, oneof: 'by' },
    { no: 3, name: 'offset', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSquadsRequest {
    return new ListSquadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSquadsRequest {
    return new ListSquadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSquadsRequest {
    return new ListSquadsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListSquadsRequest | PlainMessage<ListSquadsRequest> | undefined,
    b: ListSquadsRequest | PlainMessage<ListSquadsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListSquadsRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListSquadsResponse
 */
export class ListSquadsResponse extends Message<ListSquadsResponse> {
  /**
   * @generated from field: profiler.biz.v1.Squads squads = 1;
   */
  squads?: Squads;

  constructor(data?: PartialMessage<ListSquadsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListSquadsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squads', kind: 'message', T: Squads },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSquadsResponse {
    return new ListSquadsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSquadsResponse {
    return new ListSquadsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListSquadsResponse {
    return new ListSquadsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListSquadsResponse | PlainMessage<ListSquadsResponse> | undefined,
    b: ListSquadsResponse | PlainMessage<ListSquadsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListSquadsResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.UpdateSquadInfoRequest
 */
export class UpdateSquadInfoRequest extends Message<UpdateSquadInfoRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID squad_id = 1;
   */
  squadId?: UUID;

  /**
   * @generated from field: string about = 2;
   */
  about = '';

  constructor(data?: PartialMessage<UpdateSquadInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.UpdateSquadInfoRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squad_id', kind: 'message', T: UUID },
    { no: 2, name: 'about', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateSquadInfoRequest {
    return new UpdateSquadInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateSquadInfoRequest {
    return new UpdateSquadInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateSquadInfoRequest {
    return new UpdateSquadInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateSquadInfoRequest | PlainMessage<UpdateSquadInfoRequest> | undefined,
    b: UpdateSquadInfoRequest | PlainMessage<UpdateSquadInfoRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateSquadInfoRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.UpdateSquadInfoResponse
 */
export class UpdateSquadInfoResponse extends Message<UpdateSquadInfoResponse> {
  constructor(data?: PartialMessage<UpdateSquadInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.UpdateSquadInfoResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateSquadInfoResponse {
    return new UpdateSquadInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateSquadInfoResponse {
    return new UpdateSquadInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateSquadInfoResponse {
    return new UpdateSquadInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateSquadInfoResponse | PlainMessage<UpdateSquadInfoResponse> | undefined,
    b: UpdateSquadInfoResponse | PlainMessage<UpdateSquadInfoResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateSquadInfoResponse, a, b);
  }
}
