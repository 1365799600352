import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import MobileDrawer from '@pages/Main/MobileDrawer';
import NotificationPopover from '@components/NotificationPopover/NotificationPopover';
import UserProfileDrawer from '@pages/Main/ProfilePopover';
import CreateEntityMenu from '@pages/Main/CreateEntityMenu';
import React, { useEffect } from 'react';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import OpenChatButton from '@components/Chat/OpenChatButton';
import { NavBar } from '@components/Header/NavBar';
import { Logo } from '../Logo';
import { useStreamStore } from '../../zustand/subscribe';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Header = (): JSX.Element => {
  const { subscribe, cancelStream } = useStreamStore();

  useEffect(() => {
    subscribe();

    return () => {
      cancelStream();
    };
  }, [subscribe, cancelStream]);

  return (
    <Box className="backdrop-blur h-[60px] lg:h-[96px]  sticky top-0 z-50">
      <AppBar sx={{ width: '100%', display: { xs: 'block', md: 'none' } }} position="fixed" open>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MobileDrawer />
          <Box display="flex" alignItems="center" gap={2}>
            <CreateEntityMenu />
            <NotificationPopover />
            <UserProfileDrawer />
          </Box>
        </Toolbar>
      </AppBar>

      <AppBar sx={{ display: { xs: 'none', md: 'block' } }} position="fixed" open>
        <Toolbar className=" py-4">
          <Box display="flex" flexGrow={1} alignItems="center" gap={5}>
            <Logo />
            <Box display="flex" className="items-center" gap={2}>
              <NavBar />
            </Box>
            {/* <Breadcrumbs /> */}
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <OpenChatButton />
            <CreateEntityMenu />
            <NotificationPopover />
            <UserProfileDrawer />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
