import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import LogoSrc from '@assets/images/icons/logo.svg';

export const Logo = () => (
  <Box maxWidth={{ xs: 74, md: 86, xl: 98 }}>
    <Link to="/">
      <img src={LogoSrc} alt="Logo" />
    </Link>
  </Box>
);
