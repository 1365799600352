// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file rewarder/achievements/v1/achievements.proto (package rewarder.achievements.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from enum rewarder.achievements.v1.AchievementId
 */
export enum AchievementId {
  /**
   * @generated from enum value: ACHIEVEMENT_ID_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_EGG_HUNTER = 1;
   */
  EGG_HUNTER = 1,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_EARLY_ADOPTER = 2;
   */
  EARLY_ADOPTER = 2,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_BUG_REPORTER = 3;
   */
  BUG_REPORTER = 3,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_PROFILE_COMPLETE = 4;
   */
  PROFILE_COMPLETE = 4,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_CONDUCTED_INTERVIEWS = 5;
   */
  CONDUCTED_INTERVIEWS = 5,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_INTERVIEWS_COMPLETE = 6;
   */
  INTERVIEWS_COMPLETE = 6,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_SQUAD_SIZE = 7;
   */
  SQUAD_SIZE = 7,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_FIRST_DEAL = 8;
   */
  FIRST_DEAL = 8,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_MONEY_EARNED = 9;
   */
  MONEY_EARNED = 9,

  /**
   * @generated from enum value: ACHIEVEMENT_ID_REFERALS = 10;
   */
  REFERALS = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(AchievementId)
proto3.util.setEnumType(AchievementId, 'rewarder.achievements.v1.AchievementId', [
  { no: 0, name: 'ACHIEVEMENT_ID_UNSPECIFIED' },
  { no: 1, name: 'ACHIEVEMENT_ID_EGG_HUNTER' },
  { no: 2, name: 'ACHIEVEMENT_ID_EARLY_ADOPTER' },
  { no: 3, name: 'ACHIEVEMENT_ID_BUG_REPORTER' },
  { no: 4, name: 'ACHIEVEMENT_ID_PROFILE_COMPLETE' },
  { no: 5, name: 'ACHIEVEMENT_ID_CONDUCTED_INTERVIEWS' },
  { no: 6, name: 'ACHIEVEMENT_ID_INTERVIEWS_COMPLETE' },
  { no: 7, name: 'ACHIEVEMENT_ID_SQUAD_SIZE' },
  { no: 8, name: 'ACHIEVEMENT_ID_FIRST_DEAL' },
  { no: 9, name: 'ACHIEVEMENT_ID_MONEY_EARNED' },
  { no: 10, name: 'ACHIEVEMENT_ID_REFERALS' },
]);

/**
 * @generated from message rewarder.achievements.v1.Conditions
 */
export class Conditions extends Message<Conditions> {
  /**
   * @generated from field: map<int32, string> conditions = 1;
   */
  conditions: { [key: number]: string } = {};

  constructor(data?: PartialMessage<Conditions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.Conditions';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'conditions',
      kind: 'map',
      K: 5 /* ScalarType.INT32 */,
      V: { kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Conditions {
    return new Conditions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Conditions {
    return new Conditions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Conditions {
    return new Conditions().fromJsonString(jsonString, options);
  }

  static equals(
    a: Conditions | PlainMessage<Conditions> | undefined,
    b: Conditions | PlainMessage<Conditions> | undefined
  ): boolean {
    return proto3.util.equals(Conditions, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.Achievement
 */
export class Achievement extends Message<Achievement> {
  /**
   * @generated from field: rewarder.achievements.v1.AchievementId id = 1;
   */
  id = AchievementId.UNSPECIFIED;

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  /**
   * @generated from field: map<int32, string> conditions = 3;
   */
  conditions: { [key: number]: string } = {};

  constructor(data?: PartialMessage<Achievement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.Achievement';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'enum', T: proto3.getEnumType(AchievementId) },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'conditions',
      kind: 'map',
      K: 5 /* ScalarType.INT32 */,
      V: { kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Achievement {
    return new Achievement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Achievement {
    return new Achievement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Achievement {
    return new Achievement().fromJsonString(jsonString, options);
  }

  static equals(
    a: Achievement | PlainMessage<Achievement> | undefined,
    b: Achievement | PlainMessage<Achievement> | undefined
  ): boolean {
    return proto3.util.equals(Achievement, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.Achievements
 */
export class Achievements extends Message<Achievements> {
  /**
   * @generated from field: repeated rewarder.achievements.v1.Achievement achievements = 1;
   */
  achievements: Achievement[] = [];

  constructor(data?: PartialMessage<Achievements>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.Achievements';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'achievements', kind: 'message', T: Achievement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Achievements {
    return new Achievements().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Achievements {
    return new Achievements().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Achievements {
    return new Achievements().fromJsonString(jsonString, options);
  }

  static equals(
    a: Achievements | PlainMessage<Achievements> | undefined,
    b: Achievements | PlainMessage<Achievements> | undefined
  ): boolean {
    return proto3.util.equals(Achievements, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.ListAchievementsRequest
 */
export class ListAchievementsRequest extends Message<ListAchievementsRequest> {
  constructor(data?: PartialMessage<ListAchievementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.ListAchievementsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListAchievementsRequest {
    return new ListAchievementsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListAchievementsRequest {
    return new ListAchievementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAchievementsRequest {
    return new ListAchievementsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAchievementsRequest | PlainMessage<ListAchievementsRequest> | undefined,
    b: ListAchievementsRequest | PlainMessage<ListAchievementsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListAchievementsRequest, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.ListAchievementsResponse
 */
export class ListAchievementsResponse extends Message<ListAchievementsResponse> {
  /**
   * @generated from field: repeated rewarder.achievements.v1.Achievement achievements = 1;
   */
  achievements: Achievement[] = [];

  constructor(data?: PartialMessage<ListAchievementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.ListAchievementsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'achievements', kind: 'message', T: Achievement, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListAchievementsResponse {
    return new ListAchievementsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListAchievementsResponse {
    return new ListAchievementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAchievementsResponse {
    return new ListAchievementsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAchievementsResponse | PlainMessage<ListAchievementsResponse> | undefined,
    b: ListAchievementsResponse | PlainMessage<ListAchievementsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListAchievementsResponse, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.Achieved
 */
export class Achieved extends Message<Achieved> {
  /**
   * @generated from field: rewarder.achievements.v1.AchievementId achievement_id = 1;
   */
  achievementId = AchievementId.UNSPECIFIED;

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  /**
   * @generated from field: int32 value = 3;
   */
  value = 0;

  /**
   * @generated from field: rewarder.achievements.v1.AchievedLevel achieved_level = 4;
   */
  achievedLevel?: AchievedLevel;

  constructor(data?: PartialMessage<Achieved>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.Achieved';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'achievement_id', kind: 'enum', T: proto3.getEnumType(AchievementId) },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'value', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'achieved_level', kind: 'message', T: AchievedLevel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Achieved {
    return new Achieved().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Achieved {
    return new Achieved().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Achieved {
    return new Achieved().fromJsonString(jsonString, options);
  }

  static equals(
    a: Achieved | PlainMessage<Achieved> | undefined,
    b: Achieved | PlainMessage<Achieved> | undefined
  ): boolean {
    return proto3.util.equals(Achieved, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.AchievedLevel
 */
export class AchievedLevel extends Message<AchievedLevel> {
  /**
   * @generated from field: int32 level = 1;
   */
  level = 0;

  /**
   * @generated from field: google.protobuf.Timestamp awarded = 2;
   */
  awarded?: Timestamp;

  constructor(data?: PartialMessage<AchievedLevel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.AchievedLevel';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'level', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'awarded', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AchievedLevel {
    return new AchievedLevel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AchievedLevel {
    return new AchievedLevel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AchievedLevel {
    return new AchievedLevel().fromJsonString(jsonString, options);
  }

  static equals(
    a: AchievedLevel | PlainMessage<AchievedLevel> | undefined,
    b: AchievedLevel | PlainMessage<AchievedLevel> | undefined
  ): boolean {
    return proto3.util.equals(AchievedLevel, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.Progress
 */
export class Progress extends Message<Progress> {
  /**
   * @generated from field: repeated rewarder.achievements.v1.Achieved progress = 1;
   */
  progress: Achieved[] = [];

  constructor(data?: PartialMessage<Progress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.Progress';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'progress', kind: 'message', T: Achieved, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Progress {
    return new Progress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Progress {
    return new Progress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Progress {
    return new Progress().fromJsonString(jsonString, options);
  }

  static equals(
    a: Progress | PlainMessage<Progress> | undefined,
    b: Progress | PlainMessage<Progress> | undefined
  ): boolean {
    return proto3.util.equals(Progress, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.ProgressValue
 */
export class ProgressValue extends Message<ProgressValue> {
  /**
   * @generated from field: grpc.type.v1.UUID entity_id = 1;
   */
  entityId?: UUID;

  /**
   * @generated from field: int32 value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<ProgressValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.ProgressValue';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'message', T: UUID },
    { no: 2, name: 'value', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProgressValue {
    return new ProgressValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProgressValue {
    return new ProgressValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProgressValue {
    return new ProgressValue().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProgressValue | PlainMessage<ProgressValue> | undefined,
    b: ProgressValue | PlainMessage<ProgressValue> | undefined
  ): boolean {
    return proto3.util.equals(ProgressValue, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.UpdateProgressRequest
 */
export class UpdateProgressRequest extends Message<UpdateProgressRequest> {
  /**
   * @generated from field: rewarder.achievements.v1.AchievementId achievement_id = 1;
   */
  achievementId = AchievementId.UNSPECIFIED;

  /**
   * @generated from field: repeated rewarder.achievements.v1.ProgressValue values = 2;
   */
  values: ProgressValue[] = [];

  constructor(data?: PartialMessage<UpdateProgressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.UpdateProgressRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'achievement_id', kind: 'enum', T: proto3.getEnumType(AchievementId) },
    { no: 2, name: 'values', kind: 'message', T: ProgressValue, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProgressRequest {
    return new UpdateProgressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProgressRequest {
    return new UpdateProgressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProgressRequest {
    return new UpdateProgressRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProgressRequest | PlainMessage<UpdateProgressRequest> | undefined,
    b: UpdateProgressRequest | PlainMessage<UpdateProgressRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProgressRequest, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.UpdateProgressResponse
 */
export class UpdateProgressResponse extends Message<UpdateProgressResponse> {
  constructor(data?: PartialMessage<UpdateProgressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.UpdateProgressResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProgressResponse {
    return new UpdateProgressResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProgressResponse {
    return new UpdateProgressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProgressResponse {
    return new UpdateProgressResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProgressResponse | PlainMessage<UpdateProgressResponse> | undefined,
    b: UpdateProgressResponse | PlainMessage<UpdateProgressResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProgressResponse, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.ListProgressRequest
 */
export class ListProgressRequest extends Message<ListProgressRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS entities = 1;
   */
  entities?: UUIDS;

  constructor(data?: PartialMessage<ListProgressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.ListProgressRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entities', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProgressRequest {
    return new ListProgressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProgressRequest {
    return new ListProgressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProgressRequest {
    return new ListProgressRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProgressRequest | PlainMessage<ListProgressRequest> | undefined,
    b: ListProgressRequest | PlainMessage<ListProgressRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProgressRequest, a, b);
  }
}

/**
 * map[entity_id]Progress
 *
 * @generated from message rewarder.achievements.v1.ListProgressResponse
 */
export class ListProgressResponse extends Message<ListProgressResponse> {
  /**
   * @generated from field: map<string, rewarder.achievements.v1.Progress> progress = 1;
   */
  progress: { [key: string]: Progress } = {};

  constructor(data?: PartialMessage<ListProgressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.ListProgressResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'progress',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Progress },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProgressResponse {
    return new ListProgressResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProgressResponse {
    return new ListProgressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProgressResponse {
    return new ListProgressResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProgressResponse | PlainMessage<ListProgressResponse> | undefined,
    b: ListProgressResponse | PlainMessage<ListProgressResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProgressResponse, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.GetAchievementRequest
 */
export class GetAchievementRequest extends Message<GetAchievementRequest> {
  /**
   * @generated from field: rewarder.achievements.v1.AchievementId achievement_id = 1;
   */
  achievementId = AchievementId.UNSPECIFIED;

  constructor(data?: PartialMessage<GetAchievementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.GetAchievementRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'achievement_id', kind: 'enum', T: proto3.getEnumType(AchievementId) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetAchievementRequest {
    return new GetAchievementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAchievementRequest {
    return new GetAchievementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetAchievementRequest {
    return new GetAchievementRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAchievementRequest | PlainMessage<GetAchievementRequest> | undefined,
    b: GetAchievementRequest | PlainMessage<GetAchievementRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetAchievementRequest, a, b);
  }
}

/**
 * @generated from message rewarder.achievements.v1.GetAchievementResponse
 */
export class GetAchievementResponse extends Message<GetAchievementResponse> {
  /**
   * @generated from field: rewarder.achievements.v1.Achievement achievement = 1;
   */
  achievement?: Achievement;

  constructor(data?: PartialMessage<GetAchievementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'rewarder.achievements.v1.GetAchievementResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'achievement', kind: 'message', T: Achievement },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetAchievementResponse {
    return new GetAchievementResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetAchievementResponse {
    return new GetAchievementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetAchievementResponse {
    return new GetAchievementResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAchievementResponse | PlainMessage<GetAchievementResponse> | undefined,
    b: GetAchievementResponse | PlainMessage<GetAchievementResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetAchievementResponse, a, b);
  }
}
