import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { useFormContext } from 'react-hook-form';

import { InputController } from '@components/Form/controllers/InputController';
import { Form } from '@components/Form';
import {
  FIELD_LABELS,
  FieldNames,
  getDefaultValues,
} from '@pages/ProfileSettings/ProfileTraitsForm/config';
import { useGetMyProfile, useUupdateProfileTraits } from '@services/queries/ProfilesQueries';
import CloseIcon from '@mui/icons-material/Close';

const SubmitButton = () => {
  const { watch } = useFormContext();
  const { data: userProfile } = useGetMyProfile();

  if (
    Number(userProfile?.profile?.traits?.rate) === Number(watch('traits')?.rate) &&
    userProfile?.profile?.traits?.experience === watch('traits')?.experience &&
    userProfile?.profile?.traits?.capacity === watch('traits')?.capacity
  ) {
    return null;
  }

  return (
    <LoadingButton type="submit">
      <CheckIcon sx={{ width: '18px', height: '18px' }} />
    </LoadingButton>
  );
};

const CancelButton = ({ defaultValues }: { defaultValues: any }) => {
  const { reset, watch } = useFormContext();
  const { data: userProfile } = useGetMyProfile();

  const watchedTraits = watch('traits');

  const isChanged =
    Number(userProfile?.profile?.traits?.rate) !== Number(watchedTraits?.rate) ||
    userProfile?.profile?.traits?.experience !== watchedTraits?.experience ||
    userProfile?.profile?.traits?.capacity !== watchedTraits?.capacity;

  if (!isChanged) return null;

  const handleCancel = () => {
    reset(defaultValues);
  };

  return (
    <LoadingButton type="submit" onClick={handleCancel}>
      <CloseIcon sx={{ width: '18px', height: '18px' }} />
    </LoadingButton>
  );
};

const FeedProfileTraitsForm = () => {
  const { mutate: updateTraits } = useUupdateProfileTraits();
  const { data: userProfile, isLoading } = useGetMyProfile();

  if (!userProfile) return null;

  const defaultValues = getDefaultValues(userProfile?.profile?.traits);

  const handleSubmit = (data: any) => {
    updateTraits({ ...data, rate: BigInt(data.traits.rate) });
  };

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues }}
      // resolverSchema={myProfileTraitsFormSchema}
      submitHandler={handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box className="flex gap-4">
          <Grid pt={0} item xs={4}>
            <InputController
              variant="standard"
              fullWidth
              type="number"
              name={`traits.${FieldNames.RATE}`}
              label={FIELD_LABELS[FieldNames.RATE]}
            />
          </Grid>
          <Grid item xs={4}>
            <InputController
              variant="standard"
              fullWidth
              type="number"
              name={`traits.${FieldNames.CAPACITY}`}
              label={FIELD_LABELS[FieldNames.CAPACITY]}
            />
          </Grid>
          <Grid item xs={4}>
            <InputController
              variant="standard"
              fullWidth
              type="number"
              name={`traits.${FieldNames.EXPIRIENCE}`}
              label={FIELD_LABELS[FieldNames.EXPIRIENCE]}
            />
          </Grid>
        </Box>
        <Box className="flex justify-center gap-2">
          <SubmitButton />
          <CancelButton defaultValues={defaultValues} />
        </Box>
      </Box>
    </Form>
  );
};

export default FeedProfileTraitsForm;
