import Box from '@mui/material/Box';
import React, { Suspense } from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDialog } from '@components/hooks/useDialog';
import { useAppDispatch } from '@store/helpers';
import { userSlice } from '@store/profiler/slices';
import WaitConfirmationInvites from '@pages/Marketplace/ManageDemands/Interviews/WaitConfirmationInvites';
import ScrollableContainer from '@components/ScrollableContainerІ';
import { AddCandidateDialog } from '@pages/Marketplace/ManageDemands/Interviews/AddCandidateDialog';

type TListInvitedCandidates = {
  entityId: string;
  objectName?: string;
};

const ListInvitedCandidates = (props: TListInvitedCandidates) => {
  const { entityId, objectName } = props;
  const { isOpen, handleOpen, handleClose } = useDialog();
  const dispatch = useAppDispatch();
  const {
    actions: { deleteSelectedProfile },
  } = userSlice;

  const closeDialog = () => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    handleClose();
  };

  return (
    <ScrollableContainer maxHeight="75vh">
      <Box display="flex" flexDirection="column" gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
              <AddIcon onClick={handleOpen} color="success" cursor="pointer" />
            </Stack>
          </Box>
        </Stack>
        <WaitConfirmationInvites />
      </Box>
      <Suspense fallback={null}>
        <AddCandidateDialog
          objectName={objectName}
          isOpen={isOpen}
          handleClose={closeDialog}
          entityId={entityId}
        />
      </Suspense>
    </ScrollableContainer>
  );
};
export default ListInvitedCandidates;
