import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'sonner';

interface LinkInputProps {
  link: string;
  label?: string;
  defaultRevealed?: boolean;
}

const LinkInput: React.FC<LinkInputProps> = ({ label, link, defaultRevealed }) => {
  const [revealed, setRevealed] = useState(defaultRevealed);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      toast.success('Link copied');
      setRevealed(true);
    } catch (err) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <TextField
      id="link"
      value={revealed ? link : '*'.repeat(link.length)}
      variant="outlined"
      className="w-full"
      label={label}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleCopy}
              edge="end"
              sx={{
                color: '#00A8A8',
                '&:hover': {
                  color: '#09dbdb',
                },
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '16px',
          backgroundColor: 'transparent',
          '& fieldset': {
            borderColor: '#00A8A8',
          },
          '&:hover fieldset': {
            borderColor: '#09dbdb',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#00A8A8',
          },
        },
      }}
    />
  );
};

export default LinkInput;
