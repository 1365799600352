import { create } from 'zustand/react';

interface MembersState {
  searchedMembers: string | undefined;
  setSearchedMembers: (searchedMembers: string | undefined) => void;
}

export const useMembersState = create<MembersState>((set, get) => ({
  searchedMembers: undefined,
  setSearchedMembers: (searchedMembers: string | undefined) => set({ searchedMembers }),
}));
