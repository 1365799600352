import { create } from 'zustand/react';
import { Device } from '@proto/iam/auth/v1/auth_pb';
import { deviceService } from '@services/device';

interface AuthState {
  sessionId: string | undefined;
  device: {
    fingerprint: Device['fingerprint'];
    os: Device['os'];
  } | null;
  setSessionId: (sessionId: string | undefined) => void;
  getDevice: () => Promise<void>;
  isWelcomeProfile: boolean | null;
  setIsWelcomeProfile: (isWelcomeProfile: boolean | null) => void;
}

export const useAuthState = create<AuthState>((set, get) => ({
  sessionId: undefined,
  device: null,
  setSessionId: (sessionId) => set({ sessionId }),
  getDevice: async () => {
    const result = await deviceService.getDevice();
    set({ device: result.device });
  },
  isWelcomeProfile: null,
  setIsWelcomeProfile: (isWelcomeProfile) => set({ isWelcomeProfile }),
}));
