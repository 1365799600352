import { useQuery } from '@tanstack/react-query';
import {
  ListAchievementsRequest,
  ListAchievementsResponse,
  ListProgressRequest,
  ListProgressResponse,
} from '@proto/rewarder/achievements/v1/achievements_pb';
import { getConnectClient } from '@services/api/helpers';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { AchievementAPI } from '@proto/api/rewarder/v1/achievements_connect';

export const achievementsClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AchievementAPI
);

export const useListAchievements = () => {
  const listAchievements = async (): Promise<ListAchievementsResponse> => {
    const request = new ListAchievementsRequest();
    return achievementsClient.listAchievements(request);
  };

  return useQuery({
    queryKey: ['listAchievements'],
    queryFn: listAchievements,
  });
};

export const useListProgress = (payload: { ids: UUIDS; enabled: boolean }) => {
  const listProgress = async (): Promise<ListProgressResponse> => {
    const request = new ListProgressRequest({
      entities: payload.ids,
    });
    return achievementsClient.listProgress(request);
  };

  return useQuery({
    queryKey: ['listProgress', payload],
    queryFn: listProgress,
    enabled: payload?.enabled,
  });
};

// export const useUpdateProgress = () => {
//     const updateProgress = async (
//         payload: UpdateProgressRequest
//     ): Promise<UpdateProgressResponse> => {
//         console.log(payload);
//         return achievementsClient.updateProgress(payload);
//     };
//
//     return useMutation({
//         mutationFn: updateProgress,
//     });
// };
