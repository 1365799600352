import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useMarketplace } from '@store/marketplace';
import Typography from '@mui/material/Typography';
import { TOrg } from '@services/api/profiler/biz';
import { ListSquadsRequest, Squad, Squads } from '@proto/profiler/biz/v1/squad_pb';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import { ListOrgsRequest, Orgs } from '@proto/profiler/biz/v1/org_pb';
import { MenuItem } from '@mui/material';
import { useEffect } from 'react';

type ResultType = { entity: string; name: string };

const mergeLists = (squads: Squad[] = [], orgs: TOrg[] = []): ResultType[] => [
  ...squads.map(({ squadId: id, name }) => ({
    entity: JSON.stringify({ id, case: 'squadId' }),
    name,
  })),
  ...orgs.map(({ orgId: id, name }) => ({
    entity: JSON.stringify({ id, case: 'orgId' }),
    name,
  })),
];

const PreAppliedEntitySelect = () => {
  const { data: squadsList } = useListUserSquads(new ListSquadsRequest());
  const { data: orgsList } = useListUserOrgs(new ListOrgsRequest());
  const mergedList = mergeLists(
    (squadsList?.squads as Squads)?.squads,
    (orgsList?.orgs as Orgs)?.orgs
  );
  const { currentContractorId, setCurrentContractorId } = useMarketplace();

  const handleChange = (event: SelectChangeEvent) => {
    const entity = JSON.parse(event.target.value as string);
    setCurrentContractorId({
      id: entity.id,
      case: entity.case,
    });
  };
  useEffect(() => {
    setCurrentContractorId({
      id: squadsList?.squads?.squads[0]?.squadId || orgsList?.orgs?.orgs[0]?.orgId,
      case: squadsList?.squads?.squads[0] ? 'squadId' : 'orgId',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squadsList, orgsList]);

  return currentContractorId && currentContractorId.id ? (
    <div>
      <Typography variant="subtitle2" mb={1}>
        Select entity for apply contracts:
      </Typography>
      <Select value={JSON.stringify(currentContractorId)} onChange={handleChange} fullWidth>
        {mergedList?.map((entity) => (
          <MenuItem value={entity.entity} key={entity.name}>
            {entity.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;
};

export default PreAppliedEntitySelect;
