import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import React from 'react';
import UserCard from '@components/AddMemberDialog/UserCard';
import { useProfiler } from '@store/profiler';
import SendInvitesButton from '@components/Invites/SendInvites';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { SendInviteByEmailSchema } from '@components/AddMemberDialog/AddMemberDialog';
import { useInviteCandidates } from '@services/queries/MarketplaceQueries';
import { useSendInviteByEmails } from '@services/queries/ProfilesQueries';
import { Strings } from '@proto/grpc/type/v1/types_pb';
import { InviteCandidatesRequest } from '@proto/marketplace/demand/v1/candidate_pb';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import EmailAutocomplete from '@components/AddMemberDialog/EmailsAutocomplete';

type AddCandidateProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId?: string;
  objectName?: string;
};

export const AddCandidateDialog = (props: AddCandidateProps) => {
  const { isOpen, handleClose, entityId, objectName } = props;
  const { selectedProfile } = useProfiler();
  const [inviteType, setInviteType] = React.useState<'email' | 'user'>('user');
  const { mutate: inviteCandidates, error } = useInviteCandidates();
  const { mutate: sendInviteByEmails } = useSendInviteByEmails();
  const sendInvite = () => {
    if (selectedProfile && entityId) {
      inviteCandidates(
        new InviteCandidatesRequest({
          interviewId: new UUID({ value: entityId }),
          by: {
            case: 'profiles',
            value: new UUIDS({ values: [selectedProfile?.profileId?.value as string] }),
          },
        })
      );
    }
  };

  const handleSubmit = () => {
    sendInvite();
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box display="flex" flexDirection="column" px={2} py={4} gap={2} sx={{ width: '600px' }}>
        <Typography textAlign="center" variant="subtitle1">
          Add Candidate To {objectName}
        </Typography>
        <Box display="flex" justifyContent="center">
          {inviteType === 'user' ? (
            <Chip
              size="small"
              variant="outlined"
              onClick={() => setInviteType('email')}
              label="Send invitation by e-mail"
            />
          ) : (
            <Chip
              size="small"
              variant="outlined"
              onClick={() => setInviteType('user')}
              label="Find a user on our platform"
            />
          )}
        </Box>

        {inviteType === 'user' ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {/* <SearchProfile /> */}
            {selectedProfile && <UserCard userProfile={selectedProfile} />}
            {selectedProfile ? <SendInvitesButton handleSubmit={handleSubmit} /> : null}
          </Box>
        ) : (
          <Form
            width="100%"
            display="flex"
            alignSelf="center"
            flexDirection="column"
            formProps={{ defaultValues: { email: [] } }}
            submitHandler={(data) => {
              inviteCandidates(
                new InviteCandidatesRequest({
                  interviewId: new UUID({ value: entityId || '' }),
                  by: {
                    case: 'emails',
                    value: new Strings({ values: data.email }),
                  },
                })
              );
              handleClose();
            }}
            resolverSchema={SendInviteByEmailSchema}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <EmailAutocomplete name="email" />
              <Box display="flex" justifyContent="center">
                <LoadingButton type="submit" variant="contained">
                  Send Invite
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )}
      </Box>
    </Dialog>
  );
};
