import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { pinClient, profileClient, toolTipsClient } from '@services/api/profiler/profile';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  GetMyProfileRequest,
  GetProfileRequest,
  GetProfileResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  Profile,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
  UpdateProfileTraitsRequest,
  UpdateProfileTraitsResponse,
} from '@proto/profiler/user/v1/profile_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';
import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ToolTips,
} from '@proto/profiler/user/v1/tool_tips_pb';
import { inviteClient } from '@services/api/profiler/biz';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import {
  AcceptInviteRequest,
  GenerateInviteLinkRequest,
  GenerateInviteLinkRequest_ExpiresAt,
  JoinByInviteLinkRequest,
  ListInvitesRequest,
  ListInvitesResponse,
  ListUserInvitesRequest,
  ListUserInvitesResponse,
  RejectInviteRequest,
  RevokeInviteRequest,
  SendInvitesRequest,
  SendInvitesResponse,
} from '@proto/profiler/invite/v1/invite_pb';
import {
  PinItemRequest,
  PinItemResponse,
  UnpinItemRequest,
  UnpinItemResponse,
} from '@proto/profiler/user/v1/pin_pb';
import { JWT } from '@services/jwt';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { Strings } from '@proto/grpc/type/v1/types_pb';
import { Interceptor } from '@connectrpc/connect';
import { getConnectClientWithInterceptor } from '@services/api/helpers';
import { ProfileAPI } from '@proto/api/profiler/v1/profile_connect';
import { useMembersState } from '../../zustand/invites';
import { useAuthState } from '../../zustand/auth';

export const useGetMyProfile = () => {
  const tokens = JWT.getJWTTokens();
  const { sessionId, isWelcomeProfile } = useAuthState();

  const getMyProfile = async () => {
    const customInterceptor: Interceptor = (next) => async (req) => {
      if (sessionId && isWelcomeProfile) {
        req.header.set('x-session-id', sessionId);
      }
      return next(req);
    };

    const profileClientWithInterceptor = await getConnectClientWithInterceptor(
      `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
      ProfileAPI,
      customInterceptor
    );

    return profileClientWithInterceptor.getMyProfile(new GetMyProfileRequest());
  };

  // const getMyProfile = async (): Promise<GetMyProfileResponse> => {
  //   return profileClient.getMyProfile(new GetMyProfileRequest());
  // };

  return useQuery({
    queryKey: ['myProfile'],
    queryFn: getMyProfile,
    enabled: !!tokens,
  });
};

export const useListProfiles = (payload: UUIDS) => {
  const getSquad = async (): Promise<ListProfilesResponse> => {
    return profileClient.listProfiles(
      new ListProfilesRequest({
        profiles: payload,
      })
    );
  };

  return useQuery({
    queryKey: ['listProfiles', payload],
    queryFn: getSquad,
    select: (data: ListProfilesResponse) => {
      const profilesById = data.profiles?.profiles.reduce((acc, profile) => {
        if (profile.profileId) {
          acc[profile.profileId.value] = profile;
        }
        return acc;
      }, {} as { [key: string]: Profile });

      return profilesById;
    },
  });
};

export const useGetProfile = (payload: UUID, enabled: boolean) => {
  const getProfile = async (): Promise<GetProfileResponse> => {
    return profileClient.getProfile(
      new GetProfileRequest({
        by: {
          case: 'profileId',
          value: payload,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['getProfile', payload.value],
    queryFn: getProfile,
    enabled,
  });
};

export const useUpdateProfileName = () => {
  const dispatch = useAppDispatch();

  const updateProfileName = async (payload: {
    names: {
      firstName: string;
      lastName: string;
      nickName: string;
    };
    birthDate: Date | null;
  }): Promise<UpdateProfilePersonalInfoResponse> => {
    return profileClient.updateProfilePersonalInfo(
      new UpdateProfilePersonalInfoRequest({
        names: payload.names,
        birthDate: Timestamp.fromDate(
          // @ts-ignore
          payload.birthDate !== '' ? new Date(payload.birthDate) : new Date()
        ),
      })
    );
  };
  return useMutation({
    mutationKey: ['updateProfileName'],
    mutationFn: updateProfileName,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'update names',
          message: 'info updated',
          severity: 'success',
        })
      );
    },
  });
};

const useChangeProfileStatus = async () => {
  const dispatch = useAppDispatch();

  const changeProfileStatus = async (payload: {
    status: boolean;
  }): Promise<ChangeProfileStatusResponse> => {
    return profileClient.changeProfileStatus(
      new ChangeProfileStatusRequest({
        active: payload.status,
      })
    );
  };

  return useMutation({
    mutationKey: ['changeProfileStatus'],
    mutationFn: changeProfileStatus,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'change profile status',
          message: 'status updated',
          severity: 'success',
        })
      );
    },
  });
};

export const useGetListProfiles = (payload: string[]) => {
  const getListProfiles = async (): Promise<ListProfilesResponse> => {
    return profileClient.listProfiles(
      new ListProfilesRequest({
        profiles: new UUIDS({ values: payload }),
      })
    );
  };

  return useQuery({
    queryKey: ['listProfiles'],
    queryFn: getListProfiles,
  });
};

export const useUupdateProfileTraits = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const updateProfileTraits = async (
    payload: UpdateProfileTraitsRequest
  ): Promise<UpdateProfileTraitsResponse> => {
    return profileClient.updateProfileTraits(
      new UpdateProfileTraitsRequest({
        traits: {
          rate: payload.traits?.rate,
          capacity: payload.traits?.capacity,
          experience: payload.traits?.experience,
          interviewer: payload.traits?.interviewer,
          squader: payload.traits?.squader,
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['updateProfileTraits'],
    mutationFn: updateProfileTraits,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'update traits',
          message: 'traits updated',
          severity: 'success',
        })
      );
      queryClient.invalidateQueries({ queryKey: ['myProfile'] });
      queryClient.invalidateQueries({ queryKey: ['listFeedItems'] });
    },
    onError: () => {
      dispatch(
        showSnackbar({
          id: 'update traits',
          message: 'traits not updated',
          severity: 'error',
        })
      );
    },
  });
};

export const useFinishToolTips = () => {
  const dispatch = useAppDispatch();
  const finishToolTips = async (payload: ToolTips): Promise<FinishToolTipsResponse> => {
    return toolTipsClient.finishToolTips(
      new FinishToolTipsRequest({
        toolTips: payload,
      })
    );
  };

  return useMutation({
    mutationKey: ['finishToolTips'],
    mutationFn: finishToolTips,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'finish tool tips',
          message: 'tool tips finished',
          severity: 'success',
        })
      );
    },
  });
};

export const useSendInvite = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const sendInvite = async (payload: {
    id: string;
    type: Entity_Type;
    profileIds: string[];
  }): Promise<SendInvitesResponse> => {
    return inviteClient.sendInvites(
      new SendInvitesRequest({
        entity: {
          id: new UUID({ value: payload.id }),
          type: payload.type,
        },
        recipients: {
          case: 'profiles',
          value: new UUIDS({
            values: payload.profileIds,
          }),
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['sendInvite'],
    mutationFn: sendInvite,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'send invite',
          message: 'invite sent',
          severity: 'success',
        })
      );
      queryClient.invalidateQueries({ queryKey: ['listInvites'] });
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'send invite',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useSendInviteByEmails = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const sendInviteByEmails = async (payload: {
    id: string;
    type: Entity_Type;
    emails: Strings;
  }): Promise<SendInvitesResponse> => {
    return inviteClient.sendInvites(
      new SendInvitesRequest({
        entity: {
          id: new UUID({ value: payload.id }),
          type: payload.type,
        },
        recipients: {
          case: 'emails',
          value: payload.emails,
        },
      })
    );
  };

  return useMutation({
    mutationFn: sendInviteByEmails,
    onSuccess: () => {
      dispatch(
        showSnackbar({
          id: 'send invite',
          message: 'invite sent',
          severity: 'success',
        })
      );
      queryClient.invalidateQueries({ queryKey: ['listInvites'] });
    },
  });
};

export const usePinEntity = () => {
  const queryClient = useQueryClient();
  const pinEntity = async (payload: {
    entityId: string;
    entityType: Entity_Type;
  }): Promise<PinItemResponse> => {
    return pinClient.pinItem(
      new PinItemRequest({
        entity: {
          id: new UUID({ value: payload.entityId }),
          type: payload.entityType,
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['pinEntity'],
    mutationFn: pinEntity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myProfile'] });
      toast.success('Pinned successfully');
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });
};

export const useRevokeInvite = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const revokeInvite = async (payload: RevokeInviteRequest) => {
    return inviteClient.revokeInvite(payload);
  };

  return useMutation({
    mutationFn: revokeInvite,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listInvites'] });
      dispatch(
        showSnackbar({
          id: 'revoke invite',
          message: 'invite revoked',
          severity: 'success',
        })
      );
    },
  });
};

export const useUnpinEntity = () => {
  const queryClient = useQueryClient();
  const unpinEntity = async (payload: {
    entityId: string;
    entityType: Entity_Type;
  }): Promise<UnpinItemResponse> => {
    return pinClient.unpinItem(
      new UnpinItemRequest({
        entity: {
          id: new UUID({ value: payload.entityId }),
          type: payload.entityType,
        },
      })
    );
  };

  return useMutation({
    mutationKey: ['unpinEntity'],
    mutationFn: unpinEntity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['myProfile'] });
    },
  });
};

export const useListUserInvites = () => {
  const getListInvites = async (): Promise<ListUserInvitesResponse> => {
    return inviteClient.listUserInvites(new ListUserInvitesRequest());
  };

  return useQuery({
    queryKey: ['listInvites'],
    queryFn: getListInvites,
  });
};

export const useListInvites = (payload: { id: string; type: Entity_Type }) => {
  const getListInvites = async (): Promise<ListInvitesResponse> => {
    return inviteClient.listInvites(
      new ListInvitesRequest({
        entity: new Entity({
          id: new UUID({ value: payload.id }),
          type: payload.type,
        }),
      })
    );
  };

  return useQuery({
    queryKey: ['listInvites', payload],
    queryFn: getListInvites,
  });
};

export const useAcceptInvite = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const acceptInvite = async (payload: AcceptInviteRequest) => {
    return inviteClient.acceptInvite(payload);
  };

  return useMutation({
    mutationFn: acceptInvite,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listInvites'] });
      dispatch(
        showSnackbar({
          id: 'accept invite',
          message: 'invite accepted',
          severity: 'success',
        })
      );
    },
  });
};

export const useRejectInvite = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const rejectInvite = async (payload: RejectInviteRequest) => {
    return inviteClient.rejectInvite(payload);
  };

  return useMutation({
    mutationFn: rejectInvite,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listInvites'] });
      dispatch(
        showSnackbar({
          id: 'reject invite',
          message: 'invite rejected',
          severity: 'success',
        })
      );
    },
  });
};

export const useGenerateInviteLink = () => {
  const queryClient = useQueryClient();
  const { setInviteCode } = useMembersState();
  const generateInviteLink = async (payload: {
    entity: Entity;
    policy:
      | {
          value: GenerateInviteLinkRequest_ExpiresAt;
          case: 'expiresAt';
        }
      | {
          value: boolean;
          case: 'oneTime';
        };
  }) => {
    return inviteClient.generateInviteLink(
      new GenerateInviteLinkRequest({
        entity: payload.entity,
        policy: payload.policy,
      })
    );
  };

  return useMutation({
    mutationFn: generateInviteLink,
    onSuccess: (data) => {
      setInviteCode(data?.code);
      queryClient.invalidateQueries({ queryKey: ['listInvites'] });
    },
  });
};

const entityMapping = {
  [Entity_Type.USER]: 'user',
  [Entity_Type.SQUAD]: 'squads',
  [Entity_Type.ORG]: 'orgs',
};

export const useJoinByInviteLink = () => {
  const navigate = useNavigate();

  const joinByInviteLink = async (payload: { inviteId: string }) => {
    return inviteClient.joinByInviteLink(
      new JoinByInviteLinkRequest({
        code: payload.inviteId,
      })
    );
  };

  return useMutation({
    mutationFn: joinByInviteLink,
    onSuccess: (data) => {
      const redirectLink = `/${entityMapping[data?.entity?.type as keyof typeof entityMapping]}/${
        data?.entity?.id?.value
      }`;

      toast.success('Joined successfully');
      navigate(redirectLink);
    },
  });
};
