import { useLocation, useNavigate } from 'react-router-dom';
import { useJoinByInviteLink } from '@services/queries/ProfilesQueries';
import { useEffect } from 'react';

const JoinByInvite = () => {
  const { mutate: joinByInvite } = useJoinByInviteLink();
  const { search } = useLocation();
  const navigation = useNavigate();
  const queryParams = new URLSearchParams(search);
  const code = queryParams.get('code');

  useEffect(() => {
    if (code) {
      joinByInvite({ inviteId: code });
    }
    if (!code) {
      navigation('/');
    }
  }, [code, joinByInvite, navigation]);
  return <div />;
};

export default JoinByInvite;
