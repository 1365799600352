import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import Card from '@components/Card/Card';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { Link, useParams } from 'react-router-dom';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { useSetDemandstatus } from '@services/queries/MarketplaceQueries';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Demand, Demand_Status_Status } from '@proto/marketplace/demand/v1/demand_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { S } from '@fullcalendar/core/internal-common';
import { useDashboard } from 'zustand/dashboard';

type KanbanItemProps = {
  demand: Demand;
};

const KanbanDemandCard = (props: KanbanItemProps) => {
  const { dashboardId } = useParams();
  const { demand } = props;
  const [tagByType, setTagByType] = useState<Array<[TagTypeEnum, TTag[]]>>([]);
  const { mutate } = useSetDemandstatus();
  const { hoveredDemandChartIds } = useDashboard();
  const isChartHovered = hoveredDemandChartIds?.includes(
    (demand?.entity?.value as Contract)?.contractId?.value ||
      (demand?.entity?.value?.interviewId?.value as string)
  );

  useEffect(() => {
    setTagByType(transformTagsToTagsByType(demand?.entity?.value?.tags?.tags || []));
  }, [demand?.entity?.value?.tags?.tags]);

  const handleСlick = () => {
    mutate({
      demandId:
        (demand?.entity?.value as Contract)?.contractId?.value ||
        (demand?.entity?.value?.interviewId?.value as string),
      status: Number(demand?.status?.status) + 1,
    });
  };

  return (
    <Box className={`rounded-xl transition-all ${isChartHovered ? 'bg-white/10 ' : ''}`}>
      <Card>
        <Box display="flex" gap={1} flexDirection="column">
          <Link
            className="NavLink"
            to={`/${dashboardId}/${demand?.entity?.case}-manage/${
              (demand?.entity?.value as Contract)?.contractId?.value ||
              demand?.entity?.value?.interviewId?.value
            }`}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Typography fontSize="13px" fontWeight="bold">
                {demand?.entity?.case?.toUpperCase()}
              </Typography>

              {tagByType
                // @ts-ignore
                .filter(([type]) => type === TagType.ROLE)
                .map(([type, tags]) => (
                  <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                    {tags.map((tag) => (
                      <Chip
                        size="small"
                        key={tag?.value}
                        label={tag.alias || tag?.value}
                        variant="outlined"
                      />
                    ))}
                  </Box>
                ))}
            </Box>
          </Link>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DemandTraits fontSize="13px" demand={demand.entity?.value as Contract} />
            {demand?.status?.status === Demand_Status_Status.DRAFT && (
              <Box sx={{ cursor: 'pointer' }} onClick={handleСlick}>
                <KeyboardDoubleArrowRightIcon />
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default KanbanDemandCard;
