import { Message, PartialMessage, ServiceType } from '@bufbuild/protobuf';
import { createConnectTransport } from '@connectrpc/connect-web';
import { CallOptions, createClient, Interceptor, PromiseClient } from '@connectrpc/connect';
import { useNavigate } from 'react-router-dom';
import { JWT } from '../jwt';

export type PlainMsg<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [P in keyof T as T[P] extends Function ? never : P]: PlainField<T[P]>;
};

type OneOfSelectedMessage<K extends string, M extends Message> = {
  case: K;
  value: M;
};

type PlainField<F> = F extends Date | Uint8Array | bigint | boolean | string | number
  ? F
  : F extends Array<infer U>
  ? Array<PlainField<U>>
  : F extends ReadonlyArray<infer U>
  ? ReadonlyArray<PlainField<U>>
  : F extends Message
  ? PlainMsg<F>
  : F extends OneOfSelectedMessage<infer C, infer V>
  ? {
      case: C;
      value: PlainMsg<V>;
    }
  : F extends {
      case: string | undefined;
      value?: unknown;
    }
  ? F
  : F extends {
      [key: string | number]: Message<infer U>;
    }
  ? {
      [key: string | number]: PlainMsg<U>;
    }
  : F;

const authInterceptor: Interceptor = (next) => async (req) => {
  const tokens = JWT.getJWTTokens();

  if (tokens) {
    req.header.set('x-auth-token', tokens.accessToken);
  }

  return next(req);
};

const errorInterceptor: Interceptor = (next) => async (req) => {
  try {
    return await next(req);
  } catch (error: any) {
    if (error.code === 2 && error.message.includes('expired')) {
      JWT.removeJWTTokens();
      const navigate = useNavigate();
      navigate('/login');
    }
    throw error;
  }
};

export const getConnectClient: <ST extends ServiceType>(
  baseUrl: string | undefined,
  service: ST
) => PromiseClient<ST> = (baseUrl, service) => {
  if (!baseUrl) {
    throw new Error(`Base url for ${service.typeName} is missing`);
  }
  const transport = createConnectTransport({
    baseUrl,
    interceptors: [authInterceptor, errorInterceptor],
  });

  return createClient(service, transport);
};

export const getConnectClientWithInterceptor = async <ST extends ServiceType>(
  baseUrl: string | undefined,
  service: ST,
  customInterceptor?: Interceptor
) => {
  if (!baseUrl) {
    throw new Error(`Base url for ${service.typeName} is missing`);
  }

  const interceptors = [authInterceptor];
  if (customInterceptor) {
    interceptors.push(customInterceptor);
  }

  const transport = createConnectTransport({
    baseUrl,
    interceptors,
  });

  return createClient(service, transport);
};

export type TMakeRequest<
  RequestT extends Message<RequestT>,
  ResponseT extends Message<ResponseT>
> = (
  serviceMethod: (request: PartialMessage<RequestT>, options?: CallOptions) => Promise<ResponseT>,
  serviceMethodRequest: PartialMessage<RequestT>
) => Promise<PlainMsg<ResponseT>>;
// @ts-ignore
export const makeRequest: TMakeRequest = async (serviceMethod, serviceMethodRequest) => {
  const serviceMethodOptions: CallOptions = {};
  serviceMethodOptions.headers = new Headers();
  const tokens = JWT.getJWTTokens();
  const hasMetaKey = (obj: object) => {
    return Object.prototype.hasOwnProperty.call(obj, 'meta');
  };

  if (tokens?.accessToken) {
    serviceMethodOptions.headers.set('x-auth-token', tokens.accessToken);
  }

  if (hasMetaKey(serviceMethodRequest)) {
    serviceMethodOptions.headers.set(
      serviceMethodRequest.meta.label,
      serviceMethodRequest.meta.value
    );
  }

  const res = await serviceMethod(serviceMethodRequest, serviceMethodOptions);

  return res.toJson();
};

export type TMakeConnect<
  RequestT extends Message<RequestT>,
  ResponseT extends Message<ResponseT>
> = (
  serviceMethod: (request: PartialMessage<RequestT>, options?: CallOptions) => Promise<ResponseT>,
  serviceMethodRequest: PartialMessage<RequestT>
) => Promise<PlainMsg<ResponseT>>;
// @ts-ignore
export const makeConnect: TMakeConnect = async (serviceMethod, serviceMethodRequest) => {
  const serviceMethodOptions: CallOptions = {};
  serviceMethodOptions.headers = new Headers();
  const tokens = JWT.getJWTTokens();

  if (tokens?.accessToken) {
    serviceMethodOptions.headers.set('x-auth-token', tokens.accessToken);
  }

  serviceMethodOptions.headers.set('streaming-content-type', 'application/connect+proto');

  const iterableRes = await serviceMethod(serviceMethodRequest, serviceMethodOptions);
  return iterableRes;
};
