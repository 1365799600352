import { Box, Container, Drawer, Tab, Tabs } from '@mui/material';
import { a11yProps, TabPanel } from '@components/TabPanel/TabPanel';
import React, { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { tr } from 'date-fns/locale';

export interface TabItem {
  label: string;
  content: ReactNode;
  requiresPermission?: boolean;
  isVisible?: boolean;
}

interface TabContainerProps {
  tabs: TabItem[];
  userPermissions: boolean;
  pageName?: string;
}

const TabContainer = ({ tabs, userPermissions, pageName = 'root' }: TabContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const availableTabs = tabs.filter(
    (tab) => (!tab.requiresPermission || userPermissions) && tab.isVisible !== false
  );
  const currentTabParam = searchParams.get(pageName) || availableTabs[0].label;
  const currentIndex =
    availableTabs.findIndex((tab) => tab.label.toLocaleLowerCase() === currentTabParam) !== -1
      ? availableTabs.findIndex((tab) => tab.label.toLocaleLowerCase() === currentTabParam)
      : 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearchParams({ [pageName]: availableTabs[newValue].label.toLocaleLowerCase() });
  };

  return (
    <Box display="flex">
      <Box className="w-[200px] px-[8px]">
        <Tabs orientation="vertical" value={currentIndex} onChange={handleChange}>
          {availableTabs.map((tab, index) => (
            <Tab label={tab.label} className="!items-start !text-left" {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>

      {availableTabs.map((tab, index) => (
        <TabPanel value={currentIndex} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabContainer;
