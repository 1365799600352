import Box from '@mui/material/Box';
import { useTagsList } from '@services/queries/TagsQueries';
import { ListTagsResponse, Tag, TagType } from '@proto/profiler/tags/v1/tags_pb';
import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { useState } from 'react';

const tagConfigs = [
  { key: 'industryDomains', label: 'Industry Domains' },
  { key: 'roles', label: 'Roles' },
  { key: 'language', label: 'Languages' },
  { key: 'programmingLanguage', label: 'Programming Languages' },
  { key: 'country', label: 'Countries' },
];

const SearchSquadsByTags = ({
  selectedTags,
  handleTagChange,
}: {
  selectedTags: Record<string, Tag[]>;
  handleTagChange: (key: string, newTags: Tag[]) => void;
}) => {
  const [enabledTags, setEnabledTags] = useState<Record<string, boolean>>({});

  const { data: industryDomains } = useTagsList({
    tagType: TagType.INDUSTRY_DOMAIN,
    enabled: !!enabledTags?.industryDomains,
  });
  const { data: roles } = useTagsList({
    tagType: TagType.ROLE,
    enabled: !!enabledTags?.roles,
  });
  const { data: language } = useTagsList({
    tagType: TagType.LANGUAGE,
    enabled: !!enabledTags?.language,
  });
  const { data: programmingLanguage } = useTagsList({
    tagType: TagType.PROGRAMMING_LANGUAGE,
    enabled: !!enabledTags?.programmingLanguage,
  });
  const { data: country } = useTagsList({
    tagType: TagType.COUNTRY,
    enabled: !!enabledTags?.country,
  });

  type TagKey = 'industryDomains' | 'roles' | 'language' | 'programmingLanguage' | 'country';

  const tagDataMapping: Record<TagKey, ListTagsResponse | undefined> = {
    industryDomains,
    roles,
    language,
    programmingLanguage,
    country,
  };

  return (
    <Box className="flex flex-col gap-4 py-1 pr-2">
      {tagConfigs.map(({ key, label }) => (
        <Autocomplete
          key={key}
          disableCloseOnSelect
          multiple
          value={selectedTags[key as TagKey] || []}
          onChange={(event, newValue) => handleTagChange(key as TagKey, newValue)}
          onOpen={() => setEnabledTags((prev) => ({ ...prev, [key]: true }))}
          options={tagDataMapping[key as TagKey]?.tags?.tags || []}
          getOptionLabel={(option: Tag) => option.value}
          isOptionEqualToValue={(option: Tag, value) => option.tagId === value.tagId}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                size="small"
                label={option.value}
                {...getTagProps({ index })}
                className="!text-[12px]"
                variant="filled"
              />
            ))
          }
          renderInput={(params) => <TextField variant="standard" {...params} label={label} />}
        />
      ))}
    </Box>
  );
};
export default SearchSquadsByTags;
