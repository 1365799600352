import React from 'react';
import Box from '@mui/material/Box';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import Typography from '@mui/material/Typography';
import { ListOrgsRequest, Orgs } from '@proto/profiler/biz/v1/org_pb';
import Fade from '@mui/material/Fade';
import { Timestamp } from '@bufbuild/protobuf';
import ExploreOrgCard from '@pages/Orgs/ExploreOrgs/ExploreOrgCard';

export const ListUserOrgs = (): JSX.Element => {
  const {
    data: orgsList,
    error: orgsError,
    isLoading: orgsLoading,
  } = useListUserOrgs(
    new ListOrgsRequest({
      offset: Timestamp.now(),
    })
  );

  return (
    <Fade in>
      <Box display="flex" flexDirection="column" gap={2}>
        {orgsList?.orgs ? (
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography className="font-bold">Organizations</Typography>
            <Box display="flex" flexDirection="column" gap={1}>
              {(orgsList?.orgs as Orgs)?.orgs?.map((org, index) => {
                return (
                  <Box key={org.orgId?.value} display="flex" flexDirection="column" gap={0.5}>
                    <ExploreOrgCard org={org} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box>{orgsLoading ? 'Loading...' : 'No orgs found.'}</Box>
        )}
      </Box>
    </Fade>
  );
};
export default ListUserOrgs;
