import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import FiltersFeedItems from '@pages/Marketplace/Feed/FiltersFeedItems';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InterviewerButton from '@pages/Marketplace/Feed/FeedProfileSettings/InterviewerButton';
import FeedProfileTraitsForm from '@pages/Marketplace/Feed/FeedProfileSettings/FeedProfileTraitsForm';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { useFeed } from '../../../../zustand/feed';

const MobileFeedSettings = () => {
  const [open, setOpen] = React.useState(false);
  const { setIsPinnedReturn, isPinnedReturn } = useFeed();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ height: '100%' }} onClick={toggleDrawer(false)}>
      <Box className="bg-transparent  flex-shrink-0 h-full  ">
        <Box className="flex  bg-white/5 p-4 !backdrop-blur-3xl mb-2	rounded-xl flex-col">
          {/* <EventAvailableIcon/> */}
          <Box className="flex" gap={2}>
            <FiltersFeedItems />
            <Box className="flex items-center">
              <Typography lineHeight="16px" fontSize="13px">
                Show pinned
              </Typography>
              <Checkbox
                onChange={() => setIsPinnedReturn(!isPinnedReturn)}
                value={isPinnedReturn}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" className=" bg-white/5 p-4 rounded-xl" flexDirection="column" gap={2}>
          {/* <UserInvitesList /> */}
          <Box display="flex" flexDirection="column" gap={1}>
            <InterviewerButton />
            <FeedProfileTraitsForm />
            <ProfileTags />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className="md:hidden">
      <Button onClick={toggleDrawer(true)}>Feed settings</Button>
      <Drawer
        PaperProps={{
          sx: { height: '90vh' },
        }}
        className="!h-full"
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </Box>
  );
};

export default MobileFeedSettings;
