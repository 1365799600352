import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES, ROUTES_PREFIXES } from '@common/routes';
import { lazyComponent } from '@common/utils';
import { Login } from '@pages/Login/Login';
import Mfa from '@pages/Mfa/Mfa';
import UserInvitesList from '@components/Invites/UserInvitesList';
import Kanbanboard from '@pages/Marketplace/Dashboards/KanbanBoard';
import ExploreSquadsList from '@pages/Squads/ExploreSquads/ExploreSquadsList';
import ExploreOrgsList from '@pages/Orgs/ExploreOrgs/ExploreOrgsList';
import MainPage from '@pages/Main/MainPage/MainPage';
import SquadsListPage from '@pages/Squads/SquadsListPage';
import { MainDashboard } from '@pages/Main/Dashboard';
import FeedPage from '@pages/Marketplace/Feed/FeedPage';
import ContractPage from '@pages/Marketplace/Feed/ContractPage';
import ManageContractPage from '@pages/Marketplace/Dashboards/ManageContractPage';
import ManageInterviewPage from '@pages/Marketplace/Dashboards/ManageInterviewPage';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import Evaluation from '@pages/Evaluation/Evaluation';
import ReworkProfilePage from '@pages/ProfileSettings/ReworkProfilePage';
import DraggableList from '@pages/Product/BreakDowns/DnD';
import { Notifications } from '@pages/Notifications/Notifications';
import ManageRole from '@components/Roles/ManageRole';
import Order from '@pages/Order/Order';
import JoinByInvite from '@pages/JoinByInvite/JoinByInvite';
import ManageDashboardsList from '@pages/Marketplace/Dashboards/ManageDashboardsList';

import { useJWTStore } from '../../zustand/jwt';

const InviteAuth = lazyComponent('InviteAuth', import('@pages/InviteAuth/InviteAuth'));
const Onboarding = lazyComponent('Onboarding', import('@pages/Onboarding'));
const Org = lazyComponent('Org', import('@pages/Orgs/Org'));
const Product = lazyComponent('Product', import('@pages/Product/Product'));
const User = lazyComponent('User', import('@pages/User/User'));
const MarketplacePage = lazyComponent(
  'MarketplacePage',
  import('@pages/Marketplace/MarketplacePage')
);
const Home = lazyComponent('Home', import('@pages/Home'));
const Welcome = lazyComponent('Welcome', import('@pages/Welcome'));
const Legal = lazyComponent('Legal', import('@pages/Legal'));
const PrivacyPolicy = lazyComponent('PrivacyPolicy', import('@pages/Legal/PrivacyPolicy'));
const CookiePolicy = lazyComponent('CookiePolicy', import('@pages/Legal/CookiePolicy'));
const TermsOfService = lazyComponent('TermsOfService', import('@pages/Legal/TermsOfService'));
const PageNotFound = lazyComponent('PageNotFound', import('@pages/PageNotFound/PageNotFound'));
const Squad = lazyComponent('Squad', import('@pages/Squads/Squad'));
const ManageSquad = lazyComponent('ManageSquad', import('@pages/Squads/ManageSquads/ManageSquad'));
const ManageOrg = lazyComponent('ManageOrg', import('@pages/Orgs/ManageOrg'));

const InterviewPage = lazyComponent(
  'InterviewPage',
  import('@pages/Marketplace/Feed/InterviewPage')
);

export const Router = (): JSX.Element => {
  const { tokens } = useJWTStore();

  return (
    <Suspense fallback={<LoaderWithDelay />}>
      <Routes>
        <Route path={ROUTES.INVITE_LOGIN} element={<InviteAuth />} />
        {tokens ? (
          <>
            <Route path="/" element={<MainDashboard />}>
              <Route index element={<MainPage />} />
              <Route path={`${ROUTES.FEED_LIST}`} element={<FeedPage />} />
              <Route path={`${ROUTES.PROFILE_SETTING}`} element={<ReworkProfilePage />} />
              <Route path={`${ROUTES.SQUADS_LIST}`} element={<SquadsListPage />}>
                <Route index element={<ExploreSquadsList />} />
              </Route>
              <Route path={`${ROUTES.ORGS_LIST}`} element={<ExploreOrgsList />} />
              <Route path={`${ROUTES.SQUAD}`} element={<Squad />} />
              <Route path={`${ROUTES.MANAGE_SQUAD}`} element={<ManageSquad />} />
              <Route path={`${ROUTES.ORG}`} element={<Org />} />
              <Route path={`${ROUTES.MANAGE_ORG}`} element={<ManageOrg />} />
              <Route path={`${ROUTES._DASHBOARD}`} element={<Kanbanboard />} />
              <Route path={`${ROUTES.MFA}`} element={<Mfa />} />
              <Route path={`${ROUTES.USER_INVITES}`} element={<UserInvitesList />} />
              <Route path={`${ROUTES.PROFILE_SETTINGS_ONBOARDING}`} element={<Onboarding />} />
              <Route path={`${ROUTES.INTERVIEW}`} element={<InterviewPage />} />
              <Route path={`${ROUTES.PRODUCT}`} element={<Product />} />
              <Route path={`${ROUTES.EDIT_BREAKDOWN}`} element={<DraggableList />} />
              <Route path={`${ROUTES.CONTRACT}`} element={<ContractPage />} />
              <Route path={`${ROUTES.MANAGE_CONTRACT}`} element={<ManageContractPage />} />
              <Route path={`${ROUTES.MANAGE_INTERVIEW}`} element={<ManageInterviewPage />} />
              <Route path={`${ROUTES.USER}`} element={<User />} />
              <Route path={`${ROUTES.MARKETPLACE}`} element={<MarketplacePage />} />
              <Route path={`${ROUTES.EVALUATION}`} element={<Evaluation />} />
              <Route path={`${ROUTES.NOTIFICATIONS}`} element={<Notifications />} />
              <Route path={`${ROUTES.MANAGE_ROLE}`} element={<ManageRole />} />
              <Route path={`${ROUTES.ORDER}`} element={<Order />} />
              <Route path={`${ROUTES.MANAGE_DASHBOARDS}`} element={<ManageDashboardsList />} />

              {/* Інші маршрути для авторизованих користувачів */}
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/404" element={<PageNotFound />} />
          </>
        )}

        <Route path={`${ROUTES.JOIN_BY_INVITE}`} element={<JoinByInvite />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />

        <Route path={ROUTES_PREFIXES.LEGAL} element={<Legal />}>
          <Route path={ROUTES.LEGAL_PRIVACY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.LEGAL_TERMS} element={<TermsOfService />} />
          <Route path={ROUTES.LEGAL_COOKIE} element={<CookiePolicy />} />
        </Route>
        <Route path={ROUTES.WELCOME} element={<Welcome />} />
        <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
        <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} replace />} />
      </Routes>
    </Suspense>
  );
};
