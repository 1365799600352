import { useListProgress } from '@services/queries/RewarderQueries';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import AchievementBox from '@pages/Main/MainPage/AchievementBox';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';

const ListUserAchievements = () => {
  const { data: userProfile } = useGetMyProfile();
  const { data: progressData } = useListProgress({
    ids: new UUIDS({
      values: [userProfile?.profile?.profileId?.value || ''],
    }),
    enabled: !!userProfile?.profile?.profileId?.value,
  });

  return (
    <Box>
      {progressData?.progress &&
        Object.values(progressData?.progress)?.map((progress) => {
          return (
            <Box>
              <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold" mb={1}>
                Achievements
              </Typography>
              <Box display="flex" gap={1}>
                {progress?.progress?.map((ach) => {
                  return (
                    <Box>
                      <AchievementBox achieved={ach} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default ListUserAchievements;
