import { Page } from '@components/Page/Page';
import React from 'react';
import SupplyList from '@pages/Marketplace/Dashboards/SupplyList';
import { DemandsList } from '@pages/Marketplace/Dashboards/DemandsList';
import { Chip, CircularProgress, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import UpdateDashboardInfoForm from '@pages/Marketplace/ManageDemands/UpdateDashboardInfoForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDashboard } from '@services/queries/MarketplaceQueries';
import { useDialog } from '@components/hooks/useDialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { isUUID } from '@utils/isUUID';
import { replaceDashesWithSpaces, replaceSpacesWithDashes } from '@utils/parseStrings';
import DemandChart from './DemandCharts';

const KanbanBoard = () => {
  const navigate = useNavigate();
  const { dashboardId } = useParams();
  const { data, isLoading, error } = useGetDashboard({
    case: isUUID(dashboardId as string) ? 'dashboardId' : 'title',
    value: isUUID(dashboardId as string)
      ? (dashboardId as string)
      : replaceDashesWithSpaces(dashboardId as string),
  });

  const [display, setDisplay] = React.useState<'demandsList' | 'supplyList'>('demandsList');
  const { isOpen, handleOpen, handleClose } = useDialog();
  if (error?.message?.includes('NotFound') || error?.message?.includes('InvalidArgument')) {
    navigate('/404');
  }

  const displayContent = {
    demandsList: <DemandsList demands={data?.demands} />,
    supplyList: <SupplyList />,
  };

  return (
    <Page description="Kanbanboard" title="Kanbanboard">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="subtitle1" flex="grow">
            {data?.kanbanBoard?.title || 'No title'}
          </Typography>
          <Box className="flex gap-4 items-center">
            <Box className="flex items-center" gap={1}>
              <Chip
                size="small"
                variant={display === 'demandsList' ? 'outlined' : 'filled'}
                onClick={() => setDisplay('demandsList')}
                label="Demands"
              />
              <Chip
                size="small"
                variant={display === 'supplyList' ? 'outlined' : 'filled'}
                onClick={() => setDisplay('supplyList')}
                label="Supplies"
              />
            </Box>

            <Tooltip onClick={handleOpen} placement="top-start" title="Edit dashboard title">
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
      {data?.kanbanBoard && (
        <UpdateDashboardInfoForm
          handleClose={handleClose}
          isOpen={isOpen}
          dashboard={data.kanbanBoard}
        />
      )}
      <Box className="flex justify-center">
        {data?.notConvertedDemands && <DemandChart demands={data?.notConvertedDemands} />}
      </Box>

      {displayContent[display]}
    </Page>
  );
};
export default KanbanBoard;
