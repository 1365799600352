import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Tooltip, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@components/Card/Card';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { useListInvites, useRejectInvite } from '@services/queries/ProfilesQueries';
import {
  ListInvitesResponse_Direct,
  RejectInviteRequest,
} from '@proto/profiler/invite/v1/invite_pb';

const WaitConfirmationInvites = () => {
  const { demandId } = useParams();
  const { mutate: closeGroupingInvite } = useRejectInvite();
  const { data: invitesList } = useListInvites({
    id: demandId as string,
    type: Entity_Type.INTERVIEW,
  });
  console.log({ invitesList });
  return invitesList ? (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" gap={1}>
        {invitesList?.invites?.map((inviteItem) => {
          return (
            <Card key={(inviteItem?.invite?.value as ListInvitesResponse_Direct)?.inviteId?.value}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" gap={1}>
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: 'rgb(255, 255, 255, 0.2)',
                        color: 'white',
                      }}
                      alt={
                        (
                          inviteItem?.invite?.value as ListInvitesResponse_Direct
                        )?.name?.nickName.toUpperCase() || 'User'
                      }
                      src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${
                        (inviteItem?.invite?.value as ListInvitesResponse_Direct)?.profileId
                      }/${(inviteItem?.invite?.value as ListInvitesResponse_Direct)?.profileId}`}
                    />
                    <Box>
                      <Typography color="secondary" textTransform="capitalize" variant="subtitle2">
                        {(inviteItem?.invite?.value as ListInvitesResponse_Direct)?.name?.firstName}{' '}
                        {(inviteItem?.invite?.value as ListInvitesResponse_Direct)?.name?.lastName}
                      </Typography>
                      <Typography fontWeight="bold">
                        @{(inviteItem?.invite?.value as ListInvitesResponse_Direct)?.name?.nickName}
                      </Typography>
                    </Box>
                  </Box>
                  <Tooltip
                    placement="left"
                    onClick={async () =>
                      closeGroupingInvite(
                        new RejectInviteRequest({
                          inviteId: (inviteItem?.invite?.value as ListInvitesResponse_Direct)
                            ?.inviteId,
                        })
                      )
                    }
                    title="Cancel invite"
                  >
                    <IconButton>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* {inviteItem?.traits?.experience && ( */}
                {/*    <Tooltip placement="right" title="years"> */}
                {/*        <Box display="flex" gap={1} alignItems='center'> */}
                {/*            <InfoIcon fontSize="small"/> */}
                {/*            <Typography>Experience: {inviteItem.traits.experience}+ </Typography> */}
                {/*        </Box> */}
                {/*    </Tooltip> */}
                {/* )} */}
                {/* {inviteItem?.traits?.capacity && ( */}
                {/*    <Tooltip placement="right" title="hours per week"> */}
                {/*        <Box display="flex" gap={1} alignItems='center'> */}
                {/*            <InfoIcon fontSize="small"/> */}
                {/*            <Typography>Capacity: {inviteItem.traits.capacity} </Typography> */}
                {/*        </Box> */}
                {/*    </Tooltip> */}
                {/* )} */}
                {/* {inviteItem?.traits?.rate && ( */}
                {/*    <Typography>Rate: ${inviteItem.traits.rate}</Typography> */}
                {/* )} */}
                {/* <Box display="flex" flexWrap="wrap" gap={1}> */}
                {/*    {inviteItem?.tags?.tags && */}
                {/*        inviteItem.tags?.tags.map((tag) => { */}
                {/*            return ( */}
                {/*                <Chip */}
                {/*                    key={tag.value} */}
                {/*                    label={tag.value} */}
                {/*                    variant="outlined" */}
                {/*                /> */}
                {/*            ); */}
                {/*        })} */}
                {/* </Box> */}
              </Box>
            </Card>
          );
        })}
      </Box>
    </Box>
  ) : (
    <Typography>No candidates</Typography>
  );
};

export default WaitConfirmationInvites;
