// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/order.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { RWX } from '../../../iam/auth/v1/iam_pb.js';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';
import { Demands } from '../../demand/v1/demand_pb.js';

/**
 * @generated from enum marketplace.product.v1.ProductOrderStatus
 */
export enum ProductOrderStatus {
  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_OFFER_DRAFT = 2;
   */
  OFFER_DRAFT = 2,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_OFFER_SENT = 3;
   */
  OFFER_SENT = 3,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_IN_PROGRESS = 4;
   */
  IN_PROGRESS = 4,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_COMPLETED = 5;
   */
  COMPLETED = 5,

  /**
   * @generated from enum value: PRODUCT_ORDER_STATUS_CANCELLED = 6;
   */
  CANCELLED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductOrderStatus)
proto3.util.setEnumType(ProductOrderStatus, 'marketplace.product.v1.ProductOrderStatus', [
  { no: 0, name: 'PRODUCT_ORDER_STATUS_UNSPECIFIED' },
  { no: 1, name: 'PRODUCT_ORDER_STATUS_PENDING' },
  { no: 2, name: 'PRODUCT_ORDER_STATUS_OFFER_DRAFT' },
  { no: 3, name: 'PRODUCT_ORDER_STATUS_OFFER_SENT' },
  { no: 4, name: 'PRODUCT_ORDER_STATUS_IN_PROGRESS' },
  { no: 5, name: 'PRODUCT_ORDER_STATUS_COMPLETED' },
  { no: 6, name: 'PRODUCT_ORDER_STATUS_CANCELLED' },
]);

/**
 * @generated from message marketplace.product.v1.ProductOrder
 */
export class ProductOrder extends Message<ProductOrder> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID product_id = 2;
   */
  productId?: UUID;

  /**
   * @generated from field: string product_title = 3;
   */
  productTitle = '';

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: marketplace.product.v1.ProductOrderStatus status = 5;
   */
  status = ProductOrderStatus.UNSPECIFIED;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 6;
   */
  rwx?: RWX;

  /**
   * @generated from field: grpc.type.v1.Entity customer = 7;
   */
  customer?: Entity;

  constructor(data?: PartialMessage<ProductOrder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ProductOrder';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
    { no: 2, name: 'product_id', kind: 'message', T: UUID },
    { no: 3, name: 'product_title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'created', kind: 'message', T: Timestamp },
    { no: 5, name: 'status', kind: 'enum', T: proto3.getEnumType(ProductOrderStatus) },
    { no: 6, name: 'rwx', kind: 'message', T: RWX },
    { no: 7, name: 'customer', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductOrder {
    return new ProductOrder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductOrder {
    return new ProductOrder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductOrder {
    return new ProductOrder().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProductOrder | PlainMessage<ProductOrder> | undefined,
    b: ProductOrder | PlainMessage<ProductOrder> | undefined
  ): boolean {
    return proto3.util.equals(ProductOrder, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.OrderProductRequest
 */
export class OrderProductRequest extends Message<OrderProductRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  constructor(data?: PartialMessage<OrderProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.OrderProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderProductRequest {
    return new OrderProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderProductRequest {
    return new OrderProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OrderProductRequest {
    return new OrderProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: OrderProductRequest | PlainMessage<OrderProductRequest> | undefined,
    b: OrderProductRequest | PlainMessage<OrderProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(OrderProductRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.OrderProductResponse
 */
export class OrderProductResponse extends Message<OrderProductResponse> {
  constructor(data?: PartialMessage<OrderProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.OrderProductResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderProductResponse {
    return new OrderProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderProductResponse {
    return new OrderProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OrderProductResponse {
    return new OrderProductResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: OrderProductResponse | PlainMessage<OrderProductResponse> | undefined,
    b: OrderProductResponse | PlainMessage<OrderProductResponse> | undefined
  ): boolean {
    return proto3.util.equals(OrderProductResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductOrderRequest
 */
export class GetProductOrderRequest extends Message<GetProductOrderRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<GetProductOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductOrderRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetProductOrderRequest {
    return new GetProductOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderRequest {
    return new GetProductOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderRequest {
    return new GetProductOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductOrderRequest | PlainMessage<GetProductOrderRequest> | undefined,
    b: GetProductOrderRequest | PlainMessage<GetProductOrderRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProductOrderRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductOrderResponse
 */
export class GetProductOrderResponse extends Message<GetProductOrderResponse> {
  /**
   * @generated from field: marketplace.product.v1.ProductOrder order = 1;
   */
  order?: ProductOrder;

  /**
   * @generated from oneof marketplace.product.v1.GetProductOrderResponse.data
   */
  data:
    | {
        /**
         * @generated from field: marketplace.product.v1.GetProductOrderResponse.Draft draft = 2;
         */
        value: GetProductOrderResponse_Draft;
        case: 'draft';
      }
    | {
        /**
         * @generated from field: marketplace.demand.v1.Demands demands = 3;
         */
        value: Demands;
        case: 'demands';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetProductOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductOrderResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order', kind: 'message', T: ProductOrder },
    { no: 2, name: 'draft', kind: 'message', T: GetProductOrderResponse_Draft, oneof: 'data' },
    { no: 3, name: 'demands', kind: 'message', T: Demands, oneof: 'data' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetProductOrderResponse {
    return new GetProductOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse {
    return new GetProductOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse {
    return new GetProductOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductOrderResponse | PlainMessage<GetProductOrderResponse> | undefined,
    b: GetProductOrderResponse | PlainMessage<GetProductOrderResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetProductOrderResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductOrderResponse.Role
 */
export class GetProductOrderResponse_Role extends Message<GetProductOrderResponse_Role> {
  /**
   * @generated from field: int32 role_id = 1;
   */
  roleId = 0;

  /**
   * @generated from field: grpc.type.v1.UUIDS profile_ids = 2;
   */
  profileIds?: UUIDS;

  /**
   * @generated from field: int64 fixed = 3;
   */
  fixed = protoInt64.zero;

  /**
   * @generated from field: int64 rate = 4;
   */
  rate = protoInt64.zero;

  constructor(data?: PartialMessage<GetProductOrderResponse_Role>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductOrderResponse.Role';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'profile_ids', kind: 'message', T: UUIDS },
    { no: 3, name: 'fixed', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: 'rate', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetProductOrderResponse_Role {
    return new GetProductOrderResponse_Role().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse_Role {
    return new GetProductOrderResponse_Role().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse_Role {
    return new GetProductOrderResponse_Role().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductOrderResponse_Role | PlainMessage<GetProductOrderResponse_Role> | undefined,
    b: GetProductOrderResponse_Role | PlainMessage<GetProductOrderResponse_Role> | undefined
  ): boolean {
    return proto3.util.equals(GetProductOrderResponse_Role, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductOrderResponse.Draft
 */
export class GetProductOrderResponse_Draft extends Message<GetProductOrderResponse_Draft> {
  /**
   * @generated from field: repeated marketplace.product.v1.GetProductOrderResponse.Role roles = 1;
   */
  roles: GetProductOrderResponse_Role[] = [];

  constructor(data?: PartialMessage<GetProductOrderResponse_Draft>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductOrderResponse.Draft';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'roles', kind: 'message', T: GetProductOrderResponse_Role, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetProductOrderResponse_Draft {
    return new GetProductOrderResponse_Draft().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse_Draft {
    return new GetProductOrderResponse_Draft().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductOrderResponse_Draft {
    return new GetProductOrderResponse_Draft().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductOrderResponse_Draft | PlainMessage<GetProductOrderResponse_Draft> | undefined,
    b: GetProductOrderResponse_Draft | PlainMessage<GetProductOrderResponse_Draft> | undefined
  ): boolean {
    return proto3.util.equals(GetProductOrderResponse_Draft, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListProductOrdersRequest
 */
export class ListProductOrdersRequest extends Message<ListProductOrdersRequest> {
  /**
   * @generated from field: marketplace.product.v1.ProductOrderStatus status = 1;
   */
  status = ProductOrderStatus.UNSPECIFIED;

  /**
   * @generated from oneof marketplace.product.v1.ListProductOrdersRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID product_id = 2;
         */
        value: UUID;
        case: 'productId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUID customer_id = 3;
         */
        value: UUID;
        case: 'customerId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListProductOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListProductOrdersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'status', kind: 'enum', T: proto3.getEnumType(ProductOrderStatus) },
    { no: 2, name: 'product_id', kind: 'message', T: UUID, oneof: 'by' },
    { no: 3, name: 'customer_id', kind: 'message', T: UUID, oneof: 'by' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProductOrdersRequest {
    return new ListProductOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersRequest {
    return new ListProductOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersRequest {
    return new ListProductOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductOrdersRequest | PlainMessage<ListProductOrdersRequest> | undefined,
    b: ListProductOrdersRequest | PlainMessage<ListProductOrdersRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProductOrdersRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListProductOrdersResponse
 */
export class ListProductOrdersResponse extends Message<ListProductOrdersResponse> {
  /**
   * @generated from field: repeated marketplace.product.v1.ProductOrder orders = 1;
   */
  orders: ProductOrder[] = [];

  constructor(data?: PartialMessage<ListProductOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListProductOrdersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'orders', kind: 'message', T: ProductOrder, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProductOrdersResponse {
    return new ListProductOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersResponse {
    return new ListProductOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductOrdersResponse {
    return new ListProductOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductOrdersResponse | PlainMessage<ListProductOrdersResponse> | undefined,
    b: ListProductOrdersResponse | PlainMessage<ListProductOrdersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProductOrdersResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderRequest
 */
export class CreateProductOrderRequest extends Message<CreateProductOrderRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID product_id = 1;
   */
  productId?: UUID;

  /**
   * @generated from field: grpc.type.v1.Entity customer = 2;
   */
  customer?: Entity;

  constructor(data?: PartialMessage<CreateProductOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'message', T: UUID },
    { no: 2, name: 'customer', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderRequest {
    return new CreateProductOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderRequest {
    return new CreateProductOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderRequest {
    return new CreateProductOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductOrderRequest | PlainMessage<CreateProductOrderRequest> | undefined,
    b: CreateProductOrderRequest | PlainMessage<CreateProductOrderRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderResponse
 */
export class CreateProductOrderResponse extends Message<CreateProductOrderResponse> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  constructor(data?: PartialMessage<CreateProductOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderResponse {
    return new CreateProductOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderResponse {
    return new CreateProductOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderResponse {
    return new CreateProductOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductOrderResponse | PlainMessage<CreateProductOrderResponse> | undefined,
    b: CreateProductOrderResponse | PlainMessage<CreateProductOrderResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CancelProductOrderRequest
 */
export class CancelProductOrderRequest extends Message<CancelProductOrderRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  constructor(data?: PartialMessage<CancelProductOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CancelProductOrderRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CancelProductOrderRequest {
    return new CancelProductOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CancelProductOrderRequest {
    return new CancelProductOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CancelProductOrderRequest {
    return new CancelProductOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CancelProductOrderRequest | PlainMessage<CancelProductOrderRequest> | undefined,
    b: CancelProductOrderRequest | PlainMessage<CancelProductOrderRequest> | undefined
  ): boolean {
    return proto3.util.equals(CancelProductOrderRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CancelProductOrderResponse
 */
export class CancelProductOrderResponse extends Message<CancelProductOrderResponse> {
  constructor(data?: PartialMessage<CancelProductOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CancelProductOrderResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CancelProductOrderResponse {
    return new CancelProductOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CancelProductOrderResponse {
    return new CancelProductOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CancelProductOrderResponse {
    return new CancelProductOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CancelProductOrderResponse | PlainMessage<CancelProductOrderResponse> | undefined,
    b: CancelProductOrderResponse | PlainMessage<CancelProductOrderResponse> | undefined
  ): boolean {
    return proto3.util.equals(CancelProductOrderResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateProductOrderOfferDraftRequest
 */
export class UpdateProductOrderOfferDraftRequest extends Message<UpdateProductOrderOfferDraftRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  /**
   * @generated from field: int32 role_id = 2;
   */
  roleId = 0;

  /**
   * @generated from field: int64 fixed_price = 3;
   */
  fixedPrice = protoInt64.zero;

  /**
   * @generated from field: grpc.type.v1.UUIDS assignees = 4;
   */
  assignees?: UUIDS;

  constructor(data?: PartialMessage<UpdateProductOrderOfferDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateProductOrderOfferDraftRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
    { no: 2, name: 'role_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'fixed_price', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: 'assignees', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProductOrderOfferDraftRequest {
    return new UpdateProductOrderOfferDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProductOrderOfferDraftRequest {
    return new UpdateProductOrderOfferDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProductOrderOfferDraftRequest {
    return new UpdateProductOrderOfferDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateProductOrderOfferDraftRequest
      | PlainMessage<UpdateProductOrderOfferDraftRequest>
      | undefined,
    b:
      | UpdateProductOrderOfferDraftRequest
      | PlainMessage<UpdateProductOrderOfferDraftRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateProductOrderOfferDraftRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateProductOrderOfferDraftResponse
 */
export class UpdateProductOrderOfferDraftResponse extends Message<UpdateProductOrderOfferDraftResponse> {
  /**
   * @generated from field: int64 rate = 1;
   */
  rate = protoInt64.zero;

  constructor(data?: PartialMessage<UpdateProductOrderOfferDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateProductOrderOfferDraftResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rate', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProductOrderOfferDraftResponse {
    return new UpdateProductOrderOfferDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProductOrderOfferDraftResponse {
    return new UpdateProductOrderOfferDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProductOrderOfferDraftResponse {
    return new UpdateProductOrderOfferDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateProductOrderOfferDraftResponse
      | PlainMessage<UpdateProductOrderOfferDraftResponse>
      | undefined,
    b:
      | UpdateProductOrderOfferDraftResponse
      | PlainMessage<UpdateProductOrderOfferDraftResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateProductOrderOfferDraftResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderOfferRequest
 */
export class CreateProductOrderOfferRequest extends Message<CreateProductOrderOfferRequest> {
  /**
   * @generated from field: marketplace.product.v1.CreateProductOrderOfferRequest.Payload payload = 1;
   */
  payload?: CreateProductOrderOfferRequest_Payload;

  /**
   * @generated from field: iam.auth.v1.RWX rwx = 2;
   */
  rwx?: RWX;

  constructor(data?: PartialMessage<CreateProductOrderOfferRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderOfferRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'payload', kind: 'message', T: CreateProductOrderOfferRequest_Payload },
    { no: 2, name: 'rwx', kind: 'message', T: RWX },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderOfferRequest {
    return new CreateProductOrderOfferRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderOfferRequest {
    return new CreateProductOrderOfferRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderOfferRequest {
    return new CreateProductOrderOfferRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductOrderOfferRequest | PlainMessage<CreateProductOrderOfferRequest> | undefined,
    b: CreateProductOrderOfferRequest | PlainMessage<CreateProductOrderOfferRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderOfferRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderOfferRequest.Payload
 */
export class CreateProductOrderOfferRequest_Payload extends Message<CreateProductOrderOfferRequest_Payload> {
  /**
   * @generated from field: grpc.type.v1.UUID order_id = 1;
   */
  orderId?: UUID;

  /**
   * @generated from field: bool create_dynamic_squad = 2;
   */
  createDynamicSquad = false;

  constructor(data?: PartialMessage<CreateProductOrderOfferRequest_Payload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderOfferRequest.Payload';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'order_id', kind: 'message', T: UUID },
    { no: 2, name: 'create_dynamic_squad', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderOfferRequest_Payload {
    return new CreateProductOrderOfferRequest_Payload().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderOfferRequest_Payload {
    return new CreateProductOrderOfferRequest_Payload().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderOfferRequest_Payload {
    return new CreateProductOrderOfferRequest_Payload().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateProductOrderOfferRequest_Payload
      | PlainMessage<CreateProductOrderOfferRequest_Payload>
      | undefined,
    b:
      | CreateProductOrderOfferRequest_Payload
      | PlainMessage<CreateProductOrderOfferRequest_Payload>
      | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderOfferRequest_Payload, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductOrderOfferResponse
 */
export class CreateProductOrderOfferResponse extends Message<CreateProductOrderOfferResponse> {
  constructor(data?: PartialMessage<CreateProductOrderOfferResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductOrderOfferResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductOrderOfferResponse {
    return new CreateProductOrderOfferResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderOfferResponse {
    return new CreateProductOrderOfferResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductOrderOfferResponse {
    return new CreateProductOrderOfferResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductOrderOfferResponse | PlainMessage<CreateProductOrderOfferResponse> | undefined,
    b: CreateProductOrderOfferResponse | PlainMessage<CreateProductOrderOfferResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductOrderOfferResponse, a, b);
  }
}
