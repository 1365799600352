import { create } from 'zustand/react';

interface DashboardState {
  hoveredDemandChartIds: string[];
  setHoveredDemandChartIds: (hoveredDemandChartIds: string[]) => void;
}

export const useDashboard = create<DashboardState>((set, get) => ({
  hoveredDemandChartIds: [],
  setHoveredDemandChartIds: (hoveredDemandChartIds) => set({ hoveredDemandChartIds }),
}));
