import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CreateContractForm from '@pages/Marketplace/Dashboards/CreateContracForm';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CreateInterviewForm from '@pages/Marketplace/Dashboards/CreateInterviewForm';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArticleIcon from '@mui/icons-material/Article';

type CreateDemandFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  dashboardId: string;
};

const CreateDemandForm = (props: CreateDemandFormProps) => {
  const { isOpen, handleClose, dashboardId } = props;
  const [formType, setFormType] = useState('');

  return (
    <Dialog
      onClose={() => {
        handleClose();
        setFormType('');
      }}
      open={isOpen}
    >
      <Box className="max-w-[1240px] w-full" p={3} display="flex" flexDirection="column" gap={2}>
        {!formType && (
          <Box display="flex" gap={2} flexDirection="column">
            <Box display="flex" gap={1}>
              <ArticleIcon />
              <Typography variant="subtitle1">Choose Your Collaboration Type</Typography>
            </Box>
            <Typography variant="subtitle2">
              Where you can efficiently manage your work projects. Choose one of two collaboration
              options:
            </Typography>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              <Typography textAlign="center">
                Assess the professionalism and competence of a potential candidate before signing a
                contract. Find the perfect specialist for your project by conducting a thorough
                evaluation of their skills.
              </Typography>
              <Box>
                <Button variant="outlined" onClick={() => setFormType('interview')}>
                  <Typography>Interview</Typography>
                </Button>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              <Typography textAlign="center">
                Ensure the completion of your project by signing a contract with a qualified
                contractor. Guarantee successful task completion with a detailed description of
                requirements and terms of cooperation.
              </Typography>
              <Box>
                <Button variant="outlined" onClick={() => setFormType('contract')}>
                  <Typography>Contract</Typography>
                </Button>
              </Box>
            </Box>
            <Typography variant="subtitle2">
              Select the appropriate option and ensure the successful execution of your work
              projects!
            </Typography>
          </Box>
        )}

        {formType && (
          <Box
            sx={{ cursor: 'pointer' }}
            display="flex"
            alignItems="center"
            gap={1}
            onClick={() => setFormType('')}
          >
            <ArrowBackIosNewIcon /> Back
          </Box>
        )}

        {formType === 'contract' && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography textAlign="center" variant="subtitle1">
              Create New Contract
            </Typography>
            <Typography>
              Here, you can create detailed contracts to formalize agreements with freelancers or
              organizations. Our platform makes it easy to outline all necessary details, ensuring
              clear expectations and smooth collaboration.
            </Typography>
            <CreateContractForm dashboardId={dashboardId} handleCloseModal={handleClose} />
          </Box>
        )}

        {formType === 'interview' && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography textAlign="center" variant="subtitle1">
              Create New Interview
            </Typography>
            <Typography>
              Here, you can schedule interviews to evaluate potential candidates and assess their
              skills before signing a contract. Our platform ensures a seamless interview process,
              enabling you to find the best fit for your needs.
            </Typography>
            <CreateInterviewForm dashboardId={dashboardId} handleCloseModal={handleClose} />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default CreateDemandForm;
