import React, { useEffect, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Box from '@mui/material/Box';
import {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import { createEventId } from '@pages/Evaluation/event-utils';
import {
  useAddEvaluationScheduleSlots,
  useDeleteEvaluationScheduleSlot,
  useGetInterviewerrAvailability,
  useListEvaluations,
} from '@services/queries/MarketplaceQueries';
import {
  convertDateToTimeStamp,
  createOccupiedEvents,
  selectAllow,
} from '@pages/Evaluation/EvaluationSchedulesCalendar/config';
import { Timestamp } from '@bufbuild/protobuf';
import { DeleteEvaluationScheduleSlotRequest } from '@proto/marketplace/demand/v1/evaluation_pb';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import Dialog from '@mui/material/Dialog';
import { useRefetch } from '../../../zustand/refetch';

type CalendarComponentProps = {
  isOpen: boolean;
  handleClose: () => void;
  afterCloseCallback?: () => void;
};

export const convertTimestampToDate = (timestamp: Timestamp | undefined) => {
  if (!timestamp) return new Date();
  const milliseconds = Number(timestamp.seconds) * 1000 + Math.floor(timestamp.nanos / 1000000);
  return new Date(milliseconds);
};

const CalendarComponentForInterviewer = (props: CalendarComponentProps) => {
  const { handleClose, isOpen, afterCloseCallback } = props;
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const { mutate: addEvaluationSlots } = useAddEvaluationScheduleSlots();
  const { mutate: deleteEvaluationSlot } = useDeleteEvaluationScheduleSlot();
  const { data: profile } = useGetMyProfile();
  const { isFetchedListEvaluation, setIsFetchedListEvaluation } = useRefetch();
  const { data: slots } = useGetInterviewerrAvailability({
    case: 'interviewerId',
    value: profile?.profile?.profileId as UUID,
    isEnabled: isOpen,
  });

  const { data, isFetched } = useListEvaluations({
    enabled: isFetchedListEvaluation && isOpen && !!slots?.slots?.length,
  });

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();

    const isConflict = currentEvents.some(
      (event) =>
        new Date(event.start as Date).getTime() <= selectInfo.start.getTime() &&
        new Date(event.end as Date).getTime() > selectInfo.start.getTime()
    );

    if (isConflict) {
      return;
    }

    calendarApi.addEvent({
      id: createEventId(),
      start: selectInfo.start,
      end: selectInfo.end,
      title: 'Convenient time for you',
      color: '#1769aa',
      extendedProps: {
        occupedSlots: true,
      },
    });

    addEvaluationSlots({
      from: convertDateToTimeStamp(selectInfo.start.toISOString()),
      to: convertDateToTimeStamp(selectInfo.end.toISOString()),
    });
  };
  const initial = slots?.slots?.map((slot) => {
    return {
      id: createEventId(),
      start: convertTimestampToDate(slot.from),
      end: convertTimestampToDate(slot.to),
      color: '#1769aa',
    };
  });
  const occupiedSlots = createOccupiedEvents(data?.evaluations || []);
  const initialEvents = initial && [
    ...(occupiedSlots as EventInput[]),
    ...(initial as EventInput[]),
  ];

  const handleEventClick = (clickInfo: EventClickArg) => {
    const { event } = clickInfo;

    if (event.extendedProps?.occuped) return;

    deleteEvaluationSlot(
      new DeleteEvaluationScheduleSlotRequest({
        from: convertDateToTimeStamp(event?.start?.toISOString() as string),
      })
    );
    clickInfo.event.remove();
  };

  const renderEventContent = (eventContent: EventContentArg) => (
    <>
      {eventContent.timeText} - {eventContent.event.title}
    </>
  );

  const handleModalClose = () => {
    if (afterCloseCallback && slots?.slots?.length) afterCloseCallback();
    handleClose();
  };

  useEffect(() => {
    if (isFetched) {
      setIsFetchedListEvaluation(false);
    }
  }, [isFetched, setIsFetchedListEvaluation]);

  return (
    <Dialog onClose={handleModalClose} open={isOpen}>
      <Box width="1200px" minHeight="70vh" sx={{ background: '#04020f' }} px={6} py={4}>
        {initialEvents && (
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialDate={new Date()}
            initialView="customWeek"
            views={{
              customWeek: {
                type: 'timeGrid',
                duration: { days: 7 },
              },
            }}
            headerToolbar={{
              left: 'prev,next today',
              center: '',
              right: 'title',
            }}
            height={640}
            weekends
            allDaySlot={false}
            initialEvents={initialEvents}
            select={handleDateSelect}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            eventsSet={setCurrentEvents}
            selectAllow={selectAllow}
            scrollTimeReset={false}
            selectable
          />
        )}
      </Box>
    </Dialog>
  );
};

export default CalendarComponentForInterviewer;
