import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ListSquadsRequest, Squads } from '@proto/profiler/biz/v1/squad_pb';
import React from 'react';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { Timestamp } from '@bufbuild/protobuf';
import Fade from '@mui/material/Fade';
import ExploreSquadCard from '@pages/Squads/ExploreSquads/ExploreSquadCard';

const ListUserSquads = () => {
  const {
    data: squadsList,
    error: squadsError,
    isLoading: squadsLoading,
  } = useListUserSquads(
    new ListSquadsRequest({
      offset: Timestamp.now(),
    })
  );

  return (
    <Fade in>
      <Box display="flex" flexDirection="column" gap={2}>
        {squadsList?.squads ? (
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography className="font-bold">Squads</Typography>

            <Box display="flex" flexDirection="column" gap={1}>
              {(squadsList?.squads as Squads)?.squads?.map((squad, index) => {
                return (
                  <Box key={squad.squadId?.value} display="flex" flexDirection="column" gap={0.5}>
                    <ExploreSquadCard squad={squad} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box>{squadsLoading ? 'Loading...' : 'No squads found.'}</Box>
        )}
      </Box>
    </Fade>
  );
};

export default ListUserSquads;
