import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Page } from '@components/Page/Page';
import { ProfileNameForm } from '@pages/ProfileSettings/ProfileNameForm/ProfileNameForm';
import Divider from '@mui/material/Divider';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { ProfileTraitsForm } from '@pages/ProfileSettings/ProfileTraitsForm/ProfileTraitsForm';
import Avatars from '@components/Avatars/Avatars';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import MfaButton from '@pages/ProfileSettings/MfaDialog/MfaButton';
import TabContainer, { TabItem } from '@components/TabContainer/TabContainer';

const VerticalTabs = () => {
  const { data: userProfile } = useGetMyProfile();

  const tabs: TabItem[] = [
    {
      label: 'Personal info',
      content: (
        <Box className="flex flex-col gap-6">
          <Box className="flex flex-col gap-2">
            <Typography fontSize="24px" className="font-bold">
              Profile
            </Typography>
            <Typography>This is how others will see you on the site.</Typography>
          </Box>
          <Divider />
          <Box sx={{ height: 'auto', overflow: 'auto', width: '100%' }}>
            <Avatars
              link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${userProfile?.profile?.profileId?.value}/${userProfile?.profile?.avatar}`}
              entityId={userProfile?.profile?.profileId?.value as string}
            />
            <MfaButton />
            <ProfileNameForm />
          </Box>
        </Box>
      ),
    },
    {
      label: 'Expertise',
      content: (
        <Box className="flex flex-col gap-6">
          <Box className="flex flex-col gap-2">
            <Typography fontSize="24px" className="font-bold">
              Expertise
            </Typography>
            <Typography>
              Indicate your characteristics. We will show you the demands that suit you.
            </Typography>
          </Box>
          <Divider />
          <ProfileTraitsForm />
          <ProfileTags />
        </Box>
      ),
    },
  ];

  return (
    <Page description="Profile" title="Profile">
      <TabContainer tabs={tabs} userPermissions />
    </Page>
  );
};

export default VerticalTabs;
