// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file api/rewarder/v1/achievements.proto (package api.rewarder.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  GetAchievementRequest,
  GetAchievementResponse,
  ListAchievementsRequest,
  ListAchievementsResponse,
  ListProgressRequest,
  ListProgressResponse,
} from '../../../rewarder/achievements/v1/achievements_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service api.rewarder.v1.AchievementAPI
 */
export const AchievementAPI = {
  typeName: 'api.rewarder.v1.AchievementAPI',
  methods: {
    /**
     * @generated from rpc api.rewarder.v1.AchievementAPI.ListProgress
     */
    listProgress: {
      name: 'ListProgress',
      I: ListProgressRequest,
      O: ListProgressResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.rewarder.v1.AchievementAPI.ListAchievements
     */
    listAchievements: {
      name: 'ListAchievements',
      I: ListAchievementsRequest,
      O: ListAchievementsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc api.rewarder.v1.AchievementAPI.GetAchievement
     */
    getAchievement: {
      name: 'GetAchievement',
      I: GetAchievementRequest,
      O: GetAchievementResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
