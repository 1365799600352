import * as React from 'react';
import { Suspense } from 'react';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { Page } from '@components/Page/Page';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import MobileFeedSettings from '@pages/Marketplace/Feed/FeedProfileSettings/MobileFeedSettings';
import FeedByDemandsDialog from './FeedByDemand/FeedByDemandsDialog';
import FeedProfileSettings from './FeedProfileSettings/FeedProfileSettings';
import FeedList from './FeedList';

export const demandsTypes = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

const FeedPage = () => {
  return (
    <Page description="Demands" title="Demands">
      <Container>
        <Box className="relative flex flex-col">
          <div className="flex gap-4 ">
            {/* Main Content */}
            <Box className="min-w-[70%]">
              <MobileFeedSettings />
              <FeedList />
            </Box>
            {/* Sidebar */}
            <FeedProfileSettings />
          </div>
        </Box>
        <Suspense fallback={<div>Loading...</div>}>
          <FeedByDemandsDialog />
        </Suspense>
      </Container>
    </Page>
  );
};

export default FeedPage;
