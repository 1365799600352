import { useDialog } from '@components/hooks/useDialog';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Suspense } from 'react';
import { Box, Typography } from '@mui/material';
import SelectDemandStatus from '@pages/Marketplace/Dashboards/SelectDemandStatus';
import UpdateDemandTraits from '@pages/Marketplace/Dashboards/UpdateDemandTraits';
import UpdateContractDescription from '@pages/Marketplace/Dashboards/UpdateContractDescription';
import Tags from '@components/Tags/Tags';
import { useGetDemand } from '@services/queries/MarketplaceQueries';
import ListSuppliers from '@pages/Marketplace/Dashboards/ListSuppliers';
import TabContainer, { TabItem } from '@components/TabContainer/TabContainer';
import Button from '@mui/material/Button';
import CreateInterviewForContractForm from '@pages/Marketplace/Dashboards/CreateInterviewForContractorm';
import ManageDemandHeader from '@pages/Marketplace/Dashboards/ManageDemandHeader';

const ManageContractPage = () => {
  const navigate = useNavigate();
  const { demandId, dashboardId } = useParams();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { data: demand, error } = useGetDemand({ demandId: demandId || '', type: 'contract' });
  const contract = demand?.entity?.value;
  console.log('demand', demand);
  if (error?.message?.includes('NotFound') || error?.message?.includes('InvalidArgument')) {
    navigate('/404');
  }

  const tabs: TabItem[] = [
    {
      label: 'Info',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          <Box display="flex" flexDirection="column" gap={1}>
            {demand && <UpdateDemandTraits demand={demand} />}
            {contract?.tags?.tags && <Tags tags={contract.tags.tags} />}
            {demand && <UpdateContractDescription demand={demand} />}
            <Box display="flex">
              <SelectDemandStatus status={demand?.status?.status?.toString() || ''} />
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      label: 'Suppliers',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          {demand?.supply?.view?.value ? (
            <Box display="flex" gap={2} flexDirection="column">
              <ListSuppliers demandType="contract" supply={demand?.supply} />
            </Box>
          ) : (
            <Typography>No suppliers</Typography>
          )}
        </Box>
      ),
    },
    {
      label: 'Interview',
      content: (
        <Box display="flex" flexDirection="column" gap={2}>
          <ManageDemandHeader demand={demand} />
          <Box display="flex" flexDirection="column" py={2} gap={2}>
            {demand?.entity?.value?.interviewId ? (
              <Link
                className="NavLink"
                to={`/${dashboardId}/interview-manage/${demand?.entity?.value?.interviewId?.value}`}
              >
                <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>
                  Connected interview
                </Typography>
              </Link>
            ) : (
              <Button variant="outlined" onClick={handleOpen}>
                Create interview for this contract
              </Button>
            )}
            {dashboardId && demandId && demand && (
              <Suspense fallback={null}>
                <CreateInterviewForContractForm
                  dashboardId={dashboardId}
                  contractId={demandId}
                  isOpen={isOpen}
                  demand={demand}
                  handleCloseModal={handleClose}
                />
              </Suspense>
            )}
          </Box>
        </Box>
      ),
    },
  ];

  return <TabContainer tabs={tabs} userPermissions />;
};

export default ManageContractPage;
