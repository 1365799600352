import { Link, useParams } from 'react-router-dom';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import {
  useCancelProductOrder,
  useGenerateProductOrderOffer,
  useGetProductOrder,
} from '@services/queries/StepsQueries';
import Box from '@mui/material/Box';
import {
  CreateProductOrderOfferRequest_Payload,
  ProductOrderStatus,
} from '@proto/marketplace/product/v1/order_pb';
import { useGetProfile } from '@services/queries/ProfilesQueries';
import { useGetSquad } from '@services/queries/SquadsQueries';
import { useGetOrg } from '@services/queries/OrgsQueries';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { Page } from '@components/Page/Page';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { GetProfileResponse } from '@proto/profiler/user/v1/profile_pb';
import { GetSquadResponse } from '@proto/profiler/biz/v1/squad_pb';
import { GetOrgResponse } from '@proto/profiler/biz/v1/org_pb';
import { orderStatusColors, orderStatusLabels } from '@pages/Main/MainPage/ListOrders';
import { toePolicyLabel } from '@components/Roles/ManageRole';
import CustomAvatar from '@components/Avatar/Avatar';
import React from 'react';
import Button from '@mui/material/Button';
import { linkTypes } from '@components/NotificationPopover/NotificationCard';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CreateOrderRolesList from '@pages/Order/CreateOrderRolesList';
import { DemandsList } from '@pages/Marketplace/Dashboards/DemandsList';

const isOfferSend = (offer: ProductOrderStatus | undefined) => {
  if (!offer) return false;

  return [
    ProductOrderStatus.OFFER_SENT,
    ProductOrderStatus.IN_PROGRESS,
    ProductOrderStatus.COMPLETED,
    ProductOrderStatus.CANCELLED,
  ].includes(offer);
};

export const useGetCustomerData = (customer: Entity | undefined) => {
  const isUser = customer?.type === Entity_Type.USER;
  const isSquad = customer?.type === Entity_Type.SQUAD;
  const isOrg = customer?.type === Entity_Type.ORG;

  const profileQuery = useGetProfile(new UUID({ value: customer?.id?.value as string }), isUser);
  const squadQuery = useGetSquad({ value: customer?.id?.value as string, case: 'id' }, isSquad);
  const orgQuery = useGetOrg(
    { value: customer?.id?.value as string, case: 'id' },
    customer?.type === Entity_Type.ORG
  );

  if (isUser) return profileQuery;
  if (isSquad) return squadQuery;
  if (isOrg) return orgQuery;

  return { data: undefined, isLoading: false, error: undefined };
};

const Order = () => {
  const { orderId } = useParams();
  const [createDynamicSquad, setCreateDynamicSquad] = React.useState(false);
  const { data: order } = useGetProductOrder(new UUID({ value: orderId }));
  const { mutate: generateProductOffet } = useGenerateProductOrderOffer();
  const { mutate: cancelProductOrder } = useCancelProductOrder();
  const { data: customer } = useGetCustomerData(order?.order?.customer);

  return (
    <Page title="Order" description="Order">
      <Container>
        <Box className="flex flex-col justify-between min-h-[80vh]">
          <Box>
            <Box className="flex flex-col gap-4 mb-4  ">
              <Box className="flex justify-between">
                <Box className="flex gap-4">
                  <Typography className="font-bold">{order?.order?.productTitle}</Typography>
                  <Box
                    style={{
                      color:
                        orderStatusColors[order?.order?.status as keyof typeof orderStatusColors],
                    }}
                  >
                    {orderStatusLabels[order?.order?.status as keyof typeof orderStatusLabels]}
                  </Box>
                </Box>
                <Box className="flex justify-between ">
                  <Box>{new Date(order?.order?.created?.toDate() || '').toLocaleDateString()}</Box>
                </Box>
              </Box>
              <Link
                to={`/${linkTypes[order?.order?.customer?.type as keyof typeof linkTypes]}/${
                  (customer as GetProfileResponse)?.profile?.profileId?.value ||
                  (customer as GetSquadResponse)?.squad?.squadId?.value ||
                  (customer as GetOrgResponse)?.org?.orgId?.value
                }`}
              >
                <Box>
                  <Typography className="text-white/80">Customer</Typography>
                  <Box className="flex gap-2 items-center">
                    <CustomAvatar
                      borderRadius="8px"
                      height={35}
                      width={35}
                      alt={
                        (customer as GetProfileResponse)?.profile?.avatar ||
                        (customer as GetSquadResponse)?.squad?.avatar ||
                        (customer as GetOrgResponse)?.org?.avatar ||
                        'Squad'
                      }
                      avatarId={
                        (customer as GetProfileResponse)?.profile?.avatar ||
                        (customer as GetSquadResponse)?.squad?.avatar ||
                        (customer as GetOrgResponse)?.org?.avatar ||
                        'Squad'
                      }
                      profileId={
                        (customer as GetProfileResponse)?.profile?.profileId?.value ||
                        (customer as GetSquadResponse)?.squad?.squadId?.value ||
                        (customer as GetOrgResponse)?.org?.orgId?.value ||
                        'Squad'
                      }
                    />
                    <Typography>
                      {(customer as GetProfileResponse)?.profile?.name?.nickName ||
                        (customer as GetSquadResponse)?.squad?.name ||
                        (customer as GetOrgResponse)?.org?.name}
                    </Typography>
                    <Typography>
                      {toePolicyLabel[order?.order?.customer?.type as keyof typeof toePolicyLabel]}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Box>
            {order?.order &&
              (order?.order?.status === ProductOrderStatus.PENDING ||
                order?.order?.status === ProductOrderStatus.OFFER_DRAFT) && (
                <CreateOrderRolesList order={order?.order} draft={order?.draft} />
              )}
            {order?.demands?.contracts && Object.values(order?.demands?.contracts)?.length && (
              <DemandsList demands={order?.demands} />
            )}
            {!isOfferSend(order?.order?.status) && order && (
              <Box className="mb-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={createDynamicSquad}
                      onChange={(e) => setCreateDynamicSquad(e.target.checked)}
                    />
                  }
                  label="Create dynamic squad"
                />
              </Box>
            )}
          </Box>

          {!isOfferSend(order?.order?.status) && order && (
            <Box className="flex flex-col items-end justify-end gap-2">
              <Button
                className="w-full "
                variant="contained"
                onClick={() =>
                  generateProductOffet(
                    new CreateProductOrderOfferRequest_Payload({
                      orderId: new UUID({ value: orderId }),
                      createDynamicSquad,
                    })
                  )
                }
              >
                Generate offer
              </Button>
              <Button
                className="w-full "
                variant="outlined"
                color="error"
                onClick={() => {
                  cancelProductOrder(new UUID({ value: orderId }));
                }}
              >
                Cancel order
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default Order;
